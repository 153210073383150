import React from "react";
import { BaseWidget } from "@origin-digital/widget-helpers";
import { SpikeWidget as Widget } from "./components/SpikeWidget/SpikeWidget";
import { config } from "./config";

export const SpikeWidget = () => (
  <BaseWidget name={config.widgetName}>
    <Widget />
  </BaseWidget>
);
