import { IBatteryControlStatus } from "../contract/generated/bff/src";
import { IUpdateSnooze, updateSnooze } from "../service/snoozeService";
import { useDataFetch } from "../../../../../hooks/useDataFetch";

export interface ISnoozeControlData {
  data?: IBatteryControlStatus;
  loading: boolean;
  error?: Error;
  update: (param: IUpdateSnooze) => void;
}

export const useSnoozeUpdate = (): ISnoozeControlData => {
  const { loading, error, data, dataFetch } = useDataFetch<
    IBatteryControlStatus,
    IUpdateSnooze
  >(updateSnooze);

  return {
    update: dataFetch,
    data,
    loading,
    error,
  };
};
