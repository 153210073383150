import React from "react";
import { ICardAA } from "@origin-digital/reporting-client";
import { Text } from "@origin-digital/ods-core";
import { ErrorCard } from "../../ErrorCard/ErrorCard";

interface INoActiveScheduleErrorCardProps {
  analyticsData: ICardAA;
}

export const NoActiveScheduleErrorCard = ({
  analyticsData,
}: INoActiveScheduleErrorCardProps) => (
  <ErrorCard
    error={{
      message: "No active schedule found for vehicle",
      status: 500,
    }}
    analyticsData={analyticsData}
    displayText={
      <Text data-id="display-text">No active schedule found for vehicle</Text>
    }
  />
);
