import { RewardsOffersWidget } from "@origin-digital/rewards-offers-widget";
import { WidgetDefinition } from "../../WidgetDefinition";

export const rewardsOffersWidgetDefinition: WidgetDefinition<{
  isRxUser: boolean;
}> = {
  widgetComponent: RewardsOffersWidget,
  name: "RewardsOffersWidget",
  propsBuilder: (_data) => ({ isRxUser: true }),
};
