import {
  activateChargeOverride,
  deactivateChargeOverride,
} from "../contract/bff";
import { apiBasePath } from "../config";

export const activateInstantCharge = (vehicleId: string) =>
  activateChargeOverride(apiBasePath, { vehicleId }, "jwt");

export const deactivateInstantCharge = (vehicleId: string) =>
  deactivateChargeOverride(apiBasePath, { vehicleId }, "jwt");
