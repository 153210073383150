import React from "react";

export const Laundry = () => (
  <svg width="128px" height="134px" viewBox="0 0 128 134" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="error-screen_something-wrong_01"
        transform="translate(-124.000000, -172.000000)"
      >
        <g id="Group" transform="translate(32.000000, 162.000000)">
          <g
            id="Illustration-spot-circle_laundry"
            transform="translate(76.000000, 0.000000)"
          >
            <rect
              id="Safe-Area"
              strokeOpacity="0"
              stroke="#FFFFFF"
              x="0"
              y="0"
              width="160"
              height="144"
            />
            <circle id="Oval" fill="#F8F8F8" cx="80" cy="80" r="64" />
            <g id="Illustration" transform="translate(25.000000, 10.000000)">
              <polygon
                id="Body"
                fill="#FFFFFF"
                points="0 116.881609 66.9802222 133.857869 90.0507145 42.9481951 23.0705829 25.9719343"
              />
              <polygon
                id="Base"
                fill="#939398"
                points="0 116.881609 66.9802222 133.857869 68.7804103 126.76438 1.80009744 109.788119"
              />
              <polygon
                id="Head-Lower"
                fill="#D3D3D3"
                points="90.050787 42.9481951 23.0705648 25.9719343 19.3623768 40.5840911 86.342599 57.5603519"
              />
              <polygon
                id="Head-Upper"
                fill="#EDECED"
                points="90.050787 42.9481951 23.0705648 25.9719343 19.8341221 38.7252032 86.8143443 55.7013735"
              />
              <g id="Door" transform="translate(22.100540, 58.042641)">
                <path
                  d="M44.7268339,28.2788917 C41.6435929,40.4283457 29.2889758,47.7790606 17.1322254,44.697966 C4.97511256,41.6166903 -2.38092504,29.27022 0.702315981,17.1208565 C3.78555701,4.97140247 16.1401741,-2.37931239 28.2972869,0.701872743 C40.4540373,3.78296734 47.8099844,16.1295281 44.7268339,28.2788917"
                  id="Oval"
                  fill="#D3D3D3"
                />
                <path
                  d="M27.3538597,4.41977518 C17.2678238,1.86345355 6.98084094,7.98435626 4.4228888,18.0638725 C1.86493666,28.1434792 7.9897435,38.4239049 18.0756888,40.9803171 C28.1617247,43.5365481 38.4487076,37.415736 41.0066597,27.3361292 C43.5646119,17.2565225 37.4398956,6.97600626 27.3538597,4.41977518"
                  id="Rim"
                  fill="#ECECEC"
                />
                <path
                  d="M18.3902914,39.7407627 C8.98791017,37.3577357 3.27867769,27.7746533 5.6632247,18.3782654 C8.04777171,8.98187761 17.6369666,3.27628437 27.0392572,5.65931139 C36.4416384,8.04233842 42.1508709,17.6254209 39.7663238,27.0217181 C37.3817768,36.418106 27.792582,42.1236992 18.3902914,39.7407627"
                  id="Oval"
                  fill="#232323"
                />
              </g>
              <g id="Items" transform="translate(24.710376, 0.038932)">
                <polygon
                  id="Fill-13"
                  fill="#C8C8C8"
                  points="20.151959 43.4762004 -1.36779477e-13 38.368718 2.42078632 28.8295477 22.5727453 33.9371207"
                />
                <polygon
                  id="Fill-15"
                  fill="#939398"
                  points="22.5727544 33.9370845 2.42079538 28.8295115 1.63449282 31.9279899 21.7864518 37.0355628"
                />
                <path
                  d="M73.1622691,45.5937354 L63.9157169,26.2341759 C62.9759409,24.2666395 63.8098981,21.9109557 65.7786896,20.9718692 C67.747028,20.0329638 70.1046674,20.8662084 71.0444434,22.8336543 L80.2909956,42.1932138 C80.8672007,43.3998476 80.3555015,44.8449651 79.1481887,45.4209841 L76.3920075,46.7357232 C75.1846947,47.3116516 73.7384742,46.8002786 73.1622691,45.5937354"
                  id="Fill-17"
                  fill="#FFC72C"
                />
                <path
                  d="M65.0512397,46.9961359 L51.4341354,30.4132738 C50.5852294,29.3793914 50.7358944,27.8540549 51.7700738,27.0059616 L54.1309747,25.0697522 C55.1651542,24.2216589 56.6915542,24.3715941 57.5404602,25.4054765 L71.1574738,41.9883386 C72.5410909,43.6733886 72.295932,46.1607184 70.6098978,47.5434535 C68.9235012,48.9264603 66.4348568,48.6810954 65.0512397,46.9961359"
                  id="Fill-19"
                  fill="#FA4616"
                />
                <path
                  d="M42.2669111,47.6603414 C42.0877983,48.3661954 41.3700786,48.7932751 40.663865,48.6142765 C39.9575607,48.4352778 39.5302085,47.7180157 39.709412,47.0121616 C39.8885248,46.3063981 40.6062444,45.8793184 41.3124581,46.058317 C42.0186718,46.2373157 42.4460239,46.9545778 42.2669111,47.6603414"
                  id="Fill-21"
                  fill="#C8C8C8"
                />
                <path
                  d="M46.9967398,48.8591162 C46.817627,49.5649703 46.0999074,49.99205 45.3936031,49.8130514 C44.6873894,49.6340527 44.2600373,48.9167905 44.4391501,48.2109365 C44.6182629,47.505173 45.3359826,47.0780932 46.0421962,47.2570919 C46.7485005,47.4360905 47.1758526,48.1533527 46.9967398,48.8591162"
                  id="Fill-23"
                  fill="#C8C8C8"
                />
                <path
                  d="M51.7263874,50.0578911 C51.5472745,50.7637451 50.8295549,51.1908249 50.1233412,51.0117357 C49.4170369,50.8328276 48.9896848,50.1154749 49.1688882,49.4097114 C49.348001,48.7039478 50.0656301,48.2767776 50.7719344,48.4558668 C51.478148,48.6347749 51.9055002,49.3521276 51.7263874,50.0578911"
                  id="Fill-25"
                  fill="#C8C8C8"
                />
                <path
                  d="M56.4561617,51.256675 C56.2770489,51.9624385 55.5593292,52.3895182 54.853025,52.2105196 C54.1468113,52.0316115 53.7194591,51.3142588 53.898572,50.6084953 C54.0776848,49.9026412 54.7954044,49.4755615 55.5016181,49.6546507 C56.2079224,49.8335588 56.6352745,50.5509115 56.4561617,51.256675"
                  id="Fill-27"
                  fill="#C8C8C8"
                />
                <polygon
                  id="Fill-29"
                  fill="#939398"
                  points="58.8417597 48.0216162 39.1549323 43.0320176 40.3213853 38.4354554 60.0082126 43.4251446"
                />
                <path
                  d="M31.4272786,45.8797711 C29.1684821,45.3072832 27.7970957,43.0051995 28.3698581,40.7479332 C28.9428923,38.4902143 31.2463538,37.1195211 33.5051504,37.6920089 C35.7638564,38.2645873 37.1353333,40.5665805 36.5623897,42.8242995 C35.9895368,45.0816562 33.6860752,46.4522589 31.4272786,45.8797711"
                  id="Fill-31"
                  fill="#FFC72C"
                />
                <path
                  d="M34.7479234,42.3642449 C34.4282926,43.6238449 33.147414,44.3855624 31.8874636,44.0662259 C30.6275132,43.7468895 29.8647662,42.46728 30.1843969,41.20768 C30.5041183,39.94808 31.7848157,39.1862719 33.0448568,39.5056989 C34.3048072,39.8249449 35.0675542,41.1046449 34.7479234,42.3642449"
                  id="Fill-33"
                  fill="#EDECED"
                />
                <path
                  d="M14.9213844,30.479975 C14.9183041,30.4819669 14.9412255,30.5094912 14.9404101,30.5103966 L14.9630597,30.481152 L11.4938699,27.8022385 L10.6006614,27.1125912 L7.20721179,24.4922574 C7.20721179,24.4922574 7.19715538,24.5044804 7.19788185,24.503575 C7.20077932,24.3934777 7.27697248,23.2654331 7.47782889,22.7518872 C7.52294684,22.6359047 7.58074855,22.5476277 7.64597932,22.4787264 C7.64625111,22.4783642 7.64027162,22.4748331 7.64054342,22.4751047 C7.87936051,22.2206858 8.19138103,22.233452 8.19138103,22.233452 L8.31214855,22.0773601 L17.5161195,10.1731804 C17.7311998,9.89494932 18.0164032,9.68172635 18.3363058,9.51123851 C18.3445503,9.50616824 18.3564186,9.50281824 18.3657503,9.4984723 C18.3879468,9.48733581 18.4121366,9.47665203 18.4346956,9.46569662 C18.5026443,9.43219662 18.5729485,9.40105068 18.6432528,9.37144392 C19.1082032,9.17451824 19.6383844,9.06179527 20.1542511,8.98646554 C21.0469161,8.85518176 21.8449964,8.79470068 22.428087,8.78528446 L22.8782699,8.20301824 L26.6643725,11.1266628 L26.2141896,11.7089291 C26.3519896,12.274898 26.5152477,13.0777209 26.6130032,13.9738007 C26.6705332,14.4918736 26.7019708,15.0383764 26.6293109,15.5381601 C26.616899,15.6254412 26.6082921,15.7168872 26.5897195,15.8015426 C26.5841024,15.8249926 26.5967862,15.8621142 26.5911691,15.8855642 C26.5067315,16.237948 26.3848768,16.5772939 26.1697964,16.855525 L16.9658255,28.7596142 L16.8451485,28.9157966 C16.8451485,28.9157966 16.914275,29.1983736 16.7620699,29.5192493 C16.7039058,29.5945791 16.6194682,29.6655628 16.5191759,29.7383574 C16.0722545,30.0618588 15.0259349,30.4462939 14.9213844,30.479975 Z"
                  id="Fill-35"
                  fill="#C8C8C8"
                />
                <path
                  d="M25.5138829,11.58162 C25.4110538,11.7146241 25.2184419,11.7389795 25.0857154,11.6364876 L22.7894111,9.86325108 C22.6563222,9.76057811 22.6313171,9.56808892 22.7341462,9.43517541 L23.4946282,8.45154297 C23.5971855,8.31890108 23.7900692,8.29418351 23.9230675,8.39694703 L26.2194624,10.170093 C26.3520983,10.2725849 26.3769222,10.4654362 26.274365,10.5980781 L25.5138829,11.58162 Z"
                  id="Fill-37"
                  fill="#6C89AF"
                />
                <path
                  d="M16.5191759,29.7383574 C16.0722545,30.0618588 15.0259349,30.4462939 14.9213844,30.479975 C14.9183041,30.4819669 14.9412255,30.5094912 14.9404101,30.5103966 L14.9630597,30.481152 L11.4938699,27.8022385 L10.6006614,27.1125912 L7.20721179,24.4922574 C7.20721179,24.4922574 7.19715538,24.5044804 7.19797077,24.503575 C7.19815197,24.5025791 7.19307846,24.5062912 7.19350281,24.5028507 C7.20077932,24.3934777 7.27697248,23.2654331 7.47782889,22.7518872 C7.52294684,22.6359047 7.58074855,22.5476277 7.64597932,22.4787264 C7.64625111,22.4783642 7.64027162,22.4748331 7.64054342,22.4751047 C7.87936051,22.2206858 8.19138103,22.233452 8.19138103,22.233452 L8.31214855,22.0773601 L17.5161195,10.1731804 C17.7311998,9.89494932 18.0164032,9.68172635 18.3363058,9.51123851 C18.3445503,9.50616824 18.3564186,9.50281824 18.3657503,9.4984723 C18.3879468,9.48733581 18.4183879,9.46841284 18.4409468,9.45754797 C18.5085332,9.42368581 18.5801058,9.39181554 18.6564802,9.35496554 L26.6596614,15.5349007 C26.6013161,15.6814858 26.6231503,15.7152574 26.604487,15.8000034 C26.5992323,15.8236345 26.6043964,15.8613899 26.5987793,15.8848399 C26.5143417,16.2373142 26.3848768,16.5772939 26.1697964,16.855525 L16.9658255,28.7596142 L16.8451485,28.9157966 C16.8451485,28.9157966 16.914275,29.1983736 16.7620699,29.5192493 L16.7618887,29.5196115 C16.7039058,29.5945791 16.6194682,29.6655628 16.5191759,29.7383574"
                  id="Fill-39"
                  fill="#9ACAEA"
                />
                <path
                  d="M27.402205,9.4602823 C27.2097742,9.70917824 26.7922067,9.70311203 26.4571742,9.44434716 L24.6040768,8.01344446 C24.2686819,7.75440797 24.1537126,7.34932959 24.3461434,7.10043365 L25.1751178,6.02816203 C25.3679109,5.77899446 25.7894648,5.78759581 26.1247691,6.04654176 L27.9779571,7.477535 C28.3129896,7.73620932 28.4239725,8.13875257 28.2312699,8.38801068 L27.402205,9.4602823 Z"
                  id="Fill-41"
                  fill="#3A6BB3"
                />
                <path
                  d="M27.9090752,10.3381 C28.0490496,10.1571095 27.9579983,9.85914054 27.7143795,9.67108784 L24.0571983,6.84703784 C23.8135795,6.65889459 23.5082632,6.65074595 23.3682889,6.83173649 L23.1381692,7.12952432 L23.0048085,7.30200405 L22.7746889,7.59961081 C22.6583607,7.74999865 22.6974085,7.97933784 22.8525128,8.16123378 C22.8844034,8.19835541 22.9232701,8.23113108 22.9645829,8.26300135 L26.6214017,11.0867797 L26.7411726,11.1792216 C26.8828684,11.2369865 27.0259231,11.2527405 27.1418889,11.2196932 C27.1532137,11.2164338 27.1649915,11.21245 27.1757726,11.2082851 C27.1804838,11.2064743 27.1854667,11.2042108 27.1900872,11.2024905 C27.2073009,11.1950662 27.2234274,11.1861932 27.2387385,11.1763243 C27.2472547,11.1708919 27.2554085,11.1652784 27.2629282,11.1590311 C27.265465,11.1572203 27.2673675,11.1554095 27.2692701,11.1535986 C27.2770615,11.1471703 27.2847624,11.139927 27.2923726,11.1322311 C27.3002547,11.124173 27.3083179,11.1151189 27.3154752,11.1058838 L27.5455949,10.808277 L27.6789556,10.6357973 L27.9090752,10.3381 Z"
                  id="Fill-43"
                  fill="#335FAB"
                />
                <path
                  d="M45.5147874,2.47557757 C46.2788933,2.84290054 47.005854,3.25458838 47.7079002,3.71082216 C48.193054,4.02608432 48.6649805,4.3599073 49.0689583,4.77784243 C49.5432403,5.26811946 49.7776181,5.83263973 49.5249395,6.50752892 C49.2826797,7.15462216 48.8024181,7.54168297 48.117948,7.63647892 C47.5116643,7.72041 47.0555019,7.43602216 46.7047959,6.96503027 C46.4625361,6.63944649 46.3269104,6.26089649 46.2032438,5.87908703 C45.8546215,4.80346541 45.6679891,3.69307622 45.4986609,2.57933703 C45.4947651,2.55362351 45.5065429,2.52564649 45.5147874,2.47557757"
                  id="Fill-45"
                  fill="#9ACAEA"
                />
                <path
                  d="M39.9009186,0.672806757 C41.0383802,0.625001351 42.1684126,0.523595946 43.2748896,0.836413514 C43.9977733,1.04067297 44.3342554,1.59858378 44.20624,2.30317027 C44.0457904,3.18684595 43.2012332,3.60550541 42.3962674,3.20151351 C42.1012793,3.05320811 41.8489631,2.84505541 41.6073374,2.62540405 C41.0192639,2.09049054 40.4981426,1.49292297 39.9945973,0.879510811 C39.9550058,0.831071622 39.8953921,0.791777027 39.9009186,0.672806757"
                  id="Fill-47"
                  fill="#9ACAEA"
                />
                <path
                  d="M33.0398285,1.66433432 C33.9775209,1.03806541 34.8391106,0.424562703 35.8644115,0.116453243 C36.8251157,-0.172099459 37.6267294,0.53628973 37.4798696,1.52598838 C37.3986935,2.07303432 36.9487824,2.44696676 36.3296337,2.47258973 C35.8098713,2.49413838 35.3102217,2.37272351 34.8132901,2.24225459 C34.2292029,2.08869784 33.6523636,1.91286811 33.0398285,1.66433432"
                  id="Fill-49"
                  fill="#9ACAEA"
                />
                <path
                  d="M28.7347246,5.60682257 C28.7990494,4.92405635 28.8973485,4.3129077 29.0477417,3.71026986 C29.1425981,3.33054284 29.2423468,2.95117797 29.44574,2.61029284 C29.8134785,1.99416446 30.5083674,1.85572797 31.1055913,2.27873338 C31.6729178,2.6805523 31.7504699,3.3648577 31.2749195,3.90927797 C30.8641468,4.37963608 30.3293451,4.69263473 29.8015195,5.00952662 C29.468752,5.20916851 29.1242067,5.38979689 28.7347246,5.60682257"
                  id="Fill-52"
                  fill="#9ACAEA"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
