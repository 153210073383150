import React from "react";
import { Button } from "@origin-digital/ods-core";
import { navTo } from "@origin-digital/event-dispatcher";

interface IChargeSettingsLinkProps {
  vehicleId: string;
}

export const ChargeSettingsLink = ({ vehicleId }: IChargeSettingsLinkProps) => (
  <Button
    data-id="charge-settings-link"
    variant="text"
    size="small"
    noTextPadding
    onClick={() => navTo.evIChargeChargingTarget({ vehicleId })}
  >
    Settings
  </Button>
);
