import { Box, Text } from "@origin-digital/ods-core";
import React from "react";
import {
  IForecastDescription,
  IWeatherResponse,
} from "../../contract/generated/bff/src";
import { CloudyIcon } from "../Icons/Weather/CloudyIcon";
import { MoonIcon } from "../Icons/Weather/MoonIcon";
import { PartlyCloudyIcon } from "../Icons/Weather/PartlyCloudyIcon";
import { RainIcon } from "../Icons/Weather/RainIcon";
import { SunnyIcon } from "../Icons/Weather/SunnyIcon";

interface IWeatherResponseProps {
  weatherData?: IWeatherResponse | null;
  isDaylight: boolean;
}

function getWeatherIcon(
  forecastDescription?: IForecastDescription
): () => JSX.Element {
  switch (forecastDescription) {
    case IForecastDescription.CLOUDY:
      return CloudyIcon;
    case IForecastDescription.RAIN:
      return RainIcon;
    case IForecastDescription.PARTLY_CLOUDY:
      return PartlyCloudyIcon;
    case IForecastDescription.SUNNY:
    default:
      return SunnyIcon;
  }
}

export const WeatherSummary = ({
  weatherData,
  isDaylight,
}: IWeatherResponseProps) => {
  if (!weatherData) {
    return null;
  }

  const { forecastDescription, temperature } = weatherData;

  let ForecastIcon = MoonIcon;
  if (isDaylight) {
    ForecastIcon = getWeatherIcon(forecastDescription);
  }

  return (
    <Box display="flex" data-id="weather-summary" alignItems="center">
      <Box display="flex" paddingRight="xxsmall" data-id="weather-summary-icon">
        {ForecastIcon && <ForecastIcon />}
      </Box>
      <Text variant="body-small" data-id="weather-summary-temperature">
        {temperature}°
      </Text>
    </Box>
  );
};
