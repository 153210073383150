import { useEffect, useRef } from "react";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { ICardAA, ICardFailedAA } from "@origin-digital/reporting-client";

type CardReportingData = (ICardAA | ICardFailedAA) & {
  status?: WidgetStatusCode;
};

const hasLoadedStatus = ({ status }: Pick<CardReportingData, "status">) =>
  status === WidgetStatusCode.COMPLETE || status === WidgetStatusCode.FAILURE;

export const useMultiWidgetAnalytics = <T>(
  widgetsData: T[],
  getWidgetId: (cardId: T) => string
) => {
  const { updateWidgetStatus } = useWidgetProvider();
  const cardAnalytics = useRef<CardReportingData[]>([]);
  const trackingSent = useRef<boolean>(false);

  useEffect(() => {
    if (widgetsData.length && !cardAnalytics.current.length) {
      cardAnalytics.current = widgetsData.map((widgetData) => ({
        id: getWidgetId(widgetData),
        category: "dashboard-card",
        primaryCta: "",
        title: "",
      }));
    }
  }, [widgetsData]);

  const updateCardAnalytics = (analyticsData: Partial<CardReportingData>) => {
    if (trackingSent.current) {
      return;
    }

    const cardIdx = cardAnalytics.current.findIndex(
      ({ id }) => id === analyticsData.id
    );
    if (cardIdx !== -1) {
      cardAnalytics.current[cardIdx] = {
        ...cardAnalytics.current[cardIdx],
        ...analyticsData,
      };
    }
    if (cardAnalytics.current.every(hasLoadedStatus)) {
      updateWidgetStatus(WidgetStatusCode.COMPLETE, cardAnalytics.current);
    }
  };

  return (status: WidgetStatusCode, metaData?: (ICardAA | ICardFailedAA)[]) => {
    if (metaData?.length) {
      updateCardAnalytics({ ...metaData[0], status });
    }
  };
};
