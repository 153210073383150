export interface IConfig {
  widgetName: string;
  ohmPathStaging: string;
  ohmPathProd: string;
}

export const config: IConfig = {
  widgetName: "spike-widget",
  ohmPathStaging: "https://api.beta.spike.originenergy.com.au/",
  ohmPathProd: "https://api.spike.originenergy.com.au/",
};

// Temporary hack until we enable widget config
export const isProd = () => (window as any)?.oetal?.env?.name === "prod";
