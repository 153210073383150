import React from "react";

export const BatteryOffIcon = () => (
  <svg width="96" height="96" viewBox="0 0 96 96" fill="none">
    <g clipPath="url(#clip0_5707_21953)">
      <path
        d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
        fill="#F8F8F8"
      />
      <path
        d="M29.598 30.536C28.3757 35.193 24.5202 38.4027 20.1182 38.9462C18.8577 39.1048 18.5813 40.8887 19.7105 41.4912C21.0339 42.1887 22.5084 42.676 24.1093 42.8902C30.0777 43.6839 35.9608 40.1061 38.109 34.3741C40.9589 26.7508 36.7019 18.4977 29.1884 16.4425C28.2556 16.1873 27.3338 16.047 26.4007 16.0015C25.1149 15.9434 24.4713 17.6129 25.4946 18.4101C29.1675 21.2184 30.7954 26.0259 29.598 30.536Z"
        fill="#FFC72C"
      />
      <path d="M66 36.0001H34V78.0001H66V36.0001Z" fill="#E3E3E3" />
      <path d="M70 36.0001H66V78.0001H70V36.0001Z" fill="#D5D5D5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 30.0001H66V36.0001H34V30.0001Z"
        fill="#737373"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66 30.0001H70V36.0001H66V30.0001Z"
        fill="#505050"
      />
      <path d="M62 72.0001H38V68.0001H62V72.0001Z" fill="#ABABAB" />
      <path d="M62 66.0001H38V62.0001H62V66.0001Z" fill="#ABABAB" />
      <path d="M62 60.0001H38V56.0001H62V60.0001Z" fill="#ABABAB" />
      <path d="M52 47.0001H60V45.0001H52V47.0001Z" fill="white" />
      <path
        d="M40 47.0012H43.0261V50.0001H44.9307V47.0012H48V44.9839H44.9307V42.0001H43.0261V44.9839H40V47.0012Z"
        fill="white"
      />
      <path d="M60 28.0001H52V30.0001H60V28.0001Z" fill="#737373" />
      <path d="M50 28.0001H42V30.0001H50V28.0001Z" fill="#737373" />
    </g>
    <defs>
      <clipPath id="clip0_5707_21953">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
