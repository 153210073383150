import React from "react";
import { Card, Spinner, Stack } from "@origin-digital/ods-core";

export const LoadingCard = () => (
  <Card>
    <Stack alignX="center">
      <Spinner />
    </Stack>
  </Card>
);
