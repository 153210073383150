import React, { createContext, useContext } from "react";
import { ErrorSnackbar, SuccessSnackbar } from "@origin-digital/neon-common";
import { useSnoozePopupControl } from "../hooks/useSnoozePopupControl";

export interface ISnoozePopupContext {
  deactivateControl?: ReturnType<typeof useSnoozePopupControl>;
  activateControl?: ReturnType<typeof useSnoozePopupControl>;
}

export const SnoozePopupContext = createContext<ISnoozePopupContext>({});

/* istanbul ignore next */
export const useSnoozePopupContext = (): ISnoozePopupContext =>
  useContext<ISnoozePopupContext>(SnoozePopupContext);

export const SnoozePopupContextProvider: React.FC = ({ children }) => {
  const deactivateControl = useSnoozePopupControl();
  const activateControl = useSnoozePopupControl();

  return (
    <SnoozePopupContext.Provider value={{ deactivateControl, activateControl }}>
      <SuccessSnackbar
        open={deactivateControl.showSuccessSnackbar}
        onClose={deactivateControl.handleSuccessSnackbarClose}
        displayText={"24 hour snooze started."}
      />
      <SuccessSnackbar
        open={activateControl.showSuccessSnackbar}
        onClose={activateControl.handleSuccessSnackbarClose}
        displayText={"Snooze stopped."}
      />

      <ErrorSnackbar
        open={deactivateControl.showErrorSnackbar}
        onClose={deactivateControl.handleErrorSnackbarClose}
        displayText={"There was a problem starting Snooze. Please try again."}
      />
      <ErrorSnackbar
        open={activateControl.showErrorSnackbar}
        onClose={activateControl.handleErrorSnackbarClose}
        displayText={"There was a problem stopping Snooze. Please try again."}
      />
      {children}
    </SnoozePopupContext.Provider>
  );
};
