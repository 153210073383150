import React from "react";
import { EnableInstantChargeDialog } from "./EnableInstantChargeDialog";
import {
  ErrorSnackbar,
  SuccessSnackbar,
  useSnackbarState,
} from "@origin-digital/neon-common";
import { InstantChargeOption } from "./InstantChargeOption";

export const InstantChargeOptionPanel = () => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [showErrorSnackbar, handleErrorSnackbarOpen, handleErrorSnackbarClose] =
    useSnackbarState();
  const [
    showSuccessSnackbar,
    handleSuccessSnackbarOpen,
    handleSuccessSnackbarClose,
  ] = useSnackbarState();

  const handleClick = () => setShowDialog(true);

  return (
    <>
      <SuccessSnackbar
        open={showSuccessSnackbar}
        onClose={handleSuccessSnackbarClose}
        displayText="Instant charge started."
      />
      <ErrorSnackbar
        open={showErrorSnackbar}
        onClose={handleErrorSnackbarClose}
        displayText="A problem occurred. Please try again."
      />
      <EnableInstantChargeDialog
        open={showDialog}
        handleSuccessSnackbarOpen={handleSuccessSnackbarOpen}
        handleErrorSnackbarOpen={handleErrorSnackbarOpen}
        handleClose={() => setShowDialog(false)}
      />
      <InstantChargeOption onClick={handleClick} />
    </>
  );
};
