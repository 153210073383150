import React, { MouseEventHandler } from "react";
import { ControlPanel } from "../../ControlPanel/ControlPanel";

interface IInstantChargeOptionProps {
  onClick: MouseEventHandler;
}

export const InstantChargeOption = ({ onClick }: IInstantChargeOptionProps) => (
  <ControlPanel
    onClick={onClick}
    label="Need to override your charge schedule?"
    data-id={"instant-charge-option"}
  />
);
