import { useCallback, useEffect, useState } from "react";
import { useWebsocketWithAuth } from "@origin-digital/websockets-client";
import { getVehicleEndpoint } from "../../config";
import { getVehicle } from "../../services/vehicle";
import { IVehicle } from "../../contract/generated/bff/src";

const getVehicleWebsocketEndpoint = (vehicleId: string) =>
  `${getVehicleEndpoint()}/${vehicleId}/updates`;

export const useVehicleDetails = (vehicleId: string) => {
  const {
    data: socketData,
    error,
    loading,
  } = useWebsocketWithAuth<IVehicle>({
    url: getVehicleWebsocketEndpoint(vehicleId),
    errorMessage: "Failed to get vehicle details.",
  });
  const [data, setData] = useState<IVehicle | undefined>(socketData);

  useEffect(() => {
    setData(socketData);
  }, [socketData]);

  const refresh = useCallback(async () => {
    const response = await getVehicle(vehicleId);
    setData(response.data);
  }, [vehicleId]);

  return {
    data,
    error,
    loading,
    refresh,
  };
};
