import React from "react";
import { Column, Columns, Heading, Stack } from "@origin-digital/ods-core";
import { HouseIcon } from "../Icons/HouseIcon";

export const BasicHeader = () => (
  <Columns alignY="center" space="xsmall" data-id="header">
    <Column width="content">
      <HouseIcon size="medium" />
    </Column>
    <Column>
      <Stack space="none">
        <Heading variant="h4" data-id="header-text">
          Your home energy
        </Heading>
      </Stack>
    </Column>
  </Columns>
);
