// @ts-nocheck
import { dapiFetchCache, dapiFetch, IDapiRequest } from '@origin-digital/daxi';

import {
  IChargeCostDetailResponse,
  IChargeCostMonthBreakdownResponse,
  IChargeCostResponse,
  IChargeSchedule,
  IEligibilityResponse,
  ILinkingSessionBody,
  ILinkingSessionResponse,
  IUserAccount,
  IVehicleResponse,
  IVehicleWebsocketResponse,
  IVehiclesResponse,
} from '../models';

/*
 * This code has been generated from an OpenAPI spec.
 */

export interface IActivateChargeOverrideRequest {
  vehicleId: string;
}

/**
 * @summary Activate charge override
 */
export const activateChargeOverride = async (baseUrl: string, data: IActivateChargeOverrideRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'POST';
        const url = baseUrl + activateChargeOverrideUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'POST: ${ activateChargeOverrideUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the activateChargeOverride endpoint url that substitutes in given url params.
  */
const activateChargeOverrideUrl = (data: IActivateChargeOverrideRequest) => {
    return '/api/v2/vehicles/{vehicleId}/charge-override/active'.replace('{' + 'vehicleId' + '}', encodeURIComponent(data.vehicleId));
};


export interface ICreateUserRequest extends IUserAccount {
}

/**
 * @summary Create a new user
 */
export const createUser = async (baseUrl: string, data: ICreateUserRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'POST';
        const url = baseUrl + createUserUrl();
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'POST: ${ createUserUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the createUser endpoint url that substitutes in given url params.
  */
const createUserUrl = () => {
    return '/api/v2/user';
};


export interface IDeactivateChargeOverrideRequest {
  vehicleId: string;
}

/**
 * @summary Deactivate charge override
 */
export const deactivateChargeOverride = async (baseUrl: string, data: IDeactivateChargeOverrideRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'DELETE';
        const url = baseUrl + deactivateChargeOverrideUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'DELETE: ${ deactivateChargeOverrideUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the deactivateChargeOverride endpoint url that substitutes in given url params.
  */
const deactivateChargeOverrideUrl = (data: IDeactivateChargeOverrideRequest) => {
    return '/api/v2/vehicles/{vehicleId}/charge-override/active'.replace('{' + 'vehicleId' + '}', encodeURIComponent(data.vehicleId));
};


export interface IDeleteUserRequest {
}

/**
 * @summary Deletes this user from both the user db and VPP. This is a highly destructive process on the VPP side.
 */
export const deleteUser = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'DELETE';
        const url = baseUrl + deleteUserUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'DELETE: ${ deleteUserUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the deleteUser endpoint url that substitutes in given url params.
  */
const deleteUserUrl = () => {
    return '/api/v2/user';
};


export interface IGetChargeCostRequest {
}

/**
 * @summary Aggregation of last weeks charging costs and credits
 */
export const getChargeCost = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IChargeCostResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getChargeCostUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IChargeCostResponse>(url + queryString, fetchOptions);
          return response.data as IChargeCostResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IChargeCostResponse>(url + queryString, fetchOptions);
        return response.data as IChargeCostResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getChargeCostUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getChargeCost endpoint url that substitutes in given url params.
  */
const getChargeCostUrl = () => {
    return '/api/v2/charge-cost/dashboard';
};


export interface IGetChargeCostDetailRequest {
}

/**
 * @summary Breakdown of charging costs and credits
 */
export const getChargeCostDetail = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IChargeCostDetailResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getChargeCostDetailUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IChargeCostDetailResponse>(url + queryString, fetchOptions);
          return response.data as IChargeCostDetailResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IChargeCostDetailResponse>(url + queryString, fetchOptions);
        return response.data as IChargeCostDetailResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getChargeCostDetailUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getChargeCostDetail endpoint url that substitutes in given url params.
  */
const getChargeCostDetailUrl = () => {
    return '/api/v2/charge-cost/detail';
};


export interface IGetChargeCostMonthBreakdownRequest {
  month: string;
}

/**
 * @summary Breakdown of charging costs and credits for a particular month
 */
export const getChargeCostMonthBreakdown = async (baseUrl: string, data: IGetChargeCostMonthBreakdownRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IChargeCostMonthBreakdownResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getChargeCostMonthBreakdownUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IChargeCostMonthBreakdownResponse>(url + queryString, fetchOptions);
          return response.data as IChargeCostMonthBreakdownResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IChargeCostMonthBreakdownResponse>(url + queryString, fetchOptions);
        return response.data as IChargeCostMonthBreakdownResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getChargeCostMonthBreakdownUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getChargeCostMonthBreakdown endpoint url that substitutes in given url params.
  */
const getChargeCostMonthBreakdownUrl = (data: IGetChargeCostMonthBreakdownRequest) => {
    return '/api/v2/charge-cost/detail/month/{month}'.replace('{' + 'month' + '}', encodeURIComponent(data.month));
};


export interface IGetEligibilityRequest {
}

/**
 * @summary Provide eligibility status and related customer data
 */
export const getEligibility = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IEligibilityResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getEligibilityUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IEligibilityResponse>(url + queryString, fetchOptions);
          return response.data as IEligibilityResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IEligibilityResponse>(url + queryString, fetchOptions);
        return response.data as IEligibilityResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getEligibilityUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getEligibility endpoint url that substitutes in given url params.
  */
const getEligibilityUrl = () => {
    return '/api/v2/eligibility';
};


export interface IGetLinkingSessionForUserRequest extends ILinkingSessionBody {
}

/**
 * @summary Creates a linking session for the authenticated user to link their vehicle account to enode
 */
export const getLinkingSessionForUser = async (baseUrl: string, data: IGetLinkingSessionForUserRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<ILinkingSessionResponse> => {
    try {
        const method = 'POST';
        const url = baseUrl + getLinkingSessionForUserUrl();
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<ILinkingSessionResponse>(url + queryString, fetchOptions);
          return response.data as ILinkingSessionResponse;
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<ILinkingSessionResponse>(url + queryString, fetchOptions);
        return response.data as ILinkingSessionResponse;
    } catch (error) {
        error.newRelic = `'POST: ${ getLinkingSessionForUserUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getLinkingSessionForUser endpoint url that substitutes in given url params.
  */
const getLinkingSessionForUserUrl = () => {
    return '/api/v2/user/link';
};


export interface IGetVehicleRequest {
  vehicleId: string;
}

/**
 * @summary The current vehicle details
 */
export const getVehicle = async (baseUrl: string, data: IGetVehicleRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IVehicleResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getVehicleUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IVehicleResponse>(url + queryString, fetchOptions);
          return response.data as IVehicleResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IVehicleResponse>(url + queryString, fetchOptions);
        return response.data as IVehicleResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getVehicleUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getVehicle endpoint url that substitutes in given url params.
  */
const getVehicleUrl = (data: IGetVehicleRequest) => {
    return '/api/v2/vehicles/{vehicleId}'.replace('{' + 'vehicleId' + '}', encodeURIComponent(data.vehicleId));
};


export interface IGetVehicleListRequest {
}

/**
 * @summary The vehicles for the current user
 */
export const getVehicleList = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IVehiclesResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getVehicleListUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IVehiclesResponse>(url + queryString, fetchOptions);
          return response.data as IVehiclesResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IVehiclesResponse>(url + queryString, fetchOptions);
        return response.data as IVehiclesResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getVehicleListUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getVehicleList endpoint url that substitutes in given url params.
  */
const getVehicleListUrl = () => {
    return '/api/v2/vehicles';
};


export interface IGetVehicleUpdatesRequest {
  vehicleId: string;
  auth: string;
}

/**
 * @summary The current vehicles details and updates via websockets
 */
export const getVehicleUpdates = async (baseUrl: string, data: IGetVehicleUpdatesRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IVehicleWebsocketResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getVehicleUpdatesUrl(data);
        const params: any = data;
        const queryString = '?' + ['auth',]
        .filter(k => params[k] !== undefined)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IVehicleWebsocketResponse>(url + queryString, fetchOptions);
          return response.data as IVehicleWebsocketResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IVehicleWebsocketResponse>(url + queryString, fetchOptions);
        return response.data as IVehicleWebsocketResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getVehicleUpdatesUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getVehicleUpdates endpoint url that substitutes in given url params.
  */
const getVehicleUpdatesUrl = (data: IGetVehicleUpdatesRequest) => {
    return '/api/v2/vehicles/{vehicleId}/updates'.replace('{' + 'vehicleId' + '}', encodeURIComponent(data.vehicleId));
};


export interface IUpdateChargeScheduleRequest extends IChargeSchedule {
  vehicleId: string;
  scheduleId: string;
}

/**
 * @summary Update charge schedule
 */
export const updateChargeSchedule = async (baseUrl: string, data: IUpdateChargeScheduleRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'PATCH';
        const url = baseUrl + updateChargeScheduleUrl(data);
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'PATCH: ${ updateChargeScheduleUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the updateChargeSchedule endpoint url that substitutes in given url params.
  */
const updateChargeScheduleUrl = (data: IUpdateChargeScheduleRequest) => {
    return '/api/v2/vehicles/{vehicleId}/charge-schedule/{scheduleId}'.replace('{' + 'vehicleId' + '}', encodeURIComponent(data.vehicleId)).replace('{' + 'scheduleId' + '}', encodeURIComponent(data.scheduleId));
};

