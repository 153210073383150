import React from "react";
import { isDaylight, TimeOfDay } from "@origin-digital/neon-common";
import { Column, Columns, Text } from "@origin-digital/ods-core";
import { IWeatherResponse } from "../../contract/generated/bff/src";
import { WeatherSummary } from "./WeatherSummary";

export type IInfoBarProps = {
  weatherData?: IWeatherResponse | null;
  updatedAt: string;
  timeOfDay: TimeOfDay;
};

export const InfoBar = (props: IInfoBarProps) => {
  const { weatherData, updatedAt, timeOfDay } = props;

  return (
    <Columns space="xxsmall" data-id="info-bar">
      <Column alignX="left">
        <Text variant="body-small" tone="neutralLight">
          Updated{" "}
          <Text variant="body-small" inline data-id="info-bar-updated-at">
            {updatedAt}
          </Text>
        </Text>
      </Column>
      <Column alignX="right">
        <WeatherSummary
          weatherData={weatherData}
          isDaylight={isDaylight(timeOfDay)}
        />
      </Column>
    </Columns>
  );
};
