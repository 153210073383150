import React from "react";
import { Text } from "@origin-digital/ods-core";
import { IChargeStatus } from "../../../contract/bff";
import { TextLinkButton } from "../../TextLinkButton/TextLinkButton";

interface IInvalidStatusMessageProps {
  status: IChargeStatus;
}

export const InvalidStatusMessage = ({
  status,
}: IInvalidStatusMessageProps) => {
  switch (status) {
    case IChargeStatus.PLUGGED_IN_FAULT:
      return (
        <Text data-id="display-text">
          It looks like there may be a problem with your charger that's
          preventing your vehicle's charge. As a first step, try reconnecting
          your charger and if the problem persists, visit our FAQs for more
          information.
        </Text>
      );
    case IChargeStatus.PLUGGED_IN_NO_POWER:
      return (
        <Text data-id="display-text">
          Snap! We can't charge your car right now. It looks like there may be a
          problem with your charger.
        </Text>
      );
    default:
      return (
        <Text data-id="display-text">
          We're unable to communicate with your vehicle at the moment. Please{" "}
          <TextLinkButton onClick={() => location.reload()}>
            try again
          </TextLinkButton>{" "}
          in a few minutes and if the problem persists, refer to your vehicle's
          app for help.
        </Text>
      );
  }
};
