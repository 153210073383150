import React from "react";
import {
  Box,
  Card,
  CardStackSection,
  Heading,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import { getPeriodNameForCurrentTime } from "@origin-digital/node-date-time";

export const PendingOrderWidget: React.FC<void> = () => (
  <CardStackSection variant="card-centered">
    <Card padding="medium" backgroundColor="transparent">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="full"
      >
        <Stack space={["large", "xlarge"]} alignX="center">
          <svg
            width="129"
            height="112"
            viewBox="0 0 129 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M64.5 106C92.6665 106 115.5 83.1665 115.5 55C115.5 26.8335 92.6665 4 64.5 4C36.3335 4 13.5 26.8335 13.5 55C13.5 83.1665 36.3335 106 64.5 106Z"
              fill="#F8F8F8"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M33.0247 82.0938H35.2163C40.9477 82.0938 41.9172 82.6369 42.9712 83.7771C44.9942 85.9658 46.1719 90.0063 46.1719 94.0469C46.1719 98.0871 44.992 102.128 42.969 104.316C41.9147 105.457 40.9454 106 35.2138 106H33.0222H30.8331C25.1015 106 24.1322 105.457 23.0779 104.316C21.0549 102.128 19.875 98.0871 19.875 94.0469C19.875 90.0063 21.0527 85.9658 23.0755 83.7771C24.1297 82.6369 25.0991 82.0938 30.8307 82.0938H33.0247Z"
              fill="#FA4616"
            />
            <mask
              id="mask0_3487_8126"
              maskUnits="userSpaceOnUse"
              x="19"
              y="82"
              width="28"
              height="24"
            >
              <path
                d="M33.0247 82.0938H35.2163C40.9477 82.0938 41.9172 82.6369 42.9712 83.7771C44.9942 85.9658 46.1719 90.0063 46.1719 94.0469C46.1719 98.0871 44.992 102.128 42.969 104.316C41.9147 105.457 40.9454 106 35.2138 106H33.0222H30.8331C25.1015 106 24.1322 105.457 23.0779 104.316C21.0549 102.128 19.875 98.0871 19.875 94.0469C19.875 90.0063 21.0527 85.9658 23.0755 83.7771C24.1297 82.6369 25.0991 82.0938 30.8307 82.0938H33.0247Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_3487_8126)">
              <path
                d="M33.0247 82.0938H35.2163C40.9477 82.0938 41.9172 82.6369 42.9712 83.7771C44.9942 85.9658 46.1719 90.0063 46.1719 94.0469C46.1719 98.0871 44.992 102.128 42.969 104.316C41.9147 105.457 40.9454 106 35.2138 106H33.0222H30.8331C25.1015 106 24.1322 105.457 23.0779 104.316C21.0549 102.128 19.875 98.0871 19.875 94.0469C19.875 90.0063 21.0527 85.9658 23.0755 83.7771C24.1297 82.6369 25.0991 82.0938 30.8307 82.0938H33.0247Z"
                fill="#FA4616"
              />
              <g opacity="0.28025">
                <g opacity="0.28025">
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.0714 93.2298V92.0383C25.6402 92.0383 25.2917 91.6887 25.2917 91.2575C25.2917 90.8264 25.6402 90.4779 26.0714 90.4768V89.2853C24.9829 89.2853 24.0991 90.1679 24.0991 91.2575C24.0991 92.3472 24.9829 93.2298 26.0714 93.2298Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M34.0401 88.4486V87.257C33.609 87.257 33.2605 86.9074 33.2605 86.4763C33.2605 86.0452 33.609 85.6966 34.0401 85.6956V84.504C32.9516 84.504 32.0679 85.3867 32.0679 86.4763C32.0679 87.5659 32.9516 88.4486 34.0401 88.4486Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.212 93.2298V92.0383C40.7809 92.0383 40.4323 91.6887 40.4323 91.2575C40.4323 90.8264 40.7809 90.4779 41.212 90.4768V89.2853C40.1235 89.2853 39.2397 90.1679 39.2397 91.2575C39.2397 92.3472 40.1235 93.2298 41.212 93.2298Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.0714 101.199V100.007C25.6402 100.007 25.2917 99.6574 25.2917 99.2263C25.2917 98.7952 25.6402 98.4466 26.0714 98.4456V97.254C24.9829 97.254 24.0991 98.1367 24.0991 99.2263C24.0991 100.316 24.9829 101.199 26.0714 101.199Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M34.0401 96.4173V95.2258C33.609 95.2258 33.2605 94.8762 33.2605 94.445C33.2605 94.0139 33.609 93.6654 34.0401 93.6643V92.4728C32.9516 92.4728 32.0679 93.3554 32.0679 94.445C32.0679 95.5347 32.9516 96.4173 34.0401 96.4173Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M34.0401 105.183V103.991C33.609 103.991 33.2605 103.642 33.2605 103.211C33.2605 102.78 33.609 102.431 34.0401 102.43V101.238C32.9516 101.238 32.0679 102.121 32.0679 103.211C32.0679 104.3 32.9516 105.183 34.0401 105.183Z"
                        fill="white"
                      />
                    </g>
                  </g>
                  <g opacity="0.28025">
                    <g opacity="0.28025">
                      <path
                        opacity="0.28025"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.212 101.199V100.007C40.7809 100.007 40.4323 99.6574 40.4323 99.2263C40.4323 98.7952 40.7809 98.4466 41.212 98.4456V97.254C40.1235 97.254 39.2397 98.1367 39.2397 99.2263C39.2397 100.316 40.1235 101.199 41.212 101.199Z"
                        fill="white"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M34 74.9095C33.0078 74.3732 31.895 73.7786 31.4563 73.4749C30.8315 73.043 30.2958 72.6295 29.5058 71.4736C28.9018 70.5894 28.3893 69.7548 28.0316 68.6007C27.7978 67.8475 27.5491 66.6486 27.5255 66.4093C27.4132 65.4005 26.9915 65.034 26.7 64.96C26.1214 64.8141 25.9797 64.8717 25.8422 64.8417C25.2973 64.8792 24.9809 65.7792 24.7129 66.6008L24.8476 69.8568C24.2324 71.7437 24.6776 72.9407 24.9805 74.5359C25.147 75.4111 24.7033 76.2941 24.977 77.0265C25.6237 78.7566 25.6271 82.3017 26.3877 82.9856C26.3963 82.9972 26.5356 83.1574 26.5811 83.1578C26.5811 83.1578 27.912 83.1397 29.102 82.7652C30.5767 82.3012 31.5204 81.5943 33.0825 81.1781C33.0825 81.1781 34.4612 80.7318 33.9974 80.5193C33.5338 80.3067 33.735 79.4669 33.4103 78.487C33.2041 77.8641 34.0354 77.9611 34.4293 77.3208C34.7005 76.8801 34.1808 75.0071 34 74.9095Z"
              fill="#3C3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.1259 83.4039C26.3502 82.6992 26.2646 81.3431 26.0768 79.45C25.8364 77.0271 25.6929 74.7522 25.5915 73.7305C25.4598 72.4008 25.3878 70.4908 25.4769 68.4334C25.5267 67.2801 25.6782 64.8057 26.2179 64.5784C26.2179 64.5784 24.8632 64.4132 24.4143 64.7586C23.8623 65.1836 23.7782 65.9846 23.6499 67.9615C23.5039 70.2152 23.8153 73.882 24.035 76.0995C24.1977 77.7399 24.3882 79.6605 24.5718 81.5121C24.7278 83.0865 24.9102 83.4793 25.3266 83.6256C25.3266 83.6256 26.1015 83.8418 27.1259 83.4039Z"
              fill="#FFC72C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.0856 76.2375L28.5564 74.1257L26.3135 76.5508L27.2972 77.3715L28.6678 75.89L30.2135 77.1804L31.0856 76.2375Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M30.3387 73.4579C30.4869 73.295 30.7388 73.2832 30.9015 73.4315C31.0644 73.5794 31.076 73.8316 30.9278 73.9941C30.7797 74.1569 30.5277 74.1688 30.3651 74.0207C30.2024 73.8723 30.1905 73.6205 30.3387 73.4579Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.0116 72.6122C31.1237 72.4859 31.3152 72.4769 31.4395 72.5924L31.7084 72.8432C31.8323 72.9588 31.8416 73.1548 31.7295 73.2808L31.0511 74.0437C30.939 74.17 30.7475 74.1787 30.6237 74.0635L30.3543 73.8124C30.2304 73.6972 30.2212 73.5011 30.3333 73.3745L31.0116 72.6122Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.7695 71.8394C28.9312 71.6903 29.1833 71.7002 29.3325 71.8619C29.4817 72.0236 29.4713 72.2757 29.3095 72.4251C29.1478 72.5743 28.8957 72.5641 28.7465 72.4022C28.5976 72.2405 28.6077 71.9884 28.7695 71.8394Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M29.4748 70.9981C29.5946 70.8792 29.7824 70.8884 29.8944 71.0187L30.1376 71.3023C30.2495 71.4324 30.2433 71.6347 30.1235 71.7535L29.4004 72.4704C29.2806 72.5892 29.0928 72.5797 28.9809 72.4493L28.7376 72.1658C28.6257 72.0358 28.632 71.8338 28.7518 71.715L29.4748 70.9981Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.0395 70.1959C28.2287 70.0836 28.4731 70.1459 28.5853 70.3353C28.6974 70.5243 28.6353 70.7686 28.4462 70.8809C28.2568 70.9934 28.0124 70.931 27.9001 70.7416C27.7878 70.5527 27.8504 70.3083 28.0395 70.1959Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.8397 70.1389C28.979 70.0844 29.1538 70.1062 29.2303 70.1874L29.3963 70.3639C29.473 70.4453 29.4216 70.5551 29.2823 70.6098L28.441 70.9387C28.3017 70.9931 28.127 70.9716 28.0505 70.8903L27.8845 70.7139C27.8081 70.6324 27.8592 70.5222 27.9985 70.4679L28.8397 70.1389Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.0425 78.0999C29.9865 77.5368 28.8023 76.9125 28.3356 76.5935C27.6707 76.1398 27.1008 75.7054 26.261 74.4903C25.6187 73.5608 25.074 72.6834 24.6945 71.4697C24.4463 70.6776 24.1828 69.4166 24.158 69.1649C24.0396 68.1038 23.591 67.7186 23.2806 67.6411C22.6647 67.4883 22.5137 67.5491 22.3674 67.5177C21.7871 67.5577 21.4492 68.5049 21.1629 69.3695L21.3027 72.7947C20.6454 74.7804 21.1182 76.0392 21.439 77.717C21.6152 78.6375 21.1418 79.5669 21.4324 80.3371C22.1191 82.1564 22.1186 85.8859 22.9278 86.6045C22.9369 86.6167 23.085 86.785 23.1335 86.7854C23.1335 86.7854 24.5507 86.7649 25.8183 86.3696C27.389 85.8797 28.3947 85.1351 30.0584 84.6954C30.0584 84.6954 31.527 84.2244 31.0333 84.0014C30.54 83.7783 30.7552 82.8946 30.4105 81.8641C30.1917 81.209 31.0768 81.3101 31.4968 80.6361C31.7862 80.1722 31.2349 78.2023 31.0425 78.0999Z"
              fill="#3C3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.2791 86.7671C22.4699 86.048 22.3832 84.6614 22.1907 82.7259C21.9443 80.2487 21.7988 77.9227 21.6948 76.8782C21.5598 75.5186 21.4883 73.5657 21.5854 71.4616C21.6397 70.2821 21.8029 67.7516 22.3672 67.5181C22.3672 67.5181 20.9522 67.3518 20.4825 67.7058C19.905 68.1415 19.8155 68.9608 19.6777 70.9827C19.5207 73.2875 19.8388 77.0366 20.064 79.3037C20.2308 80.9808 20.4261 82.9445 20.6143 84.8375C20.7742 86.4473 20.964 86.8485 21.3987 86.9974C21.3987 86.9974 22.2079 87.2169 23.2791 86.7671Z"
              fill="#FFC72C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.1374 78.9027L24.5965 76.6696L22.3627 79.2109L23.351 80.0787L24.716 78.5262L26.2689 79.8908L27.1374 78.9027Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.425 76.2043C26.6309 75.9778 26.9813 75.9614 27.2075 76.1676C27.434 76.3734 27.4502 76.724 27.2441 76.9499C27.0381 77.1764 26.6878 77.1928 26.4616 76.9869C26.2354 76.7806 26.2189 76.4305 26.425 76.2043Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.168 75.593C27.2644 75.487 27.4286 75.4791 27.535 75.5757L27.7652 75.7854C27.8713 75.8821 27.8789 76.0463 27.7825 76.1521L27.1994 76.7927C27.1031 76.8987 26.9388 76.9064 26.8328 76.81L26.6022 76.6C26.4962 76.5037 26.4886 76.3394 26.5849 76.233L27.168 75.593Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.2389 74.6608C25.4637 74.4534 25.8143 74.4672 26.0217 74.6921C26.2292 74.9171 26.2148 75.2677 25.9899 75.4754C25.765 75.6829 25.4144 75.6688 25.2069 75.4435C24.9998 75.2186 25.0139 74.8681 25.2389 74.6608Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.0324 74.1167C26.1379 74.0194 26.3021 74.0258 26.3994 74.1312L26.6107 74.3605C26.7079 74.4657 26.7013 74.6303 26.5959 74.7275L25.9593 75.3144C25.8539 75.4117 25.6896 75.405 25.5924 75.2996L25.3811 75.0703C25.2839 74.9652 25.2904 74.8009 25.3959 74.7036L26.0324 74.1167Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.3056 72.9234C24.5687 72.7673 24.9086 72.8539 25.0647 73.1173C25.2206 73.3802 25.1342 73.7201 24.8712 73.8762C24.6078 74.0327 24.2678 73.9459 24.1116 73.6825C23.9555 73.4197 24.0425 73.0798 24.3056 72.9234Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.195 72.5561C25.3183 72.4829 25.4775 72.5237 25.5507 72.647L25.7097 72.9148C25.7832 73.0384 25.7422 73.1974 25.6188 73.2708L24.8742 73.7125C24.7509 73.7857 24.5917 73.7452 24.5184 73.6219L24.3595 73.3542C24.2862 73.2305 24.327 73.071 24.4503 72.998L25.195 72.5561Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M91.3854 84.4312C87.8627 85.3556 78.8306 85.5109 76.8611 84.5533C73.3895 82.8663 71.8842 83.765 68.8563 81.3467C64.8465 78.1439 61.4172 79.0818 60.6619 79.2075C60.0406 79.3103 58.7532 79.6855 55.3664 80.5143C53.9385 80.8635 34.9373 85.0929 34.9373 85.0929C34.9373 85.0929 33.2541 85.4988 32.9395 81.0074C32.6249 76.516 32.7161 73.9417 32.7161 73.9417C32.7161 73.9417 44.8162 71.0099 48.8249 69.8817C51.5279 69.1208 57.4496 67.7838 59.962 67.4266C61.9316 67.1462 63.9262 66.9201 67.2873 68.0787C70.4617 69.1729 77.2283 70.496 77.2283 70.496L90.0214 69.6707L97.7604 74.0719L91.3854 84.4312Z"
              fill="#828283"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.3755 78.9065C94.7818 82.8909 91.5943 91.6565 81.2349 90.8596C67.8489 89.8299 63.0122 85.2425 62.2588 85.3893C61.6389 85.5093 56.0476 83.3588 52.6773 84.3763C51.2564 84.8049 30.3791 86.1335 30.3791 86.1335C30.3791 86.1335 29.563 85.9134 29.5353 83.2064C29.5077 80.4995 30.2349 78.1096 30.2349 78.1096C30.2349 78.1096 40.3153 75.8647 44.2999 74.4611C46.9867 73.5144 52.883 71.8873 55.3912 71.4813C57.3573 71.1627 59.3504 70.9183 62.7565 72.5915C65.9734 74.1718 74.063 73.3284 74.063 73.3284L85.6016 75.4523L91.5943 76.5159L96.3755 78.9065Z"
              fill="#9E999E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M94.546 40.4397C94.3787 39.5269 94.8403 38.2949 94.4412 37.374C93.905 36.1381 93.653 35.7588 92.3929 35.808C91.5616 35.8405 90.9265 35.9612 90.3118 35.6362C89.6972 35.3115 89.3211 34.4236 89.3676 33.5081C89.4135 32.5927 89.317 30.3677 89.317 30.3677C89.317 30.3677 88.3402 30.3218 88.2412 29.8667C88.1421 29.4114 88.533 29.0388 88.8931 28.6671C89.2534 28.2952 89.9713 27.4607 89.9713 27.4607C89.9713 27.4607 90.2981 27.0805 90.077 26.4519C89.8534 25.8158 90.062 23.6163 90.9606 22.5645C91.7617 21.6264 92.9657 20.1847 95.984 20.1359C99.0021 20.0878 101.632 20.4403 102.451 24.0266C103.271 27.6131 102.029 31.438 101.707 32.3271C101.386 33.2162 101.398 34.2334 101.458 34.9176C101.529 35.7309 102.172 37.0232 102.172 37.0232L94.546 40.4397Z"
              fill="#EDA154"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M104.909 25.9894L103.196 25.6909L94.1869 24.1149C94.0539 24.3281 93.9249 24.6077 93.9071 24.9337C93.8926 25.1558 93.9305 25.4006 94.0518 25.6616C94.0855 25.7335 94.1227 25.7998 94.1631 25.8618C94.5759 26.4909 95.3122 26.617 95.7324 26.6648L95.8193 26.6742C95.9484 26.6868 96.0905 26.6501 96.2407 26.5912C96.2814 26.5754 96.3225 26.5577 96.3635 26.5395C96.4002 26.5232 96.4355 26.506 96.4729 26.4881C96.5259 26.4621 96.5799 26.4358 96.6338 26.4097C96.6429 26.4065 96.6507 26.4026 96.6581 26.3987C96.7088 26.3733 96.7605 26.3486 96.8128 26.3253C96.9426 26.266 97.074 26.2109 97.2105 26.172L97.2117 26.1718C97.3551 26.1302 97.5011 26.1077 97.6495 26.1147C98.1831 26.1407 98.548 26.3568 98.7147 26.8906C98.9014 27.4903 98.6971 28.4587 98.5457 29.9446C98.3954 31.4316 99.4079 32.0252 100.136 32.098C100.609 32.1466 100.986 32.1852 101.447 32.2324C101.47 32.2347 101.492 32.2336 101.516 32.2299C101.917 32.1805 102.412 31.3764 103.017 30.5827C103.822 29.5314 104.73 28.3358 105.019 26.8775C105.037 26.7887 104.958 26.6957 104.97 26.6054L104.909 25.9894Z"
              fill="#3D3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M105.066 26.9011L94.0821 24.3775L89.598 23.347C88.2178 23.0587 87.2771 21.8242 87.3122 20.4627C87.3193 20.1884 87.5779 19.9896 87.8453 20.0504L91.7895 20.9442C91.7833 20.9158 93.886 17.2866 99.6138 18.65C101.293 19.0493 103.344 20.1432 104.343 22.5904C104.538 23.0662 104.687 23.4989 104.8 23.8932C105.202 25.2865 105.159 26.2033 105.066 26.9011Z"
              fill="#C27A30"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M89.3954 81.6655C86.8824 80.9618 84.5391 77.2559 81.3332 75.4869C77.5741 73.4125 73.1184 73.2971 73.1184 73.2971C73.1184 73.2971 74.6882 68.2011 77.4359 62.889C81.5406 54.9526 86.3086 44.2757 90.568 40.691C93.6826 38.07 95.7991 37.0879 98.3315 36.5067C98.3801 36.4968 98.4284 36.4851 98.4764 36.4765C101.04 35.913 101.995 36.3029 101.995 36.3029C105.984 35.322 105.387 54.2428 103.856 59.7942C101.208 69.3928 92.8066 81.7824 92.8066 81.7824C92.8066 81.7824 90.717 82.0357 89.3954 81.6655Z"
              fill="#628BC1"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M63.1029 37.2953C63.1441 37.7861 63.4339 38.228 63.4477 38.7034C63.4577 39.0177 63.3424 39.2699 63.4316 39.5893C63.5473 39.9952 63.9049 40.2728 64.0306 40.6625C64.2261 41.2776 63.9711 41.8224 64.3551 42.4244C64.7301 43.009 65.29 43.6935 65.7721 44.1877C66.1411 44.567 66.5979 44.8299 67.0126 45.1421C67.1205 45.225 67.2228 45.3065 67.3229 45.3969C67.643 45.6912 67.914 46.0792 68.1707 46.4764C68.3714 46.781 68.56 47.095 68.7583 47.3679C69.7586 48.7599 70.9665 49.7904 72.2519 50.9157C72.5609 51.189 73.2935 52.1098 73.7774 51.7829C74.0169 51.6234 74.1652 51.0051 74.2729 50.7573C74.4543 50.3476 74.6331 49.9368 74.8146 49.5264C75.0056 49.0877 75.1963 48.6519 75.3902 48.2136C75.5324 47.8967 75.9195 47.3131 75.9129 46.9567C75.8959 46.2486 74.5651 45.9441 74.0623 45.6118C73.3141 45.1197 72.5639 44.624 71.8158 44.1319C71.5088 43.9305 71.1701 43.734 70.8432 43.5248C70.4581 43.2871 70.0841 43.0271 69.7863 42.7164C69.7433 42.6728 69.7044 42.6271 69.6682 42.5819C68.8409 41.5471 69.2246 39.8511 68.2152 38.9546C67.7571 38.5463 67.205 38.1168 66.5815 38.4186C66.301 38.5531 66.1409 38.8538 65.7906 38.763C65.4671 38.6778 65.0259 38.0973 64.8297 37.843C64.5476 37.4771 64.2211 36.4684 63.7094 36.3535C63.1182 36.221 63.0703 36.8896 63.1029 37.2953Z"
              fill="#EDA154"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M83.4341 65.7133C80.4883 64.2006 78.7761 63.1323 78.7761 63.1323L66.2087 46.3433C66.2087 46.3433 66.3189 45.4779 67.0021 44.4367C67.2342 44.0824 67.5313 43.7078 67.9143 43.34C69.1023 42.1986 70.1007 41.9265 70.5611 41.8637C70.7365 41.8389 70.8342 41.8454 70.8342 41.8454C71.5953 42.7422 81.4775 51.6381 82.6188 52.9382C83.6857 54.1503 85.0026 55.4959 87.388 56.0738L89.6053 56.6106C91.2794 57.0162 92.1928 58.782 91.6242 60.4079C91.6226 60.413 91.6206 60.4184 91.6191 60.4232C90.377 61.9363 88.9626 63.5082 87.5322 65.0117C87.526 65.0183 87.5203 65.0246 87.5141 65.0309C86.46 66.14 84.7955 66.4122 83.4341 65.7133Z"
              fill="#628BC1"
            />
            <path
              d="M66.8991 41.1797C66.5932 41.351 66.2135 41.2637 66.0111 40.9896L65.9643 40.9172L60.9639 32.0368C60.7781 31.7059 60.896 31.2871 61.2269 31.1015C61.5323 30.9305 61.9121 31.0178 62.1145 31.2919L62.1612 31.3643L67.1621 40.2456C67.3326 40.5508 67.2458 40.9302 66.9715 41.1329L66.8991 41.1797Z"
              fill="#3C3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.3694 26.037L97.5013 26.1726C96.9888 26.3668 96.5363 26.795 96.1546 26.788L90.3572 26.6775L90.3694 26.037Z"
              fill="#3C3C3C"
              stroke="#3C3C3C"
              strokeWidth="0.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M90.2933 25.5714C90.2678 25.4049 90.1119 25.2907 89.9457 25.316L89.8396 25.3324C89.673 25.3576 89.5584 25.5132 89.5842 25.6799L89.984 28.2968C90.0095 28.4633 90.1651 28.5777 90.3319 28.5521L90.4378 28.5361C90.6046 28.5104 90.7188 28.3547 90.6932 28.1886L90.2933 25.5714Z"
              fill="#3C3C3C"
              stroke="#3C3C3C"
              strokeWidth="0.5"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M109.922 44.0169V82.8906H86.0156C86.0156 82.8906 92.0162 60.1316 94.0769 52.5135C97.7834 38.8125 109.922 44.0169 109.922 44.0169Z"
              fill="#3C3C3C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M67.349 88.5503L71.1557 88.9146C71.5938 88.9566 71.9149 89.3457 71.873 89.7838C71.8701 89.8136 71.8656 89.8432 71.8594 89.8725L68.6206 105.21C68.5297 105.64 68.1069 105.916 67.6763 105.825C67.6728 105.824 67.6694 105.823 67.666 105.823L66.7313 105.612C66.3608 105.529 66.1006 105.196 66.1096 104.816L66.4764 89.3246C66.4868 88.8847 66.8519 88.5364 67.2919 88.5469C67.311 88.5473 67.33 88.5484 67.349 88.5503Z"
              fill="#C27A30"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M101.867 88.5504L98.0599 88.9147C97.6218 88.9567 97.3006 89.3458 97.3426 89.7839C97.3454 89.8137 97.35 89.8433 97.3561 89.8726L100.595 105.21C100.686 105.641 101.109 105.916 101.539 105.825C101.543 105.824 101.546 105.824 101.55 105.823L102.484 105.612C102.855 105.529 103.115 105.196 103.106 104.816L102.739 89.3248C102.729 88.8848 102.364 88.5366 101.924 88.547C101.905 88.5474 101.886 88.5486 101.867 88.5504Z"
              fill="#C27A30"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M109.922 62.9688V93.25H58.9219V69.1575C58.9219 65.7395 61.1763 62.9688 63.9577 62.9688H109.922Z"
              fill="#3C3C3C"
            />
          </svg>

          <Stack space={["medium", "large"]}>
            <Heading variant="h4" align="center">
              Good {getPeriodNameForCurrentTime()}!
            </Heading>
            <Text align="center">
              We’re working on creating your account. It may take a few hours,
              so check back soon!
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Card>
  </CardStackSection>
);
