import React from "react";
import { Box, Card, Stack, Text } from "@origin-digital/ods-core";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";

interface IFlowStatusCardProps {
  label: string;
  description: string;
  animation: object;
}

export const FlowStatusCard = ({
  label,
  description,
  animation,
}: IFlowStatusCardProps) => (
  <Card
    data-id="flow-status"
    backgroundColor="grey50"
    paddingXSpecific="medium"
    paddingYSpecific="xlarge"
  >
    <Box display="flex">
      <Box display="flex" marginRight="medium">
        <Lottie
          animationData={animation}
          play
          loop
          style={{ width: 72, height: 72 }}
        />
      </Box>
      <Stack space="xxsmall">
        <Text tone="neutralDark" weight="medium" data-id="flow-status-label">
          {label}
        </Text>
        <Text variant="body-small">{description}</Text>
      </Stack>
    </Box>
  </Card>
);
