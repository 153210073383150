// TODO: This will need a refactor to support multiple environments
export interface IConfig {
  widgetName: string;
}

export const config: IConfig = {
  widgetName: "ev-icharge-phase-2-widget",
};

export const getEnv = () => (window as any)?.oetal?.env?.name;

export const getVehicleEndpoint = () => {
  switch (getEnv()) {
    case "prod":
      return "wss://www.originenergy.com.au/ws/ev-icharge/api/v2/vehicles";
    case "test":
    case "staging":
      return "wss://staticsite-cdn-staging.origindigital-pac.com.au/ws/ev-icharge/api/v2/vehicles";
    default:
      return "ws://localhost:6006/ws/vehicles";
  }
};

export const apiBasePath = "/ev-icharge";
