import React, { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";
import {
  Card,
  Column,
  Columns,
  IconButton,
  Text,
} from "@origin-digital/ods-core";
import { IconChevronRight } from "@origin-digital/ods-icons";

const UnstyledButton = styled.button`
  all: unset;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

interface IControlPanelProps {
  onClick: MouseEventHandler;
  label: ReactNode;
  "data-id"?: string;
}

export const ControlPanel = ({
  onClick,
  label,
  "data-id": dataId,
}: IControlPanelProps) => {
  return (
    <UnstyledButton onClick={onClick}>
      <Card
        data-id={dataId}
        paddingXSpecific="medium"
        paddingYSpecific="medium"
        outline
      >
        <Columns space="none" alignY="center">
          <Column>
            <Text variant="body-small" tone="neutralDark">
              {label}
            </Text>
          </Column>
          <Column width="content">
            <IconButton noIconPadding onClick={onClick}>
              <IconChevronRight tone="neutral" size="small" />
            </IconButton>
          </Column>
        </Columns>
      </Card>
    </UnstyledButton>
  );
};
