import React, { MouseEventHandler } from "react";
import { IconButton } from "@origin-digital/ods-core";
import { IconInfo } from "@origin-digital/ods-icons";

export const InfoButton = ({
  onClick,
  "data-id": dataId,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
  "data-id"?: string;
}) => (
  <IconButton onClick={onClick} data-id={dataId} noIconPadding>
    <IconInfo size={"xsmall"} color={"neutralLight"} />
  </IconButton>
);
