// @ts-nocheck

export interface IIChargeSchedule {
    targetBatteryLevel: number | null;
    estimatedBatteryLevel: number | null;
    targetChargeTime: string;
    targetCanBeAchieved: boolean;
    targetEstimatedState: TargetEstimatedStateEnum;
    scheduleId: string;
}
export enum TargetEstimatedStateEnum {
    CAN_MEET = 'CAN_MEET',
    NO_CHARGE_STATIC = 'NO_CHARGE_STATIC',
    INSUFFICIENT_TIME_AND_NO_CHARGE_STATIC = 'INSUFFICIENT_TIME_AND_NO_CHARGE_STATIC',
    INSUFFICIENT_TIME = 'INSUFFICIENT_TIME',
    UNKNOWN = 'UNKNOWN'
}

