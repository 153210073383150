import React from "react";
import { Button } from "@origin-digital/ods-core";
import { deactivateInstantCharge } from "../../../services/instantCharge";

interface IStopButtonProps {
  vehicleId: string;
  handleSuccessSnackbarOpen: () => void;
  handleErrorSnackbarOpen: () => void;
  handleClose: () => void;
}

export const StopInstantChargeButton = ({
  vehicleId,
  handleSuccessSnackbarOpen,
  handleErrorSnackbarOpen,
  handleClose,
}: IStopButtonProps) => {
  const [submitting, setSubmitting] = React.useState(false);

  const handleStart = async () => {
    setSubmitting(true);
    try {
      await deactivateInstantCharge(vehicleId);
      handleSuccessSnackbarOpen();
      handleClose();
    } catch (e) {
      handleErrorSnackbarOpen();
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Button onClick={handleStart} spinner={submitting}>
      Stop
    </Button>
  );
};
