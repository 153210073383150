import { UnpluggedIcon } from "@origin-digital/neon-common";
import { Inline, Text } from "@origin-digital/ods-core";
import React from "react";

export const UnpluggedMessage = () => (
  <Inline alignY="center">
    <UnpluggedIcon />
    <Text variant="body-small">Unplugged</Text>
  </Inline>
);
