import React from "react";
import { TrackingProvider } from "../TrackingProvider";
import { Card } from "@origin-digital/ods-core";
import { useUpdateCommbattWidgetStatus } from "../../hooks/useUpdateCommbattWidgetStatus";
import { useCommbattWidgetData } from "../../hooks/useCommbattWidgetData";
import { Loader } from "../Loader/Loader";
import { CommunityBatteryWidgetActive } from "./CommunityBatteryWidgetActive";
import { CommunityBatteryWidgetError } from "./CommunityBatteryWidgetError";
import { CommunityBatteryWidgetSetup } from "./CommunityBatteryWidgetSetup";
import { IAccountStatus, IDashboard } from "../../contract/generated/bff/src";

const WidgetState = ({
  data,
  accountId,
}: {
  data: IDashboard;
  accountId: string;
}) => {
  const { status, lifetimeSavings, solarExport, usageDateLabel } = data;
  switch (status) {
    case IAccountStatus.ACTIVE:
      return (
        <CommunityBatteryWidgetActive
          accountNumber={accountId}
          usageDateLabel={usageDateLabel}
          lifetimeSavings={lifetimeSavings}
          solarExport={solarExport}
        />
      );
    case IAccountStatus.SETUP:
      return <CommunityBatteryWidgetSetup />;
    default:
      return <CommunityBatteryWidgetError />;
  }
};

export interface ICommunityBatteryWidgetProps {
  accountId: string;
}

export const CommunityBatteryWidget = ({
  accountId,
}: ICommunityBatteryWidgetProps) => {
  return <CommunityBattery accountId={accountId} />;
};

const CommunityBattery = ({ accountId }: ICommunityBatteryWidgetProps) => {
  const { updateStatusToError, updateStatusToComplete } =
    useUpdateCommbattWidgetStatus();
  const { loading, data, error } = useCommbattWidgetData(accountId);

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    updateStatusToError(error);
    return (
      <Card data-id="community-battery-widget-card-error">
        <CommunityBatteryWidgetError />
      </Card>
    );
  }

  updateStatusToComplete();

  if (data?.isDisplayed && data?.data) {
    return (
      <TrackingProvider>
        <Card data-id="community-battery-widget-card" key={accountId}>
          <WidgetState data={data.data} accountId={accountId} />
        </Card>
      </TrackingProvider>
    );
  } else {
    return null;
  }
};
