import React from "react";
import { Button, Inline, Stack, Text } from "@origin-digital/ods-core";
import {
  ErrorSnackbar,
  FlashIcon,
  SuccessSnackbar,
  useSnackbarState,
} from "@origin-digital/neon-common";
import { DisableInstantChargeDialog } from "./DisableInstantChargeDialog";

interface IInstantChargeActiveProps {
  vehicleId: string;
}

export const InstantChargeActive = ({
  vehicleId,
}: IInstantChargeActiveProps) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [showErrorSnackbar, handleErrorSnackbarOpen, handleErrorSnackbarClose] =
    useSnackbarState();
  const [
    showSuccessSnackbar,
    handleSuccessSnackbarOpen,
    handleSuccessSnackbarClose,
  ] = useSnackbarState();

  const handleClick = () => setShowDialog(true);
  return (
    <>
      <SuccessSnackbar
        open={showSuccessSnackbar}
        onClose={handleSuccessSnackbarClose}
        displayText="Instant charge stopped."
      />
      <ErrorSnackbar
        open={showErrorSnackbar}
        onClose={handleErrorSnackbarClose}
        displayText="A problem occurred. Please try again."
      />
      <DisableInstantChargeDialog
        open={showDialog}
        vehicleId={vehicleId}
        handleSuccessSnackbarOpen={handleSuccessSnackbarOpen}
        handleErrorSnackbarOpen={handleErrorSnackbarOpen}
        handleClose={() => setShowDialog(false)}
      />
      <Stack space="medium">
        <Inline alignY="center" space="none">
          <FlashIcon />
          <Text variant="body-small">Instant charge active</Text>
        </Inline>
        <Button onClick={handleClick} size="small">
          Stop
        </Button>
      </Stack>
    </>
  );
};
