import React, { useEffect } from "react";
import { Card, Divider, Heading, Stack, Text } from "@origin-digital/ods-core";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { ICardAA } from "@origin-digital/reporting-client";
import { BaseWidget } from "@origin-digital/widget-helpers";
import { BasicHeader } from "../BasicHeader/BasicHeader";
import { config } from "../../config";
import { LinkAccountButton } from "./LinkAccountButton";

interface ILinkingCardProps {
  analyticsData: ICardAA;
}

const LinkingCardContent = ({ analyticsData }: ILinkingCardProps) => {
  const { updateWidgetStatus } = useWidgetProvider();

  useEffect(() => {
    updateWidgetStatus(WidgetStatusCode.COMPLETE, [analyticsData]);
  }, []);
  return (
    <Card data-id="ev-icharge-card">
      <Stack space="large">
        <Stack space="medium">
          <BasicHeader />
          <Divider />
          <Heading variant="h4">
            You're one step away from receiving 8c per kWh EV charging
          </Heading>
          <Text>
            Just link your EV account to Origin via Enode, our partner
          </Text>
        </Stack>
        <LinkAccountButton />
      </Stack>
    </Card>
  );
};

export const LinkingCard = ({ analyticsData }: ILinkingCardProps) => {
  return (
    <BaseWidget name={config.widgetName}>
      <LinkingCardContent analyticsData={analyticsData} />
    </BaseWidget>
  );
};
