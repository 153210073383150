import React, { useEffect, useRef, useState } from "react";

import { CardStackSection } from "@origin-digital/ods-core";
import { useAppMetricsContext } from "@origin-digital/tal-fe";
import { useToggleContext } from "@origin-digital/toggle";
import { WidgetStatusCode } from "@origin-digital/widget-provider";
import { AppReadyState } from "src/context/DashboardAppMetricsProvider";
import { Loader } from "../components/Loader";
import { useOnStaleUserData } from "../hooks";
import { useUserAccountsQuery } from "./queries";
import { DashboardMonitoring } from "./widgetHelpers/widgetAnalytics";
import { WidgetLoader } from "./WidgetLoader";
import { getWidgetManifest, WidgetManifest } from "./widgetManifest";

export const nonAsyncWidgets = [
  "BrazeCardWidget",
  "ResearchCommunitySignUp",
  "GstDisclaimerWidget",
  "PortfolioSummaryWidgetClosed",
];

export const DashboardPage: React.FC = () => {
  const { data, loading, error, refetch } = useUserAccountsQuery();
  const { hasStaleData, setHasStaleData } = useOnStaleUserData();
  const [widgetManifest, setWidgetManifest] = useState<WidgetManifest>();
  const dashboardAnalytics = useRef<DashboardMonitoring>();
  const { toggles } = useToggleContext();
  const { initAppReadyState } = useAppMetricsContext<AppReadyState>();

  useEffect(() => {
    if (hasStaleData) {
      refetch();
      setHasStaleData(false);
    }
  }, [hasStaleData]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const filteredWidgetManifests = getWidgetManifest(
      toggles,
      data.viewer.digital
    );

    dashboardAnalytics.current = new DashboardMonitoring(
      data,
      filteredWidgetManifests
    );
    setWidgetManifest(filteredWidgetManifests);

    const initState = filteredWidgetManifests.reduce((acc, { name }) => {
      if (!nonAsyncWidgets.includes(name)) acc![name] = WidgetStatusCode.INIT;
      return acc;
    }, {} as AppReadyState);
    initAppReadyState(initState);

    setHasStaleData(false);
  }, [data]);

  if (error) {
    throw error;
  }

  const digitalData = data?.viewer.digital;

  return (
    <Loader isLoading={loading} variant="page">
      {widgetManifest && digitalData ? (
        <CardStackSection data-id="dashboard" variant="widget">
          {widgetManifest.map((widgetDefinition) => (
            <WidgetLoader
              key={widgetDefinition.name}
              dashboardAnalytics={dashboardAnalytics}
              widgetDefinition={widgetDefinition}
              userData={digitalData}
            />
          ))}
        </CardStackSection>
      ) : null}
    </Loader>
  );
};
