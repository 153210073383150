import { WidgetDefinition } from "../../WidgetDefinition";
import {
  getCommunityBatteryWidgetProps,
  hasCombatService,
} from "./commbattWidgetPropsHelper";
import { CommunityBatteryWidget } from "./src/components/CommunityBatteryWidget/CommunityBatteryWidget";

export const communityBatteryWidgetDefinition: WidgetDefinition<{
  accountId: string;
}> = {
  widgetComponent: CommunityBatteryWidget,
  name: "CommunityBatteryWidgetV2",
  propsBuilder: getCommunityBatteryWidgetProps,
  shouldLoad: hasCombatService,
};
