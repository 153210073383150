import React, { ReactNode } from "react";
import { Alert, Card, Stack, Text } from "@origin-digital/ods-core";
import { ICardAA } from "@origin-digital/reporting-client";
import { BasicHeader } from "../BasicHeader/BasicHeader";
import { useWidgetError } from "../../analytics";

interface IErrorCardProps {
  analyticsData: ICardAA;
  displayText: ReactNode;
  error: any;
}

export const ErrorCard = ({
  analyticsData,
  displayText,
  error,
}: IErrorCardProps) => {
  useWidgetError(analyticsData, error);
  return (
    <Card>
      <Stack space="medium" dividers>
        <Stack space="medium">
          <BasicHeader />
        </Stack>
        <Alert data-id="error-text" tone="critical">
          <Text>{displayText}</Text>
        </Alert>
      </Stack>
    </Card>
  );
};
