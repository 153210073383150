import React from "react";

export const RainIcon = () => (
  <svg
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-id="weather-icon-rain"
  >
    <title>Rain</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-811.000000, -1492.000000)">
        <g transform="translate(809.000000, 1490.000000)">
          <g id="rain">
            <path
              d="M16.4219836,17.0213854 C16.8358584,17.1084137 17.0760294,17.5506075 16.9782607,18.0410935 C16.8267288,18.8017608 16.6784816,19.5631435 16.5257421,20.3235552 C16.4412089,20.7445924 16.1521051,21.0006692 15.7694835,21.0024663 C15.2753745,21.0047574 14.9155527,20.5394652 15.0172824,20.0293045 C15.1721474,19.2521821 15.3258047,18.474753 15.482795,17.6980906 C15.5835586,17.1995303 15.9668565,16.9256696 16.4219836,17.0213854 Z M12.4276293,17.0218801 C12.8410964,17.1131738 13.0762099,17.551864 12.9776295,18.0462909 C12.8277525,18.7985065 12.6786491,19.5508241 12.5292555,20.3031418 C12.4420851,20.7419853 12.1573672,21.0017152 11.7638191,21.0012561 C11.269225,21.0005913 10.9150318,20.5357953 11.0178184,20.0229257 C11.1718533,19.2544643 11.3256465,18.4859007 11.4787627,17.717235 C11.5814043,17.202373 11.9657663,16.9200112 12.4276293,17.0218801 Z M8.43633529,17.0232924 C8.84837522,17.1204822 9.07611336,17.5551041 8.97689255,18.054434 C8.82745605,18.8065698 8.67884275,19.5588589 8.52853462,20.3107905 C8.44171036,20.745208 8.14956829,21.0039383 7.75491258,20.9999547 C7.26064868,20.9949496 6.91436856,20.5300932 7.01857703,20.0136542 C7.17198431,19.2538065 7.32534316,18.4939588 7.47594184,17.7334982 C7.58121564,17.2018908 7.96899514,16.9129771 8.43633529,17.0232924 Z"
              id="Combined-Shape"
              fill="#FFB432"
            />
            <path
              d="M18.125,6.9075 C17.5583333,4.104375 15.0333333,2 12,2 C9.59166667,2 7.5,3.3325 6.45833333,5.2825 C3.95,5.5425 2,7.614375 2,10.125 C2,12.814375 4.24166667,15 7,15 L17.8333333,15 C20.1333333,15 22,13.18 22,10.9375 C22,8.7925 20.2916667,7.05375 18.125,6.9075 Z M17.8333333,13.375 L7,13.375 C5.15833333,13.375 3.66666667,11.920625 3.66666667,10.125 C3.66666667,8.329375 5.15833333,6.875 7,6.875 L7.59166667,6.875 C8.14166667,4.998125 9.9,3.625 12,3.625 C14.5333333,3.625 16.5833333,5.62375 16.5833333,8.09375 L16.5833333,8.5 L17.8333333,8.5 C19.2166667,8.5 20.3333333,9.58875 20.3333333,10.9375 C20.3333333,12.28625 19.2166667,13.375 17.8333333,13.375 Z"
              id="Shape"
              fill="#FFB432"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
