import { useEffect, useState } from "react";
import {
  eventListener,
  IStaleData,
  StaleDataType,
  TOPICS,
} from "@origin-digital/event-dispatcher";

export const useOnStaleUserData = () => {
  const [hasStaleData, setHasStaleData] = useState<boolean>(false);

  useEffect(() => {
    const unsubFn = eventListener.addMulticastListener(
      TOPICS.STALE_DATA,
      ({ payload: { type } }: IStaleData) => {
        if (type === StaleDataType.USER_DATA) {
          setHasStaleData(true);
        }
      }
    );

    return () => unsubFn();
  }, []);

  return { hasStaleData, setHasStaleData };
};
