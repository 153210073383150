import React from "react";
import { DividerCard, Stack } from "@origin-digital/ods-core";
import ContentLoader from "react-content-loader";

export const Loader = () => (
  <DividerCard data-id="community-battery-widget-loading">
    <ContentLoader
      speed={2}
      height={50}
      foregroundColor="#f8f8f8"
      backgroundColor="#f1f1f1"
    >
      <circle cx="18" cy="20" r="19" />
      <rect x="50" y="0" rx="0" ry="0" width="150" height="25" />
      <rect x="50" y="30" rx="0" ry="0" width="110" height="18" />
    </ContentLoader>
    <Stack space="medium">
      <ContentLoader
        speed={2}
        height={65}
        foregroundColor="#f8f8f8"
        backgroundColor="#f1f1f1"
      >
        <rect x="0" y="2" rx="0" ry="0" width="30" height="30" />
        <rect x="50" y="0" rx="0" ry="0" width="140" height="15" />
        <rect x="50" y="20" rx="0" ry="0" width="150" height="15" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        height={100}
        width={"100%"}
        foregroundColor="#f8f8f8"
        backgroundColor="#f1f1f1"
      >
        <circle cx="5" cy="5" r="5" />
        <rect x="15" y="0" rx="0" ry="0" width="100" height="10" />
        <circle cx="140" cy="5" r="5" />
        <rect x="85%" y="0" rx="0" ry="0" width="75" height="10" />
        <rect x="0" y="30" rx="0" ry="0" width="100%" height="5" />
        <rect x="0" y="50" rx="0" ry="0" width="35%" height="35" />
        <rect x="65%" y="50" rx="0" ry="0" width="35%" height="35" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        height={20}
        foregroundColor="#f8f8f8"
        backgroundColor="#f1f1f1"
      >
        <rect x="0" y="0" rx="0" ry="0" width="40%" height="20" />
      </ContentLoader>
    </Stack>
  </DividerCard>
);
