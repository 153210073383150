import { TrackingProvider as OriginTrackingProvider } from "@origin-digital/ods-core";
import { trackingProviderCaptureClickCard } from "@origin-digital/widget-helpers";
import React from "react";
import { analyticsData } from "./analytics";

export const TrackingProvider: React.FC = ({ children }) => {
  return (
    <OriginTrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(analyticsData)}
    >
      {children}
    </OriginTrackingProvider>
  );
};
