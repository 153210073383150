import { Card, Divider, Heading, Stack, Text } from "@origin-digital/ods-core";
import Markdown from "markdown-to-jsx";
import React from "react";
import { useWidgetComplete } from "../../analytics";
import { IIntervention } from "../../contract/bff";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import { BasicHeader } from "../BasicHeader/BasicHeader";
import { RelinkVehicleButton } from "../RelinkVehicleButton";
import { WaitingForUpdatesCard } from "./WaitingForUpdatesCard";
import { useRelinkingState } from "./useRelinkingState";

interface IInterventionProps {
  data: IIntervention;
}

const Intervention = ({
  data: { title, message, shouldRelink },
}: IInterventionProps) => (
  <Stack space="small">
    <Stack space="none">
      <Heading variant="h4">{title}</Heading>
      <Text>
        <Markdown>{message}</Markdown>
      </Text>
    </Stack>
    {shouldRelink && <RelinkVehicleButton label="Link my EV" />}
  </Stack>
);

export const InterventionCard = () => {
  const { vehicleId, interventions } = useVehicleLiveData();
  const { relinkingCompleted } = useRelinkingState();

  useWidgetComplete();

  return relinkingCompleted ? (
    <WaitingForUpdatesCard vehicleId={vehicleId} />
  ) : (
    <InterventionDetails vehicleId={vehicleId} interventions={interventions} />
  );
};

const InterventionDetails = ({
  vehicleId,
  interventions,
}: {
  vehicleId: string;
  interventions: IIntervention[];
}) => (
  <Card data-id={`card-ev-icharge-intervention-${vehicleId}`}>
    <Stack space="large">
      <Stack space="medium">
        <BasicHeader />
        <Divider />
        <Stack space="small">
          <Heading variant="h4">Uh oh!</Heading>
          <Text>
            There's a problem communicating with your car which will prevent you
            receiving 8c/kWh charging. Follow the steps below to rectify this
            issue.
          </Text>
        </Stack>
        {interventions.map((data) => (
          <Intervention key={data.id} data={data} />
        ))}
      </Stack>
    </Stack>
  </Card>
);
