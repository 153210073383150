import { Card, Column, Columns, Stack } from "@origin-digital/ods-core";
import React from "react";
import ContentLoader from "react-content-loader";

export const Loader = () => (
  <Card data-id="battery-maximiser-loader">
    <Stack space="medium" dividers>
      <Columns alignY="center" space="xsmall" data-id="header">
        <Column width="content">
          <ContentLoader
            speed={2}
            height={50}
            width="32"
            foregroundColor="#f8f8f8"
            backgroundColor="#f1f1f1"
          >
            <circle cx="16" cy="23" r="16" />
          </ContentLoader>
        </Column>
        <Column>
          <Stack space="none">
            <ContentLoader
              speed={2}
              height={15}
              foregroundColor="#f8f8f8"
              backgroundColor="#f1f1f1"
            >
              <rect x="0" y="0" rx="0" ry="0" width="200" height="15" />
            </ContentLoader>
            <ContentLoader
              speed={2}
              height={20}
              width="100%"
              foregroundColor="#f8f8f8"
              backgroundColor="#f1f1f1"
            >
              <rect x="0" y="5" rx="0" ry="0" width="150" height="15" />
              <rect x="90%" y="5" width="50" height="15" fill="#000" />
            </ContentLoader>
          </Stack>
        </Column>
      </Columns>

      <Stack space="small">
        <ContentLoader
          speed={2}
          height={15}
          width="100%"
          foregroundColor="#f8f8f8"
          backgroundColor="#f1f1f1"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100" height="15" />
          <rect x="90%" y="0" width="50" height="15" fill="#000" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          height={132}
          width="100%"
          foregroundColor="#f8f8f8"
          backgroundColor="#f1f1f1"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="132" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          height={48}
          width="100%"
          foregroundColor="#f8f8f8"
          backgroundColor="#f1f1f1"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="48" />
        </ContentLoader>

        <Stack space="medium">
          <ContentLoader
            speed={2}
            height={62}
            width="100%"
            foregroundColor="#f8f8f8"
            backgroundColor="#f1f1f1"
          >
            <rect x="0" y="4" rx="0" ry="0" width="100%" height="62" />
          </ContentLoader>

          <ContentLoader
            speed={2}
            height={24}
            width="100%"
            foregroundColor="#f8f8f8"
            backgroundColor="#f1f1f1"
          >
            <rect x="0" y="0" rx="0" ry="0" width="132" height="24" />
          </ContentLoader>
        </Stack>
      </Stack>
    </Stack>
  </Card>
);
