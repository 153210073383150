import React from "react";
import { ChargingStatusEnum } from "../../contract/generated/bff/src";
import { roundDown } from "@origin-digital/neon-common";
import { Box, Inline, Text } from "@origin-digital/ods-core";
import { BatteryChargingIcon } from "./icons/BatteryChargingIcon";

interface IBatteryChargeStatusProps {
  soc: number;
  chargingStatus: ChargingStatusEnum;
}

const getDescriptionForChargingStatus = (
  chargingStatus: ChargingStatusEnum
) => {
  switch (chargingStatus) {
    case ChargingStatusEnum.CHARGING:
      return "Battery charging";
    case ChargingStatusEnum.DISCHARGING:
      return "Battery discharging";
    case ChargingStatusEnum.STANDBY:
    default:
      return "Battery not charging";
  }
};

export const BatteryChargeStatus = ({
  soc,
  chargingStatus,
}: IBatteryChargeStatusProps) => {
  const chargingStatusDesc = getDescriptionForChargingStatus(chargingStatus);
  const socRounded = roundDown(soc, 0);
  return (
    <Box display="flex" justifyContent="space-between">
      <Text variant="body-small">{chargingStatusDesc}</Text>
      <Inline>
        <BatteryChargingIcon soc={socRounded} />
        <Text variant="body-small">{socRounded}%</Text>
      </Inline>
    </Box>
  );
};
