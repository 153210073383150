import React from "react";
import { Alert, Stack, Strong, Text } from "@origin-digital/ods-core";
import { LearnMoreLink } from "../../LearnMoreLink";
import { WontMeetTargetLearnMoreDialog } from "./WontMeetTargetLearnMoreDialog";

interface ILearnMoreDialogProps {
  open: boolean;
  handleClose: () => void;
}

const LearnMoreDialog = ({ open, handleClose }: ILearnMoreDialogProps) => (
  <WontMeetTargetLearnMoreDialog open={open} handleClose={handleClose}>
    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        What can I do to ensure my charge limit is reached?
      </Text>
      <Text>
        You can{" "}
        <Strong>adjust your charge-ready target time in settings</Strong> to
        allow a greater window of opportunity for us to reach your charge limit.
      </Text>
    </Stack>
    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        Remember
      </Text>
      <Text>
        Keeping your car plugged in whenever you are at home gives us the best
        chance of meeting your charge-ready time.
      </Text>
    </Stack>

    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        Should I use Instant Charge?
      </Text>
      <Text>
        Your car is being charged at the maximum possible speed and using
        Instant Charge will not make it charge any faster.
      </Text>
    </Stack>
  </WontMeetTargetLearnMoreDialog>
);

export const WontMeetTargetInsufficientTime = () => {
  const [showLearnMore, setShowLearnMore] = React.useState(false);
  return (
    <>
      <LearnMoreDialog
        open={showLearnMore}
        handleClose={() => setShowLearnMore(false)}
      />
      <Alert tone="caution">
        <Text weight="medium" variant="body-small">
          EV Power Up rate is active for this charge session.
        </Text>
        <Text variant="body-small">
          However there is not enough time in this session to reach your charge
          limit by the target time you've set.
        </Text>
        <Text variant="body-small">
          <LearnMoreLink
            label="What can I do?"
            onClick={() => setShowLearnMore(true)}
          />
        </Text>
      </Alert>
    </>
  );
};
