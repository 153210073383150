import React from "react";
import { Alert, Stack, Strong, Text } from "@origin-digital/ods-core";
import { LearnMoreLink } from "../../LearnMoreLink";
import { WontMeetTargetLearnMoreDialog } from "./WontMeetTargetLearnMoreDialog";

interface ILearnMoreDialogProps {
  open: boolean;
  handleClose: () => void;
}

const LearnMoreDialog = ({ open, handleClose }: ILearnMoreDialogProps) => (
  <WontMeetTargetLearnMoreDialog open={open} handleClose={handleClose}>
    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        Why can't we meet your target?
      </Text>
      <Text>
        Origin EV Power Up avoids charging your EV during times of peak demand
        to ensure you have access to our low EV-exclusive energy rate.
      </Text>
    </Stack>
    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        What are the peak demand periods*?
      </Text>
      <Text>
        Typically, the window between 5pm-9pm is when energy demand is at its
        highest, however other events, including weather patterns, can influence
        when a peak period comes into effect.
      </Text>
    </Stack>

    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        What can I do to ensure my charge limit is reached?
      </Text>
      <ul>
        <li>
          <Text>
            You can{" "}
            <Strong>adjust your charge-ready target time in settings</Strong> to
            allow a greater window of opportunity for us to reach your charge
            limit.
          </Text>
        </li>
        <li>
          <Text>
            Alternatively, if you need your EV charged urgently, you can
            activate <Strong>Instant Charge</Strong> to override your schedule
            for this charge session.
          </Text>
        </li>
      </ul>
    </Stack>
    <Stack space="xxsmall">
      <Text weight="medium" tone="neutralDark">
        Remember
      </Text>
      <Text>
        Keeping your car plugged in whenever you are at home gives Origin the
        best chance of meeting your charge-ready time.
      </Text>
      <Text>*Also referred to as restricted times</Text>
    </Stack>
  </WontMeetTargetLearnMoreDialog>
);

export const WontMeetTargetNoChargeStatic = () => {
  const [showLearnMore, setShowLearnMore] = React.useState(false);
  return (
    <>
      <LearnMoreDialog
        open={showLearnMore}
        handleClose={() => setShowLearnMore(false)}
      />
      <Alert tone="caution">
        <Text weight="medium" variant="body-small">
          EV Power Up rate is active for this charge session.
        </Text>
        <Text variant="body-small">
          We can't meet your charge limit by the target time you've set as this
          charging session overlaps a peak demand period.{" "}
          <LearnMoreLink
            label="What can I do?"
            onClick={() => setShowLearnMore(true)}
          />
        </Text>
      </Alert>
    </>
  );
};
