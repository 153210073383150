import React from "react";
import { SvgIcon } from "@origin-digital/ods-icons";

const Battery0 = () => (
  <SvgIcon data-id="battery-0" size="xsmall" color="neutralLight">
    <path d="M17,5v16c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1V5c0-0.55,0.45-1,1-1h2V3c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v1h2 C16.55,4,17,4.45,17,5z M15,6H9v14h6V6z" />
  </SvgIcon>
);

const Battery20 = () => (
  <SvgIcon data-id="battery-20" size="xsmall" color="neutralLight">
    <path d="M17,5v16c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1V5c0-0.55,0.45-1,1-1h2V2h4v2h2C16.55,4,17,4.45,17,5z M15,6H9v12h6V6z" />
  </SvgIcon>
);

const Battery40 = () => (
  <SvgIcon data-id="battery-40" size="xsmall" color="neutralLight">
    <path d="M17,5v16c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1V5c0-0.55,0.45-1,1-1h2V2h4v2h2C16.55,4,17,4.45,17,5z M15,6H9v10h6V6z" />
  </SvgIcon>
);

const Battery60 = () => (
  <SvgIcon data-id="battery-60" size="xsmall" color="neutralLight">
    <path d="M17,5v16c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1V5c0-0.55,0.45-1,1-1h2V3c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v1h2 C16.55,4,17,4.45,17,5z M15,6H9v6h6V6z" />
  </SvgIcon>
);

const Battery80 = () => (
  <SvgIcon data-id="battery-80" size="xsmall" color="neutralLight">
    <path d="M17,5v16c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1V5c0-0.55,0.45-1,1-1h2V3c0-0.55,0.45-1,1-1h2c0.55,0,1,0.45,1,1v1h2 C16.55,4,17,4.45,17,5z M15,6H9v4h6V6z" />
  </SvgIcon>
);

const Battery99 = () => (
  <SvgIcon data-id="battery-99" size="xsmall" color="neutralLight">
    <path d="M17,5v16c0,0.55-0.45,1-1,1H8c-0.55,0-1-0.45-1-1V5c0-0.55,0.45-1,1-1h2V2h4v2h2C16.55,4,17,4.45,17,5z M15,6H9v2h6V6z" />
  </SvgIcon>
);

const Battery100 = () => (
  <SvgIcon data-id="battery-100" size="xsmall" color="neutralLight">
    <path d="M15.67 4H14V3c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v1H8.33C7.6 4 7 4.6 7 5.33v15.33C7 21.4 7.6 22 8.34 22h7.32c.74 0 1.34-.6 1.34-1.33V5.33C17 4.6 16.4 4 15.67 4z" />
  </SvgIcon>
);

interface IBatteryChargingIconProps {
  soc: number;
}

export const BatteryChargingIcon = ({ soc }: IBatteryChargingIconProps) => {
  if (soc === 0) {
    return <Battery0 />;
  } else if (soc >= 1 && soc <= 20) {
    return <Battery20 />;
  } else if (soc >= 21 && soc <= 40) {
    return <Battery40 />;
  } else if (soc >= 41 && soc <= 60) {
    return <Battery60 />;
  } else if (soc >= 61 && soc <= 80) {
    return <Battery80 />;
  } else if (soc >= 81 && soc <= 99) {
    return <Battery99 />;
  } else {
    return <Battery100 />;
  }
};
