export interface IConfig {
  widgetName: string;
}

export const config: IConfig = {
  widgetName: "community-battery-widget",
};

export const getEnv = () => (window as any)?.oetal?.env?.name;

export const apiBasePath = "/community-battery";
