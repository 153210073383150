import React from "react";
import { Column, Columns, Heading, Inline } from "@origin-digital/ods-core";
import { CarIcon } from "../../icons/CarIcon";

import { OrangePill } from "../Pill/Pill";

export const BasicHeader = () => {
  return (
    <Columns data-id="ev-icharge-widget-header" alignY="center" space="xsmall">
      <Column width="content">
        <CarIcon />
      </Column>
      <Column>
        <Inline space="xsmall" alignY="center">
          <Heading variant="h4" data-id="ev-icharge-heading">
            EV Power Up
          </Heading>
          <OrangePill
            weight="medium"
            variant="overline-text"
            tone="neutralDark"
          >
            BETA
          </OrangePill>
        </Inline>
      </Column>
    </Columns>
  );
};
