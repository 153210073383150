import { LPGWidget, LPGWidgetProps } from "@origin-digital/lpg-widget";
import { UserAccounts_viewer_digital } from "src/graphql-types/rx-gateway/UserAccounts";
import {
  DigitalAccountStatus,
  DigitalAccountType,
} from "../../../graphql-types/rx-gateway/globalTypes";
import { WidgetDefinition } from "../../WidgetDefinition";

export const LPG_WIDGET_ACTIVE_STATUS = [
  DigitalAccountStatus.ACTIVE,
  DigitalAccountStatus.SETUP,
];
export const LPG_WIDGET_CLOSED_STATUS = [DigitalAccountStatus.CLOSED];

function getLPGAccountsInStatus(
  statuses: DigitalAccountStatus[],
  data: UserAccounts_viewer_digital
) {
  return data.accounts.filter(
    (account) =>
      account?.type === DigitalAccountType.LPG &&
      statuses.includes(account.status)
  );
}

function baseLPGWidgetDefinition(
  statuses: DigitalAccountStatus[]
): WidgetDefinition<LPGWidgetProps> {
  return {
    widgetComponent: LPGWidget,
    name: "LPGWidget",
    shouldLoad: (data) => getLPGAccountsInStatus(statuses, data).length > 0,
    propsBuilder: (data) => ({
      customerNumber: getLPGAccountsInStatus(statuses, data)?.[0]?.accountId,
    }),
  };
}

export const lpgWidgetDefinition: WidgetDefinition<LPGWidgetProps> =
  baseLPGWidgetDefinition(LPG_WIDGET_ACTIVE_STATUS);
export const lpgClosedWidgetDefinition: WidgetDefinition<LPGWidgetProps> =
  baseLPGWidgetDefinition(LPG_WIDGET_CLOSED_STATUS);
