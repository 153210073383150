import React from "react";
import {
  Column,
  Columns,
  Heading,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import { Header } from "../Header/Header";
import { SolarErrorIcon } from "../../../../batteryMaximiserWidgetV2/src/components/Icons/SolarErrorIcon";

export const CommunityBatteryWidgetError = () => (
  <Stack space="medium" dividers data-id="community-battery-widget-state-error">
    <Header />
    <Columns space="medium">
      <Column>
        <Stack space="small" alignX="center">
          <SolarErrorIcon />
          <Heading variant="h4">Something isn't working</Heading>
          <Text align="center">
            We're currently unable to show you what's happening with the
            Community Battery. Please try again later.
          </Text>
        </Stack>
      </Column>
    </Columns>
  </Stack>
);
