import React from "react";

export interface IFlashIconProps {
  enabled?: boolean;
}

const colors = {
  grayColor: "#9B9B9B",
  greenColor: "#008906",
};

export const FlashIcon = ({ enabled = true }: IFlashIconProps) => {
  const fillColor = enabled ? colors.greenColor : colors.grayColor;
  return (
    <svg
      viewBox="0 0 10 20"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
    >
      <path
        transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
        d="M 4.048 18.036 L 3.082 18.036 L 4.048 11.158 L 0.666 11.158 C 0.106 11.158 0.115 10.843 0.299 10.509 C 0.483 10.175 0.347 10.431 0.367 10.391 C 1.613 8.151 3.487 4.811 5.98 0.35 L 6.946 0.35 L 5.98 7.228 L 9.362 7.228 C 9.835 7.228 9.903 7.552 9.816 7.729 L 9.748 7.876 C 5.941 14.646 4.048 18.036 4.048 18.036 Z"
      />
    </svg>
  );
};
