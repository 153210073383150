import React from "react";
import { EVChargerWidget } from "@origin-digital/ev-charger-widget";
import { WidgetProvider } from "@origin-digital/widget-provider";
import { CardStack } from "../../../components/CardStack";
import { useMultiWidgetAnalytics } from "../../hooks/useMultiWidgetAnalytics";

interface IEVChargerWidgetsProps {
  chargerIds: string[];
}

export const EVChargerWidgets = ({ chargerIds }: IEVChargerWidgetsProps) => {
  const onStatusChange = useMultiWidgetAnalytics(
    chargerIds,
    (chargerId: string) => `card-ev-${chargerId}`
  );

  return (
    <CardStack>
      {chargerIds.map((chargerId) => (
        <WidgetProvider key={chargerId} onStatusChange={onStatusChange}>
          <EVChargerWidget chargerId={chargerId} />
        </WidgetProvider>
      ))}
    </CardStack>
  );
};
