import { Text } from "@origin-digital/ods-core";
import React from "react";
import { ControlPanel } from "./ControlPanel";

type ActiveControlPanelProps = {
  handleDialogOpen(): void;
};

export const ActiveControlPanel = (props: ActiveControlPanelProps) => {
  const { handleDialogOpen } = props;

  return (
    <ControlPanel onClick={handleDialogOpen}>
      <Text variant="body-small" tone="neutralDark" data-id="snooze-label">
        Snooze Battery Maximiser
      </Text>
    </ControlPanel>
  );
};
