// @ts-nocheck
import { dapiFetchCache, dapiFetch, IDapiRequest } from '@origin-digital/daxi';

import {
  IEligibilityResponse,
  IErrorResponse,
  IProfileResponse,
  ISignUpRequest,
  ISignUpResponse,
  ISummaryResponse,
} from '../models';

/*
 * This code has been generated from an OpenAPI spec.
 */

export interface IGetEligibilityRequest {
}

/**
 * @summary Provide eligibility status and related customer data
 */
export const getEligibility = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IEligibilityResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getEligibilityUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IEligibilityResponse>(url + queryString, fetchOptions);
          return response.data as IEligibilityResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IEligibilityResponse>(url + queryString, fetchOptions);
        return response.data as IEligibilityResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getEligibilityUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getEligibility endpoint url that substitutes in given url params.
  */
const getEligibilityUrl = () => {
    return '/eligibility';
};


export interface IGetProfileRequest {
}

/**
 * @summary Provides the customer profile data
 */
export const getProfile = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IProfileResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getProfileUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IProfileResponse>(url + queryString, fetchOptions);
          return response.data as IProfileResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IProfileResponse>(url + queryString, fetchOptions);
        return response.data as IProfileResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getProfileUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getProfile endpoint url that substitutes in given url params.
  */
const getProfileUrl = () => {
    return '/profile';
};


export interface IGetSummaryRequest {
}

/**
 * @summary Provide a summary of the spike account for the customer
 */
export const getSummary = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<ISummaryResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getSummaryUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<ISummaryResponse>(url + queryString, fetchOptions);
          return response.data as ISummaryResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<ISummaryResponse>(url + queryString, fetchOptions);
        return response.data as ISummaryResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getSummaryUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getSummary endpoint url that substitutes in given url params.
  */
const getSummaryUrl = () => {
    return '/summary';
};


export interface ISignupUserRequest extends ISignUpRequest {
}

/**
 * @summary Allows customers to sign up to spike
 */
export const signupUser = async (baseUrl: string, data: ISignupUserRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<ISignUpResponse> => {
    try {
        const method = 'POST';
        const url = baseUrl + signupUserUrl();
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<ISignUpResponse>(url + queryString, fetchOptions);
          return response.data as ISignUpResponse;
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<ISignUpResponse>(url + queryString, fetchOptions);
        return response.data as ISignUpResponse;
    } catch (error) {
        error.newRelic = `'POST: ${ signupUserUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the signupUser endpoint url that substitutes in given url params.
  */
const signupUserUrl = () => {
    return '/signup';
};

