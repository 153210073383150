import { Text } from "@origin-digital/ods-core";
import React from "react";
import { ControlPanel } from "./ControlPanel";

type InactiveControlPanelProps = {
  handleDialogOpen(): void;
  snoozeEnd: string;
};

export const InactiveControlPanel = (props: InactiveControlPanelProps) => {
  const { handleDialogOpen, snoozeEnd } = props;

  return (
    <ControlPanel onClick={handleDialogOpen}>
      <Text variant="body-small" tone="neutralDark" data-id="unsnooze-label">
        Turn on Battery Maximiser
      </Text>
      <Text variant="body-small">Paused until {snoozeEnd}</Text>
    </ControlPanel>
  );
};
