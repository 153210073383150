import React, { ReactNode } from "react";
import { TextLinkStyles } from "@origin-digital/ods-core";

interface ITextLinkButtonProps {
  onClick: () => void;
  children: ReactNode;
}

export const TextLinkButton = ({ onClick, children }: ITextLinkButtonProps) => (
  <TextLinkStyles as="button" onClick={onClick}>
    {children}
  </TextLinkStyles>
);
