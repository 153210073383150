import { FeatureType } from "../../../graphql-types/rx-gateway/globalTypes";
import { UserAccounts_viewer_digital } from "../../../graphql-types/rx-gateway/UserAccounts";
import { getFeature } from "../../../util/featuresHelper";

export function getEvIChargeWidgetProps(data: UserAccounts_viewer_digital): {
  userId: string;
} {
  const { featureId } = getFeature(data, FeatureType.EV_ICHARGE) || {};

  if (featureId) {
    return {
      userId: featureId,
    };
  }

  throw new Error(`Failed to get ev iCharge feature from user data`);
}
