import React from "react";

import { configFetch, configFetchCache } from "@origin-digital/daxi";
import { BaseWidget } from "@origin-digital/widget-helpers";

import {
  IBatteryMaximiserWidgetProps,
  BatteryMaximiserWidget as Widget,
} from "./components/BatterMaximiserWidget/BatteryMaximiserWidget";
import { config } from "./config";

if (__DEV__) {
  configFetch({ disableAuthCheck: __DEV__ });
  configFetchCache({ disableCache: __DEV__ });
}

export const BatteryMaximiserWidget = (props: IBatteryMaximiserWidgetProps) => (
  <BaseWidget name={config.widgetName}>
    <Widget {...props} />
  </BaseWidget>
);
export { Header } from "./components/Header/Header";
export { FlowStatus } from "./components/FlowStatus/FlowStatus";
export { CreditCostLast24Hours } from "./components/CreditCostLast24Hours/CreditCostLast24Hours";
export { BatteryChargeStatus } from "./components/BatteryChargeStatus/BatteryChargeStatus";
