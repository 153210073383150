import React from "react";
import { Box, Button } from "@origin-digital/ods-core";
import { isNative } from "@origin-digital/platform-helpers";
import { getOhmPath } from "../../util/url";
import { config, isProd } from "../../config";
import { useAccessToken } from "../../hooks/useAccessToken";

export const Footer = () => {
  const { accessToken } = useAccessToken();
  const path = getOhmPath(isProd(), isNative(), config, accessToken);
  return (
    <Box textAlign="right">
      <Button
        data-id="link-to-spike"
        variant="text"
        noTextPadding
        size="small"
        href={path}
      >
        Go to Spike
      </Button>
    </Box>
  );
};
