// @ts-nocheck
import { dapiFetchCache, dapiFetch, IDapiRequest } from '@origin-digital/daxi';

import {
  IDashboardResponse,
} from '../models';

/*
 * This code has been generated from an OpenAPI spec.
 */

export interface IGetDashboardDataRequest {
  accountId: string;
}

/**
 * @summary Responds with a sitrep containing the lifetime savings, as well as the earnings and solar export details for the most recent day.
 */
export const getDashboardData = async (baseUrl: string, data: IGetDashboardDataRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IDashboardResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getDashboardDataUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IDashboardResponse>(url + queryString, fetchOptions);
          return response.data as IDashboardResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IDashboardResponse>(url + queryString, fetchOptions);
        return response.data as IDashboardResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getDashboardDataUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getDashboardData endpoint url that substitutes in given url params.
  */
const getDashboardDataUrl = (data: IGetDashboardDataRequest) => {
    return '/api/v1/dashboard/{accountId}'.replace('{' + 'accountId' + '}', encodeURIComponent(data.accountId));
};

