// @ts-nocheck
import { dapiFetchCache, dapiFetch, IDapiRequest } from '@origin-digital/daxi';

import {
  IChargeLocationBody,
  ILinkingSessionBody,
  ILinkingSessionResponse,
  IUserAccount,
} from '../models';

/*
 * This code has been generated from an OpenAPI spec.
 */

export interface IAdminDeleteUserRequest {
  userId: string;
}

/**
 * @summary Deletes a user from both the user db and VPP. This is a highly destructive process on the VPP side.
 */
export const adminDeleteUser = async (baseUrl: string, data: IAdminDeleteUserRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'DELETE';
        const url = baseUrl + adminDeleteUserUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'DELETE: ${ adminDeleteUserUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the adminDeleteUser endpoint url that substitutes in given url params.
  */
const adminDeleteUserUrl = (data: IAdminDeleteUserRequest) => {
    return '/api/v2/admin/user/{userId}'.replace('{' + 'userId' + '}', encodeURIComponent(data.userId));
};


export interface IAdminGetLinkingSessionForUserRequest extends ILinkingSessionBody {
  userId: string;
}

/**
 * @summary Creates a linking session for the passed user id to link their vehicle account to enode
 */
export const adminGetLinkingSessionForUser = async (baseUrl: string, data: IAdminGetLinkingSessionForUserRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<ILinkingSessionResponse> => {
    try {
        const method = 'POST';
        const url = baseUrl + adminGetLinkingSessionForUserUrl(data);
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<ILinkingSessionResponse>(url + queryString, fetchOptions);
          return response.data as ILinkingSessionResponse;
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<ILinkingSessionResponse>(url + queryString, fetchOptions);
        return response.data as ILinkingSessionResponse;
    } catch (error) {
        error.newRelic = `'POST: ${ adminGetLinkingSessionForUserUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the adminGetLinkingSessionForUser endpoint url that substitutes in given url params.
  */
const adminGetLinkingSessionForUserUrl = (data: IAdminGetLinkingSessionForUserRequest) => {
    return '/api/v2/admin/user/{userId}/link'.replace('{' + 'userId' + '}', encodeURIComponent(data.userId));
};


export interface IAdminUpdateUserChargeLocationRequest extends IChargeLocationBody {
  userId: string;
  chargeLocationId: string;
}

/**
 * @summary Updates the charge location details for the user. This is primarily used to update the lat and long of their location.
 */
export const adminUpdateUserChargeLocation = async (baseUrl: string, data: IAdminUpdateUserChargeLocationRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'PATCH';
        const url = baseUrl + adminUpdateUserChargeLocationUrl(data);
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'PATCH: ${ adminUpdateUserChargeLocationUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the adminUpdateUserChargeLocation endpoint url that substitutes in given url params.
  */
const adminUpdateUserChargeLocationUrl = (data: IAdminUpdateUserChargeLocationRequest) => {
    return '/api/v2/admin/user/{userId}/charge-location/{chargeLocationId}'.replace('{' + 'userId' + '}', encodeURIComponent(data.userId)).replace('{' + 'chargeLocationId' + '}', encodeURIComponent(data.chargeLocationId));
};


export interface IOnboardUserRequest extends IUserAccount {
}

/**
 * @summary Onboard a new user
 */
export const onboardUser = async (baseUrl: string, data: IOnboardUserRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'POST';
        const url = baseUrl + onboardUserUrl();
        const queryString = '';
        const body: any = data;

        if (ttlInMin > 0) {
          const fetchOptions = { method, body, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, body, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'POST: ${ onboardUserUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the onboardUser endpoint url that substitutes in given url params.
  */
const onboardUserUrl = () => {
    return '/api/v2/onboarding';
};

