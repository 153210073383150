import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

const localStorageExpiresAfter = 60 * 60 * 1000;
const localStorageKey = "ev-icharge.relinking-complete";

interface IRelinkingStorage {
  timestamp: number;
}

export const useRelinkingState = () => {
  const [storageValue, , removeStorageValue] = useLocalStorage<
    IRelinkingStorage | undefined
  >(localStorageKey, undefined);

  useEffect(() => {
    if (
      storageValue &&
      storageValue.timestamp < Date.now() - localStorageExpiresAfter
    ) {
      removeStorageValue();
    }
  }, [storageValue]);

  return {
    relinkingCompleted: storageValue !== undefined,
    clearRelinkingState: removeStorageValue,
  };
};
