import { UserAccounts_viewer_digital } from "../../../graphql-types/rx-gateway/UserAccounts";
import { FeatureType } from "../../../graphql-types/rx-gateway/globalTypes";
import { getFeature } from "../../../util/featuresHelper";
import { getNmiFromFeatureId } from "./src/util/batteryNmiHelper";

export function getBatteryMaximiserWidgetProps(
  data: UserAccounts_viewer_digital
): {
  nmi: string;
} {
  const { featureId } = getFeature(data, FeatureType.BATTERY_MAXIMISER) || {};

  if (featureId) {
    return {
      nmi: getNmiFromFeatureId(featureId),
    };
  }

  throw new Error(`Failed to get battery feature from user accounts data`);
}
