import React, { useContext } from "react";
import { TrackingProvider } from "@origin-digital/ods-core";
import { ICardAA } from "@origin-digital/reporting-client";
import { BaseWidget } from "@origin-digital/widget-helpers";
import {
  UpdateWidgetStatus,
  WidgetProvider,
} from "@origin-digital/widget-provider";
import { useVehicleDetails } from "../components/VehicleCards/useVehicleDetails";
import { IApiError, IIChargeSchedule, IVehicle } from "../contract/bff";
import {
  getAnalyticsData,
  trackingProviderCaptureClickCard,
} from "../analytics";
import { config } from "../config";

export interface IVehicleLiveDataContext {
  loading?: boolean;
  analyticsData: ICardAA;
  data?: IVehicle;
  error?: IApiError;
}

export const VehicleLiveDataContext =
  React.createContext<IVehicleLiveDataContext>({
    loading: true,
    analyticsData: {} as ICardAA,
  });

export const useVehicleLiveDataContext = () =>
  useContext(VehicleLiveDataContext);

interface IValidVehicle extends IVehicle {
  activeSchedule: IIChargeSchedule;
}

export const useVehicleLiveData = (): IValidVehicle => {
  const { data } = useContext(VehicleLiveDataContext);

  if (!data) {
    throw new Error("No vehicle data available");
  } else if (!data.activeSchedule) {
    throw new Error("No active schedule available");
  }

  return { ...data, activeSchedule: data.activeSchedule };
};

interface IVehicleLiveDataProviderProps {
  vehicleId: string;
  onStatusChange: UpdateWidgetStatus;
  children: React.ReactNode;
}

export const VehicleLiveDataProvider = ({
  vehicleId,
  onStatusChange,
  children,
}: IVehicleLiveDataProviderProps) => {
  const { loading, data, error } = useVehicleDetails(vehicleId);
  const analyticsData = getAnalyticsData(vehicleId);

  return (
    <TrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(analyticsData)}
    >
      <WidgetProvider onStatusChange={onStatusChange}>
        <BaseWidget name={config.widgetName}>
          <VehicleLiveDataContext.Provider
            value={{ loading, analyticsData, data, error }}
          >
            {children}
          </VehicleLiveDataContext.Provider>
        </BaseWidget>
      </WidgetProvider>
    </TrackingProvider>
  );
};
