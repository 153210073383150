import React from "react";
import { Card, Column, Columns, Stack, Text } from "@origin-digital/ods-core";
import { formatCurrency } from "@origin-digital/formatters";
import { IconPiggybank } from "@origin-digital/ods-icons";

const MonetarySummary = ({
  value,
  description,
  "data-id": dataId,
}: {
  description: string;
  value: number;
  "data-id"?: string;
}) => (
  <Text variant="body-small" tone="neutralDark" data-id={dataId}>
    {description}
    <Text weight="medium" inline>
      {formatCurrency(value)}
    </Text>
  </Text>
);

export const SavingsSummary = ({
  lifetimeSavings,
  dailyEarnings,
}: {
  lifetimeSavings: number;
  dailyEarnings: number;
}) => (
  <Card
    paddingYSpecific="medium"
    paddingXSpecific="medium"
    backgroundColor="positive"
    data-id="community-battery-savings-summary"
  >
    <Columns space="medium" alignY="center">
      <Column width="content">
        <IconPiggybank color="neutral" size="smallmedium" />
      </Column>
      <Column>
        <Stack space="xxsmall">
          <MonetarySummary
            description="Latest daily savings: "
            value={dailyEarnings}
            data-id="latest-daily-savings"
          />
          <MonetarySummary
            description="Lifetime savings: "
            value={lifetimeSavings}
            data-id="lifetime-savings"
          />
        </Stack>
      </Column>
    </Columns>
  </Card>
);
