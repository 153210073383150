import React from "react";

export const SolarErrorIcon = () => (
  <svg width={102} height={104} version="1.1">
    <defs>
      <path
        d="M.099 51.301c0 28.101 22.781 50.882 50.883 50.882 28.101 0 50.882-22.781 50.882-50.882 0-28.102-22.781-50.883-50.882-50.883C22.88.418.099 23.199.099 51.301z"
        id="prefix__a"
      />
      <path
        d="M.099 51.301c0 28.101 22.781 50.882 50.882 50.882s50.883-22.781 50.883-50.882c0-28.102-22.782-50.883-50.883-50.883C22.88.418.099 23.199.099 51.301z"
        id="prefix__c"
      />
      <path
        d="M50.683.419C22.718.579.099 23.298.099 51.301c0 28.101 22.781 50.882 50.882 50.882 28.002 0 50.722-22.62 50.882-50.583v-.597C101.703 23.137 79.144.579 51.28.419h-.597z"
        id="prefix__e"
      />
      <path id="prefix__g" d="M3.943.536H.663V3.83H7.222V.536z" />
      <path
        id="prefix__i"
        d="M.193 2.726l2.32 2.32 2.32 2.32 2.334-2.32-2.334-2.32L2.513.407z"
      />
      <path id="prefix__k" d="M.338.862v6.573h3.279V.862z" />
      <path id="prefix__m" d="M.21.093h4.741v19.848H.21z" />
      <path id="prefix__o" d="M.543.202h36.044v4.739H.543z" />
      <path id="prefix__q" d="M.338.666h64.47v48.789H.338z" />
      <path id="prefix__s" d="M.715.115h13.161v13.16H.715z" />
      <path id="prefix__u" d="M.715.308h13.161v13.161H.715z" />
      <path id="prefix__w" d="M.715.932h13.161v13.161H.715z" />
      <path id="prefix__y" d="M.182.115h13.16v13.16H.182z" />
      <path id="prefix__A" d="M.182.308h13.16v13.161H.182z" />
      <path id="prefix__C" d="M.182.932h13.16v13.161H.182z" />
      <path id="prefix__E" d="M.632.115h13.161v13.16H.632z" />
      <path id="prefix__G" d="M.632.308h13.161v13.161H.632z" />
      <path id="prefix__I" d="M.632.932h13.161v13.161H.632z" />
      <path id="prefix__K" d="M.084.115h13.161v13.16H.084z" />
      <path id="prefix__M" d="M.084.308h13.161v13.161H.084z" />
      <path id="prefix__O" d="M.084.932h13.161v13.161H.084z" />
      <path
        d="M9.479 1.826C5.171 4.199 4.463 7.779 4.463 7.779-.858 13.643-.512 20.67 2.184 25.565l3.339 6.065s-.78.994-1.631 2.227c-.851 1.235-.68 1.778-.194 2.66l.215.391 19.093-10.512 19.093-10.513-.214-.391c-.486-.881-.855-1.316-2.352-1.257-1.499.06-2.757.186-2.757.186l-3.338-6.064C30.743 3.461 24.991-.589 17.191.77c0 0-.941-.363-2.46-.363-1.37 0-3.21.295-5.252 1.419z"
        id="prefix__Q"
      />
      <path
        d="M13.743 5.284C7.965 8.465 3.236 12.854.634 17.86L31.379.932a23.292 23.292 0 00-1.95-.081c-5.092 0-10.571 1.616-15.686 4.433z"
        id="prefix__S"
      />
      <path
        d="M7.896.818a500.933 500.933 0 004.46 8.029c.681-.32 1.367-.618 2.056-.894-.845-3.328-1.042-5.87-.967-7.225l-.254.042s-.941-.363-2.46-.363c-.801 0-1.765.102-2.835.411zM.463 7.779l-.172.191c1.183.663 3.226 2.188 5.588 4.681a39.642 39.642 0 011.829-1.244 509.8 509.8 0 01-4.45-8.01C.91 5.516.463 7.779.463 7.779z"
        id="prefix__U"
      />
      <path
        d="M2.761 1.485A4.653 4.653 0 00.93 7.807a4.641 4.641 0 003.475 2.369 4.615 4.615 0 011.976-2.118 4.622 4.622 0 012.847-.537A4.652 4.652 0 005.003.906a4.64 4.64 0 00-2.242.579zm.165 5.223A2.375 2.375 0 013.86 3.48a2.377 2.377 0 012.293 4.164 2.377 2.377 0 01-3.227-.936z"
        id="prefix__W"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M50.981 103.023c28.101 0 50.882-22.781 50.882-50.882S79.083 1.259 50.981 1.259C22.881 1.259.1 24.039.1 52.14c0 28.1 22.781 50.882 50.882 50.882"
        fill="#F7F7F7"
      />
      <g transform="translate(0 .84)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill="#F7F7F7"
          mask="url(#prefix__b)"
          d="M-.019 103.102h102v-103h-102z"
        />
      </g>
      <g transform="translate(0 .84)">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__c" />
        </mask>
        <path
          fill="#F7F7F7"
          mask="url(#prefix__d)"
          d="M.099 102.183h101.764V.419H.099z"
        />
      </g>
      <g transform="translate(0 .84)">
        <mask id="prefix__f" fill="#fff">
          <use xlinkHref="#prefix__e" />
        </mask>
        <path
          fill="#F7F7F7"
          mask="url(#prefix__f)"
          d="M-4.901 107.183h111.764V-4.581H-4.901z"
        />
      </g>
      <g transform="translate(36 21.84)">
        <mask id="prefix__h" fill="#fff">
          <use xlinkHref="#prefix__g" />
        </mask>
        <path
          fill="#FEC524"
          mask="url(#prefix__h)"
          d="M-5.304 9.797h18.492V-5.43H-5.304z"
        />
      </g>
      <g transform="translate(31 32.84)">
        <mask id="prefix__j" fill="#fff">
          <use xlinkHref="#prefix__i" />
        </mask>
        <path
          fill="#FEC524"
          mask="url(#prefix__j)"
          d="M-5.774 13.332h18.908V-5.561H-5.774z"
        />
      </g>
      <g transform="translate(20 37.84)">
        <mask id="prefix__l" fill="#fff">
          <use xlinkHref="#prefix__k" />
        </mask>
        <path
          fill="#FEC524"
          mask="url(#prefix__l)"
          d="M-5.629 13.402H9.584V-5.105H-5.629z"
        />
      </g>
      <path fill="#A6A8AB" d="M50.21 88.781h4.74V68.933h-4.74z" />
      <g transform="translate(50 68.84)">
        <mask id="prefix__n" fill="#fff">
          <use xlinkHref="#prefix__m" />
        </mask>
        <path
          fill="#A6A8AB"
          mask="url(#prefix__n)"
          d="M-5.757 25.908h16.674V-5.874H-5.757z"
        />
      </g>
      <path fill="#A6A8AB" d="M34.544 88.781h36.045v-4.74H34.544z" />
      <g transform="translate(34 83.84)">
        <mask id="prefix__p" fill="#fff">
          <use xlinkHref="#prefix__o" />
        </mask>
        <path
          fill="#A6A8AB"
          mask="url(#prefix__p)"
          d="M-5.423 10.908h47.978V-5.766H-5.423z"
        />
      </g>
      <path fill="#A6A8AB" d="M20.338 71.295h64.47v-48.79h-64.47z" />
      <g transform="translate(20 21.84)">
        <mask id="prefix__r" fill="#fff">
          <use xlinkHref="#prefix__q" />
        </mask>
        <path
          fill="#A6A8AB"
          mask="url(#prefix__r)"
          d="M-5.629 55.422h76.404V-5.301H-5.629z"
        />
      </g>
      <g transform="translate(22 24.84)">
        <mask id="prefix__t" fill="#fff">
          <use xlinkHref="#prefix__s" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__t)"
          d="M-5.252 19.242h25.094V-5.852H-5.252z"
        />
      </g>
      <g transform="translate(22 39.84)">
        <mask id="prefix__v" fill="#fff">
          <use xlinkHref="#prefix__u" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__v)"
          d="M-5.252 19.436h25.094V-5.658H-5.252z"
        />
      </g>
      <g transform="translate(22 54.84)">
        <mask id="prefix__x" fill="#fff">
          <use xlinkHref="#prefix__w" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__x)"
          d="M-5.252 20.059h25.094V-5.035H-5.252z"
        />
      </g>
      <g transform="translate(38 24.84)">
        <mask id="prefix__z" fill="#fff">
          <use xlinkHref="#prefix__y" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__z)"
          d="M-5.786 19.242h25.094V-5.852H-5.786z"
        />
      </g>
      <g transform="translate(38 39.84)">
        <mask id="prefix__B" fill="#fff">
          <use xlinkHref="#prefix__A" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__B)"
          d="M-5.786 19.436h25.094V-5.658H-5.786z"
        />
      </g>
      <g transform="translate(38 54.84)">
        <mask id="prefix__D" fill="#fff">
          <use xlinkHref="#prefix__C" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__D)"
          d="M-5.786 20.059h25.094V-5.035H-5.786z"
        />
      </g>
      <g transform="translate(53 24.84)">
        <mask id="prefix__F" fill="#fff">
          <use xlinkHref="#prefix__E" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__F)"
          d="M-5.334 19.242H19.76V-5.852H-5.334z"
        />
      </g>
      <g transform="translate(53 39.84)">
        <mask id="prefix__H" fill="#fff">
          <use xlinkHref="#prefix__G" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__H)"
          d="M-5.334 19.436H19.76V-5.658H-5.334z"
        />
      </g>
      <g transform="translate(53 54.84)">
        <mask id="prefix__J" fill="#fff">
          <use xlinkHref="#prefix__I" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__J)"
          d="M-5.334 20.059H19.76V-5.035H-5.334z"
        />
      </g>
      <g transform="translate(69 24.84)">
        <mask id="prefix__L" fill="#fff">
          <use xlinkHref="#prefix__K" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__L)"
          d="M-5.882 19.242h25.094V-5.852H-5.882z"
        />
      </g>
      <g transform="translate(69 39.84)">
        <mask id="prefix__N" fill="#fff">
          <use xlinkHref="#prefix__M" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__N)"
          d="M-5.882 19.436h25.094V-5.658H-5.882z"
        />
      </g>
      <g transform="translate(69 54.84)">
        <mask id="prefix__P" fill="#fff">
          <use xlinkHref="#prefix__O" />
        </mask>
        <path
          fill="#255DAB"
          mask="url(#prefix__P)"
          d="M-5.882 20.059h25.094V-5.035H-5.882z"
        />
      </g>
      <g transform="translate(7 3.84)">
        <mask id="prefix__R" fill="#fff">
          <use xlinkHref="#prefix__Q" />
        </mask>
        <path
          fill="#FEC414"
          mask="url(#prefix__R)"
          d="M-15.045 31.285l25.65-44.136 40.059 23.28-25.651 44.136z"
        />
      </g>
      <g transform="translate(14 20.84)">
        <mask id="prefix__T" fill="#fff">
          <use xlinkHref="#prefix__S" />
        </mask>
        <path
          fill="#FFCE4F"
          mask="url(#prefix__T)"
          d="M-5.763 19.536L14.04-14.539 37.756-.757 17.952 33.318z"
        />
      </g>
      <g transform="translate(11 3.84)">
        <mask id="prefix__V" fill="#fff">
          <use xlinkHref="#prefix__U" />
        </mask>
        <path
          fill="#FFCE4F"
          mask="url(#prefix__V)"
          d="M-6.903 10.618L4.874-9.647l18.808 10.93L11.905 21.55z"
        />
      </g>
      <g transform="translate(19 13.84)">
        <mask id="prefix__X" fill="#fff">
          <use xlinkHref="#prefix__W" />
        </mask>
        <path
          fill="#ED2F34"
          mask="url(#prefix__X)"
          d="M-7.733 8.935L1.632-7.179l16.097 9.354-9.365 16.116z"
        />
      </g>
    </g>
  </svg>
);
