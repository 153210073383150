import { DigitalServiceType } from "../../../graphql-types/rx-gateway/globalTypes";
import { hasActiveService } from "../../../util/service";
import { WidgetDefinition } from "../../WidgetDefinition";
import { getEvChargerWidgetProps } from "./evChargerWidgetPropsHelper";
import { EVChargerWidgets } from "./EVChargerWidgets";

export const evChargerWidgetDefinition: WidgetDefinition<{
  chargerIds: string[];
}> = {
  widgetComponent: EVChargerWidgets,
  name: "EVChargerWidget",
  propsBuilder: (data) => getEvChargerWidgetProps(data),
  shouldLoad: (data) => hasActiveService(data, DigitalServiceType.EV_CHARGER),
};
