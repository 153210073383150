import { formatAmount } from "@origin-digital/neon-common";

export const formatKwh = (value: number, includeUnit = true) =>
  includeUnit
    ? formatAmount({
        amount: value,
        suffix: " kWh",
        round: 1,
      })
    : formatAmount({ amount: value, round: 1 });
