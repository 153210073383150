import { useEffect } from "react";
import { IDashboard } from "../contract/generated/bff/src";
import { getDashboardBffData } from "../service/dashboardBffDao";
import { useDataFetch } from "../../../../../hooks/useDataFetch";

export interface IBatteryWidgetData {
  homeEnergyState?: IDashboard;
  loading: boolean;
  error?: Error;

  refetch(): void;
}

export const useBatteryWidgetData = (nmi: string): IBatteryWidgetData => {
  const { loading, error, data, dataFetch } = useDataFetch<IDashboard, string>(
    getDashboardBffData
  );

  const refetch = () => dataFetch(nmi);

  useEffect(refetch, [nmi]);

  return { homeEnergyState: data, loading, error, refetch };
};
