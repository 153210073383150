import styled from "styled-components";

export const UnstyledButton = styled.button`
  all: unset;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;
