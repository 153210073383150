import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { analyticsData } from "../components/TrackingProvider/analytics";

export const useUpdateCommbattWidgetStatus = () => {
  const { updateWidgetStatus } = useWidgetProvider();

  const updateStatusToError = (error?: Error) => {
    const trackedError = error || new Error("Missing data");
    updateWidgetStatus(WidgetStatusCode.FAILURE, [
      {
        ...analyticsData,
        error: {
          errorCode: trackedError.name,
          errorMessage: trackedError.message,
        },
      },
    ]);
  };

  const updateStatusToComplete = () => {
    updateWidgetStatus(WidgetStatusCode.COMPLETE, [analyticsData]);
  };

  return { updateStatusToError, updateStatusToComplete };
};
