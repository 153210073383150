import * as React from "react";
import { Alert, Text, TextLink } from "@origin-digital/ods-core";

interface IUpcomingSpikeHourProps {
  upcomingSpike: string | null;
  optInLink: string | null;
  participating: boolean;
}

export const UpcomingSpikeHour = ({
  optInLink,
  participating,
  upcomingSpike,
}: IUpcomingSpikeHourProps) => {
  const showOptInLink = !!optInLink && !participating;

  return upcomingSpike ? (
    <Alert data-id="upcoming-spike">
      <Text>Upcoming SpikeHour:</Text>
      <Text>{upcomingSpike}</Text>
      {showOptInLink && (
        <Text>
          <TextLink href={optInLink!}>Click here to participate</TextLink>
        </Text>
      )}
    </Alert>
  ) : null;
};
