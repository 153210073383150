import { apiBasePath } from "../config";
import { getDashboardData, IDashboard } from "../contract/generated/bff/src";

export type IEnergyKwh = number | undefined | null;
export type IEnergyChargePercentage = number | undefined | null;

export const getDashboardBffData = async (nmi: string): Promise<IDashboard> => {
  try {
    const { data, error } = await getDashboardData(
      apiBasePath,
      { nmi },
      "jwt",
      5
    );

    if (!data || error) {
      throw new Error(
        error?.message || "Failed to get data from Battmax BFF API"
      );
    }
    return data;
  } catch (error) {
    return Promise.reject(
      "[getDashboardBffData] Failed to get data from home energy state"
    );
  }
};
