import React, { useEffect } from "react";
import { useInterval } from "usehooks-ts";
import { logger } from "@origin-digital/node-logger";
import { Card, Divider, Heading, Stack, Text } from "@origin-digital/ods-core";
import { BasicHeader } from "../BasicHeader/BasicHeader";
import { IAccountStatus } from "../../contract/generated/bff/src";
import { useVehicleDetails } from "../VehicleCards/useVehicleDetails";
import { useRelinkingState } from "./useRelinkingState";

const pollingInterval = 10 * 1000;

export const WaitingForUpdatesCard = ({ vehicleId }: { vehicleId: string }) => {
  const { data, refresh } = useVehicleDetails(vehicleId);
  const { relinkingCompleted, clearRelinkingState } = useRelinkingState();

  useInterval(() => refresh(), relinkingCompleted ? pollingInterval : null);

  useEffect(() => {
    if (data && data.accountStatus !== IAccountStatus.REQUIRES_INTERVENTION) {
      logger.info("Intervention resolved, reloading vehicle list", { data });
      clearRelinkingState();
    }
  }, [data]);

  return (
    <Card data-id={`card-ev-icharge-waiting-for-updates-${vehicleId}`}>
      <Stack space="large">
        <Stack space="medium">
          <BasicHeader />
          <Divider />
          <Heading variant="h4">We're almost done...</Heading>
          <Text>
            Thanks for re-linking your account, we're finalising the last step.
            You'll be reconnected to EV Power Up in a few moments.
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};
