import React from "react";
import { SystemStatusEnum } from "../../contract/generated/bff/src";
import solarBatteryAnimation from "./animations/animated_spot-solar-battery.json";
import batteryChargingAnimation from "./animations/animated_spot_battery-charging.json";
import transferRefundAnimation from "./animations/animated_spot_transfer-refund.json";
import plugWallAnimation from "./animations/animated_spot_plug-wall.json";
import solarAnimation from "./animations/animated_spot_solar.json";
import { FlowStatusCard } from "./components/FlowStatusCard";

interface IFlowStatusProps {
  status: SystemStatusEnum;
}

export const FlowStatus = ({ status }: IFlowStatusProps) => {
  switch (status) {
    case SystemStatusEnum.SELF_POWERED:
      return (
        <FlowStatusCard
          label="Your home is self-powered"
          description="Your battery or solar system is powering your home"
          animation={solarBatteryAnimation}
        />
      );
    case SystemStatusEnum.BATTERY_FIT:
      return (
        <FlowStatusCard
          label="Your FIT is at its highest"
          description="Getting you the most out of the peak FiT rate right now. Cha-ching"
          animation={transferRefundAnimation}
        />
      );
    case SystemStatusEnum.GRID_TO_BATTERY:
      return (
        <FlowStatusCard
          label="Your rates are at its lowest"
          description="Topping up your battery before the peak demand starts at 5pm."
          animation={batteryChargingAnimation}
        />
      );
    case SystemStatusEnum.GRID_TO_HOME:
      return (
        <FlowStatusCard
          label="Your rates are low"
          description="Your home's using cheap energy from the grid."
          animation={plugWallAnimation}
        />
      );
    case SystemStatusEnum.SOLAR_FIT:
      return (
        <FlowStatusCard
          label="Your FIT is high"
          description="Your excess solar is being exported to the grid - no export cap."
          animation={solarAnimation}
        />
      );
    case SystemStatusEnum.UNKNOWN:
    default:
      return null;
  }
};
