/**
 * This file will configure various resources for your application.
 * - Logging to New Relic (More configuration required - see below)
 * - Analytics tracking through Adobe Target Events
 *
 * Anything else required before your app is loaded can be included in this
 * file.
 */
import {
  configAnalytics,
  configNewRelic,
} from "@origin-digital/reporting-client";
import { config, appName } from "src/config";

const envAppName = `${appName}-${config.env}`;

configAnalytics({
  appName,
  debugToConsole: config.analyticsDebugToConsole,
});

configNewRelic({
  logLevel: config.logLevelNewRelic, // error level to send to new relic
  consoleLogLevel: config.logLevelConsole,
  sendOnErrors: true,
  appName: envAppName,
  endpoint: "https://log-api.newrelic.com/log/v1",
  apiKey: config.newRelicCollectorKey,
  sourceEnv: config.env,
});
