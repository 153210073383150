import React from "react";
import { TrackingProvider } from "@origin-digital/ods-core";
import {
  getAnalyticsData,
  trackingProviderCaptureClickCard,
} from "../../analytics";
import { EVIChargeCard } from "./components/EVIChargeCard";
import { VehiclesProvider } from "../../provider/VehiclesProvider";

export interface IEVIChargePhase2WidgetProps {
  userId: string;
}

export const EVIChargePhase2Widget = ({
  userId,
}: IEVIChargePhase2WidgetProps) => {
  const analyticsData = getAnalyticsData(userId);
  return (
    <TrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(analyticsData)}
    >
      <VehiclesProvider>
        <EVIChargeCard analyticsData={analyticsData} />
      </VehiclesProvider>
    </TrackingProvider>
  );
};
