import React from "react";
import { configFetch, configFetchCache } from "@origin-digital/daxi";
import { BroadbandWidget as Widget } from "@origin-digital/broadband-widget";

if (__DEV__) {
  configFetch({ disableAuthCheck: __DEV__ });
  configFetchCache({ disableCache: __DEV__ });
}

export interface IBroadbandWidgetProps {
  backendUrl: string;
}

export const BroadbandWidget = (props: IBroadbandWidgetProps) => (
  <Widget {...props} />
);
