import React from "react";
import { Alert, Text } from "@origin-digital/ods-core";
import { TextLinkButton } from "../../TextLinkButton/TextLinkButton";

export const WontMeetTargetUnknown = () => (
  <Alert data-id="error-text" tone="critical">
    <Text variant="body-small">
      We ran into some technical problems. Our team has been alerted and will
      look into it. You can{" "}
      <TextLinkButton onClick={() => location.reload()}>
        try again now
      </TextLinkButton>{" "}
      or come back later.
    </Text>
  </Alert>
);
