import React from "react";
import { Button } from "@origin-digital/ods-core";
import { navTo } from "@origin-digital/event-dispatcher";
import { useVehiclesData } from "../../provider/VehiclesProvider";

export const SavingsLink = () => {
  const { chargeCost } = useVehiclesData();
  if (!chargeCost?.chargeLocationId) {
    return null;
  }

  const { chargeLocationId } = chargeCost;

  return (
    <Button
      data-id="savings-link"
      variant="text"
      size="small"
      noTextPadding
      onClick={() => navTo.evIChargeUsage({ chargeLocationId })}
    >
      Savings
    </Button>
  );
};
