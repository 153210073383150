export const routes = {
  transactions: "/my/bills-payments/:accountId",
};

// Content card locations
export enum contentCardLocations {
  TOP = "dashboard-top",
  MIDDLE = "dashboard-middle",
  BOTTOM = "dashboard-bottom",
}
