import React from "react";
import { ISummary } from "../../contract/bff";
import { Onboarding } from "../Onboarding/Onboarding";
import { Points } from "../Points/Points";

export const Body = ({ userSummary }: { userSummary?: ISummary }) => {
  if (!userSummary) {
    return null;
  }

  return (
    <>
      {userSummary.smuStatus && userSummary.smuStatus !== "done" ? (
        <Onboarding onboardingProgress={userSummary?.smuStatus} />
      ) : (
        <Points userSummary={userSummary} />
      )}
    </>
  );
};
