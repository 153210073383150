import { useSnackbarState as usePopupState } from "@origin-digital/neon-common";

export const useSnoozePopupControl = () => {
  const [showDialog, handleDialogOpen, handleDialogClose] = usePopupState();

  const [
    showSuccessSnackbar,
    handleSuccessSnackbarOpen,
    handleSuccessSnackbarClose,
  ] = usePopupState();

  const [showErrorSnackbar, handleErrorSnackbarOpen, handleErrorSnackbarClose] =
    usePopupState();

  const onUpdateSuccess = (callback: () => void) => () => {
    callback();
    handleDialogClose();
    handleSuccessSnackbarOpen();
  };

  return {
    showDialog,
    handleDialogOpen,
    handleDialogClose,
    showErrorSnackbar,
    handleErrorSnackbarClose,
    handleErrorSnackbarOpen,
    showSuccessSnackbar,
    handleSuccessSnackbarClose,
    handleSuccessSnackbarOpen,
    onUpdateSuccess,
    onUpdateError: handleErrorSnackbarOpen,
  };
};
