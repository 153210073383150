import { FeatureType } from "../../../graphql-types/rx-gateway/globalTypes";
import { hasFeature } from "../../../util/featuresHelper";
import { WidgetDefinition } from "../../WidgetDefinition";
import { getBatteryMaximiserWidgetProps } from "./batteryMaximiserWidgetPropsHelper";
import { BatteryMaximiserWidget } from "./src";

export const batteryMaximiserWidgetV2Definition: WidgetDefinition<{
  nmi: string;
}> = {
  widgetComponent: BatteryMaximiserWidget,
  name: "BatteryMaximiserWidgetV2",
  propsBuilder: getBatteryMaximiserWidgetProps,
  shouldLoad: (data) => hasFeature(data, FeatureType.BATTERY_MAXIMISER),
};
