import React from "react";
import { Stack, Text } from "@origin-digital/ods-core";
import { ICardAA } from "@origin-digital/reporting-client";
import { IApiError } from "../../../contract/bff";
import { ErrorCard } from "../../ErrorCard/ErrorCard";
import { TextLinkButton } from "../../TextLinkButton/TextLinkButton";

const noDataError: IApiError = {
  message: "No vehicle data found for user",
  status: 404,
};

interface IVehicleErrorCardProps {
  error?: IApiError;
  analyticsData: ICardAA;
}

export const VehicleErrorCard = ({
  error = noDataError,
  analyticsData,
}: IVehicleErrorCardProps) => {
  return (
    <ErrorCard
      error={error}
      analyticsData={analyticsData}
      displayText={
        <Stack>
          <Text data-id="display-text">
            We ran into some technical problems. Our team has been alerted and
            will look into it. You can{" "}
            <TextLinkButton onClick={() => location.reload()}>
              try again now
            </TextLinkButton>{" "}
            or come back later.
          </Text>
        </Stack>
      }
    />
  );
};
