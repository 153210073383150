import { IBatteryWidgetData } from "../hooks/useBatteryWidgetData";

export enum EBatteryWidgetState {
  LOADING = "LOADING",
  ERROR = "ERROR",
  RETROFIT = "RETROFIT",
  STANDARD = "STANDARD",
}
const showLoadingState = (data: IBatteryWidgetData) => {
  const { loading, homeEnergyState, error } = data;
  const isLoading = !!loading;
  const isInitialising = !loading && !homeEnergyState && !error;
  return isLoading || isInitialising;
};

const showErrorState = (data: IBatteryWidgetData) => {
  const { error, homeEnergyState } = data;
  return error || !homeEnergyState;
};

export const getBatteryWidgetState = (
  data: IBatteryWidgetData
): EBatteryWidgetState => {
  if (showLoadingState(data)) {
    return EBatteryWidgetState.LOADING;
  }

  if (showErrorState(data)) {
    return EBatteryWidgetState.ERROR;
  }

  return EBatteryWidgetState.STANDARD;
};
