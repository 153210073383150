import React from "react";
import { useVehicleLiveData } from "../../../provider/VehicleLiveDataProvider";
import { IAccountStatus } from "../../../contract/generated/bff/src";
import { ConnectionLostCard } from "../../ConnectionLostCard/ConnectionLostCard";
import { ConnectedCard } from "../../ConnectedCard/ConnectedCard";
import { RetrievingCarDetailsCard } from "../../RetrievingCarDetailsCard/RetrievingCarDetailsCard";
import { ConnectedReadOnlyCard } from "../../ConnectedReadOnlyCard/ConnectedReadOnlyCard";
import { InterventionCard } from "../../InterventionCard/InterventionCard";

export const VehicleCardContent = () => {
  const { accountStatus } = useVehicleLiveData();

  switch (accountStatus) {
    case IAccountStatus.CONNECTED:
      return <ConnectedCard />;
    case IAccountStatus.CONNECTED_READ_ONLY:
      return <ConnectedReadOnlyCard />;
    case IAccountStatus.REQUIRES_INTERVENTION:
      return <InterventionCard />;
    case IAccountStatus.RETRIEVING_CAR_DETAILS:
      return <RetrievingCarDetailsCard />;
    case IAccountStatus.CONNECTION_LOST:
    default:
      return <ConnectionLostCard />;
  }
};
