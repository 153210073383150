import { useEffect, useState } from "react";
import { getVehicles } from "../services/vehicle";
import { getChargeCostData } from "../services/chargeCost";
import { IApiError, IChargeCost, IVehicle } from "../contract/bff";

export const useGetVehicleList = () => {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState<IVehicle[]>();
  const [chargeCost, setChargeCost] = useState<IChargeCost>();
  const [error, setError] = useState<IApiError>();

  useEffect(() => {
    Promise.allSettled([getVehicles(), getChargeCostData()])
      .then(([vehicleResponse, chargeCostsResponse]) => {
        if (vehicleResponse.status === "fulfilled") {
          setVehicles(vehicleResponse.value.data);
        } else {
          setError(vehicleResponse.reason);
        }

        if (chargeCostsResponse.status === "fulfilled") {
          setChargeCost(chargeCostsResponse.value.data);
        } else {
          console.error(
            "Charge costs dashboard call failed:",
            chargeCostsResponse.reason
          );
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return { loading, vehicles, chargeCost, error };
};
