import { Button, Dialog, Heading, Stack, Text } from "@origin-digital/ods-core";
import React from "react";
import { BatteryOffIcon } from "../../Icons/BatteryOffIcon";
import { IControlDialogProps } from "./UpdateControlDialog";

export const ActivateControlDialog: React.FC<IControlDialogProps> = (props) => {
  const { open, loading, onClose, onConfirm } = props;

  return (
    <Dialog
      open={open}
      id="activate-control-dialog"
      data-id="activate-control-dialog"
      title={null}
      onClose={onClose}
    >
      <Stack space="xlarge" alignX="center">
        <BatteryOffIcon />
        <Stack space="medium">
          <Heading variant="h3" weight="medium" align="center">
            Turn on Battery Maximiser?
          </Heading>
          <Text align="center">
            Go back to optimising your battery anytime. We'll top up your
            battery with energy from the grid and export excess energy, so you
            get more savings.
          </Text>
        </Stack>

        <Stack space="xlarge" alignX="center">
          <Button
            color="positive"
            noTextPadding
            spinner={loading}
            onClick={onConfirm}
            data-id="confirm"
          >
            Turn on
          </Button>
          <Button
            variant="text"
            size="small"
            noTextPadding
            onClick={onClose}
            data-id="cancel"
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
