import React from "react";
import { navTo } from "@origin-digital/event-dispatcher";
import { Button, Card, Stack } from "@origin-digital/ods-core";
import { IDashboard } from "../../contract/generated/bff/src";
import { BatteryChargeStatus } from "../BatteryChargeStatus/BatteryChargeStatus";
import { CreditCostLast24Hours } from "../CreditCostLast24Hours/CreditCostLast24Hours";
import { FlowStatus } from "../FlowStatus/FlowStatus";
import { TrackingProvider } from "../TrackingProvider";
import { Header } from "../Header/Header";
import { Snooze } from "../Snooze";
import { TimeOfDay } from "@origin-digital/neon-common";

export interface IStandardBatteryWidgetProps {
  timeOfDay: TimeOfDay;
  homeEnergyState: IDashboard;
  nmi: string;
  refetch: () => void;
}

export const StandardBatteryWidget = ({
  timeOfDay,
  homeEnergyState,
  nmi,
  refetch,
}: IStandardBatteryWidgetProps) => {
  const {
    systemStatus,
    credit,
    soc,
    chargingStatus,
    weather,
    updatedAt,
    controlledFrom,
    controlStatus,
  } = homeEnergyState;

  return (
    <TrackingProvider>
      <Card data-id="battery-maximiser-widget-card">
        <Stack space="medium" dividers>
          <Header
            timeOfDay={timeOfDay}
            updatedAt={updatedAt}
            weatherData={weather}
          />
          <Stack space="small">
            <BatteryChargeStatus soc={soc} chargingStatus={chargingStatus} />
            <FlowStatus status={systemStatus} />
            <CreditCostLast24Hours amount={credit} />
            <Stack space="medium">
              <Snooze
                nmi={nmi}
                controlledFrom={controlledFrom}
                refetch={refetch}
                status={controlStatus}
              />
              <Button
                data-id="battery-maximiser-usage-link"
                variant="text"
                size="small"
                noTextPadding
                onClick={() => navTo.batteryMaximiserUsage({ nmi })}
              >
                Energy breakdown
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </TrackingProvider>
  );
};
