import { hasInternetAccountInActiveOrSetupStatus } from "../../../../util/account";
import { WidgetDefinition } from "../../../WidgetDefinition";
import {
  UserAccounts_viewer_digital,
  UserAccounts_viewer_digital_accounts,
} from "../../../../graphql-types/rx-gateway/UserAccounts";
import {
  DigitalAccountStatus,
  DigitalAccountType,
} from "../../../../graphql-types/rx-gateway/globalTypes";
import { BroadbandWidget, IBroadbandWidgetProps } from "./index";

const getActiveBackendForInternet = (data: UserAccounts_viewer_digital) => {
  const superloopAccounts = data.accounts.some(
    (account: UserAccounts_viewer_digital_accounts) =>
      account.type === DigitalAccountType.SUPERLOOP &&
      (account.status === DigitalAccountStatus.ACTIVE ||
        account.status === DigitalAccountStatus.SETUP)
  );

  if (superloopAccounts) {
    return TAL.config.broadband.superloopLink;
  }

  return TAL.config.broadband.abbLink;
};

export const broadbandWidgetDefinition: WidgetDefinition<IBroadbandWidgetProps> =
  {
    widgetComponent: BroadbandWidget,
    name: "BroadbandWidget",
    shouldLoad: (data) => hasInternetAccountInActiveOrSetupStatus(data),
    propsBuilder: (data) => ({
      backendUrl: getActiveBackendForInternet(data),
    }),
  };
