import { UserAccounts_viewer_digital } from "../../../graphql-types/rx-gateway/UserAccounts";
import { getActiveService, hasActiveService } from "../../../util/service";
import { DigitalServiceType } from "../../../graphql-types/rx-gateway/globalTypes";
import { getAccountFromServiceId } from "./src/util/commbattAccountHelper";

export function getCommunityBatteryWidgetProps(
  data: UserAccounts_viewer_digital
): {
  accountId: string;
} {
  const service = getCombatService(data);
  const serviceId = service
    ? getAccountFromServiceId(service.backendServiceId)
    : undefined;

  if (serviceId) {
    return {
      accountId: serviceId,
    };
  }

  throw new Error(`Failed to get commbatt service from user accounts data`);
}

const getCombatService = (data: UserAccounts_viewer_digital) =>
  getActiveService(data, DigitalServiceType.COMMUNITY_BATTERY) ||
  getActiveService(data, DigitalServiceType.ESAAS);

export const hasCombatService = (data: UserAccounts_viewer_digital) =>
  hasActiveService(data, DigitalServiceType.COMMUNITY_BATTERY) ||
  hasActiveService(data, DigitalServiceType.ESAAS);
