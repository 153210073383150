import { FeatureType } from "../../../graphql-types/rx-gateway/globalTypes";
import { hasFeature } from "../../../util/featuresHelper";
import { WidgetDefinition } from "../../WidgetDefinition";
import { getEvIChargeWidgetProps } from "./evIChargeWidgetPropsHelper";
import { EVIChargePhase2Widget } from "./src";

export const evIChargePhase2WidgetDefinition: WidgetDefinition<{
  userId: string;
}> = {
  widgetComponent: EVIChargePhase2Widget,
  name: "EVIChargePhase2Widget",
  propsBuilder: (data) => getEvIChargeWidgetProps(data),
  shouldLoad: (data) => hasFeature(data, FeatureType.EV_ICHARGE),
};
