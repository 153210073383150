import { useEffect, useState } from "react";
import { fetchAccessToken } from "@origin-digital/event-dispatcher";

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await fetchAccessToken({});
      if (response?.accessToken) {
        setAccessToken(response.accessToken);
      }
      setLoading(false);
    })();
  }, []);

  return { loading, accessToken };
};
