import React from "react";
import { IChargeStatus, IVehicle } from "../../../contract/bff";
import { ICardAA } from "@origin-digital/reporting-client";
import { ErrorCard } from "../../ErrorCard/ErrorCard";
import { InvalidStatusMessage } from "./InvalidStatusMessage";

const invalidChargeStatuses = [
  IChargeStatus.PLUGGED_IN_FAULT,
  IChargeStatus.PLUGGED_IN_NO_POWER,
  IChargeStatus.UNKNOWN,
];
export const isInvalidChargeStatus = (data: IVehicle) =>
  invalidChargeStatuses.includes(data.chargeState.status);

interface IVehicleInvalidStatusProps {
  status: IChargeStatus;
  analyticsData: ICardAA;
}

export const VehicleInvalidStatus = ({
  status,
  analyticsData,
}: IVehicleInvalidStatusProps) => {
  const error = { message: `Charging error status: ${status}`, status: 500 };
  return (
    <ErrorCard
      error={error}
      analyticsData={analyticsData}
      displayText={<InvalidStatusMessage status={status} />}
    />
  );
};
