import { SpikeWidget } from "./SpikeWidget";
import { WidgetDefinition } from "../../WidgetDefinition";
import { FeatureType } from "../../../graphql-types/rx-gateway/globalTypes";
import { hasFeature } from "../../../util/featuresHelper";

export const spikeWidgetDefinition: WidgetDefinition<{}> = {
  widgetComponent: SpikeWidget,
  name: "SpikeWidget",
  shouldLoad: (data) => hasFeature(data, FeatureType.OHM),
};
