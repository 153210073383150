import React from "react";
import { SvgIcon, SvgIconProps } from "@origin-digital/ods-icons";

export const PowerlineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1357 21.9908C18.2552 21.9745 18.3724 21.9366 18.4809 21.8771C18.5898 21.8174 18.6851 21.7386 18.7633 21.6462C18.8565 21.5363 18.9221 21.4111 18.9603 21.2796C18.9987 21.1482 19.0105 21.0073 18.9908 20.8646C18.9864 20.8317 18.9803 20.799 18.9725 20.7666L16.1355 8H18.382L18.882 9C19.0055 9.24699 19.3058 9.3471 19.5528 9.22361L20.4472 8.77639C20.6942 8.6529 20.7943 8.35256 20.6708 8.10557L19.8944 6.55279C19.7159 6.19572 19.3526 5.99214 18.9783 6H15.6911L14.9762 2.78307C14.8711 2.31001 14.4469 1.99031 13.9803 2H10.0197C9.55307 1.99032 9.12893 2.31001 9.02381 2.78307L8.30893 6H5.02172C4.64745 5.99213 4.28411 6.19571 4.10558 6.55279L3.32918 8.10557C3.20569 8.35256 3.3058 8.6529 3.55279 8.77639L4.44722 9.22361C4.69421 9.3471 4.99454 9.24699 5.11804 9L5.61804 8H7.86449L5.02745 20.7667C5.01978 20.7988 5.01371 20.8312 5.00925 20.8638C4.98939 21.0072 5.00129 21.1487 5.04001 21.2807C5.07803 21.4112 5.14315 21.5354 5.23536 21.6446C5.31433 21.7384 5.41085 21.8182 5.52136 21.8783C5.62808 21.9365 5.74315 21.9738 5.86059 21.9903C6.00526 22.0108 6.14814 21.999 6.28127 21.9598C6.41152 21.9218 6.53556 21.8567 6.64454 21.7646C6.67051 21.7428 6.6954 21.7196 6.7191 21.6951L12 16.4142L17.2809 21.6951C17.3046 21.7196 17.3295 21.7428 17.3554 21.7646C17.4649 21.8571 17.5895 21.9223 17.7204 21.9603C17.8519 21.9987 17.9929 22.0105 18.1357 21.9908ZM10.8022 4H13.1978L13.6423 6H10.3577L10.8022 4ZM9.91328 8L9.27708 10.8629L12 13.5858L14.7229 10.8629L14.0867 8H9.91328ZM16.2789 17.8646L13.4142 15L15.2372 13.177L16.2789 17.8646ZM8.76282 13.177L10.5858 15L7.72113 17.8646L8.76282 13.177Z"
    />
  </SvgIcon>
);
