import { apiBasePath } from "../config";
import {
  IBatteryControlStatus,
  activateBatteryControl,
  deactivateBatteryControl,
} from "../contract/generated/bff/src";

export type IUpdateSnooze = {
  nmi: string;
  status: IBatteryControlStatus;
};

export const updateSnooze = async (
  param: IUpdateSnooze
): Promise<IBatteryControlStatus> => {
  const { nmi, status } = param;
  const fetchFunc =
    status === IBatteryControlStatus.ACTIVE
      ? activateBatteryControl
      : deactivateBatteryControl;

  try {
    const { data, error } = await fetchFunc(apiBasePath, { nmi }, "jwt", 0);

    if (!data || error) {
      throw new Error(error?.message);
    }

    return data.status;
  } catch (error) {
    return Promise.reject(
      "[updateSnooze] Failed to update battery control status"
    );
  }
};
