import sortby from "lodash.sortby";
import { ServiceType } from "src/graphql-types/rx-gateway/globalTypes";
import { PortfolioSummaryQuery_viewer_kraken_accounts } from "src/graphql-types/rx-gateway/PortfolioSummaryQuery";
import { parseDate } from "src/util/dateHelper";

const getServiceTypePriority = (type: ServiceType): number => {
  // changed from switch clause to array to remove boilerplate
  // the order in this list represents the order of portfolio
  const serviceTypeSequence = [
    ServiceType.ELECTRICITY,
    ServiceType.EMBEDDED_ELECTRICITY,
    ServiceType.GAS,
    ServiceType.EMBEDDED_HOT_WATER,
  ];
  const index = serviceTypeSequence.indexOf(type);
  // for unknown service type, index will be -1
  // return the last priority for unknown
  return index < 0 ? serviceTypeSequence.length : index;
};

export const sortPortfolio = <
  t extends PortfolioSummaryQuery_viewer_kraken_accounts
>(
  accounts: t[]
): t[] => {
  const portfolioSortOrder = [
    (account: t) => account.services[0]?.property?.address.components.postcode,
    (account: t) => account.services[0]?.property?.address.components.locality,
    (account: t) => getServiceTypePriority(account.services[0]?.type),
    (account: t) => parseDate(account.services[0]?.validFrom)?.valueOf(),
  ];
  return sortby(accounts, portfolioSortOrder);
};
