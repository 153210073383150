import { IConfig } from "../config";

export const getOhmPath = (
  isProd: boolean,
  isNative: boolean,
  config: IConfig,
  accessToken?: string
): string => {
  const path = isProd ? config.ohmPathProd : config.ohmPathStaging;
  const params = new URLSearchParams();
  if (accessToken) {
    params.append("originAccessToken", accessToken);
  }
  if (isNative) {
    params.append("app", "true");
  }

  const paramString = params.toString();

  if (paramString) {
    return `${path}?${paramString}`;
  }

  return path;
};
