import React from "react";
import { DividerCard, Stack, TrackingProvider } from "@origin-digital/ods-core";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import {
  titlePrefix,
  trackingProviderCaptureClickCard,
} from "@origin-digital/widget-helpers";
import { ICardAA } from "@origin-digital/reporting-client";
import { useUserSummary } from "../../hooks/useUserSummary";
import { Loader } from "../Loader/Loader";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { Body } from "../Body/Body";

const analyticsData: ICardAA = {
  id: "card-spike",
  title: `${titlePrefix}Spike`,
  category: "dashboard-card",
  primaryCta: "Go to Spike",
  product: { category: "product", divisionCode: "OHM" },
};

export const SpikeWidget = () => {
  const { updateWidgetStatus } = useWidgetProvider();
  const { userSummary, error, loading } = useUserSummary();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    updateWidgetStatus(WidgetStatusCode.FAILURE, [{ ...analyticsData, error }]);
  } else {
    updateWidgetStatus(WidgetStatusCode.COMPLETE, [analyticsData]);
  }

  return (
    <TrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(analyticsData)}
    >
      <DividerCard data-id="spike-widget-body">
        <Stack dividers>
          <Header />
          <Body userSummary={userSummary} />
        </Stack>
        <Footer />
      </DividerCard>
    </TrackingProvider>
  );
};
