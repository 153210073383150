import React from "react";

import { configFetch, configFetchCache } from "@origin-digital/daxi";

import {
  EVIChargePhase2Widget as Widget,
  IEVIChargePhase2WidgetProps,
} from "./components/EVIChargeWidget/EVIChargeWidget";

if (__DEV__) {
  configFetch({ disableAuthCheck: __DEV__ });
  configFetchCache({ disableCache: __DEV__ });
}

export const EVIChargePhase2Widget = (props: IEVIChargePhase2WidgetProps) => (
  <Widget {...props} />
);
