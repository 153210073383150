import React from "react";
import { Header } from "../Header/Header";
import { Alert, Stack, Text } from "@origin-digital/ods-core";

export const CommunityBatteryWidgetSetup = () => {
  return (
    <Stack
      space="medium"
      dividers
      data-id="community-battery-widget-state-setup"
    >
      <Header />
      <Alert tone="info">
        <Text>
          No data yet. Come back after 24 - 48 hours to view your energy
          breakdown.
        </Text>
      </Alert>
    </Stack>
  );
};
