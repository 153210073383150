import React from "react";
import {
  Column,
  Columns,
  Heading,
  IconButton,
  Inline,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import { CarIcon } from "../../icons/CarIcon";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import { BasicHeader } from "../BasicHeader/BasicHeader";
import { OrangePill } from "../Pill/Pill";
import { IconInfo } from "@origin-digital/ods-icons";
import { InfoModal } from "../InfoModal/InfoModal";
import { vendorRequiresChargingExplainer } from "../../utils/vendor";

const getMakeModelText = (make: string, model: string) =>
  [make, model]
    .filter((val) => !!val)
    .concat()
    .join(" ");

export const Header = () => {
  const { make = "", model = "", vendor } = useVehicleLiveData();
  const [showInfoModal, setShowInfoModal] = React.useState(false);

  const makeModel = getMakeModelText(make, model);
  const shouldShowInfoModal = vendorRequiresChargingExplainer(vendor);

  if (!makeModel) {
    return <BasicHeader />;
  }

  return (
    <Columns data-id="ev-icharge-widget-header" alignY="center" space="xsmall">
      <Column width="content">
        <CarIcon />
      </Column>
      <Column>
        <Stack space="none">
          <Inline space="xsmall" alignY="center">
            <Heading variant="h4" data-id="ev-icharge-heading">
              EV Power Up
            </Heading>
            <OrangePill
              weight="medium"
              variant="overline-text"
              tone="neutralDark"
            >
              BETA
            </OrangePill>
          </Inline>
          <Inline space={"small"}>
            <Text variant="body-small" tone="neutralLight" data-id="make-model">
              {makeModel}
            </Text>
            {shouldShowInfoModal && (
              <>
                <IconButton
                  data-id={"ev-icharge-header-info-button"}
                  onClick={() => setShowInfoModal(true)}
                  noIconPadding
                >
                  <IconInfo size="xsmall" color="neutralLight" />
                </IconButton>
                <InfoModal
                  key="info-modal"
                  open={showInfoModal}
                  onClose={() => setShowInfoModal(false)}
                  vendor={vendor}
                />
              </>
            )}
          </Inline>
        </Stack>
      </Column>
    </Columns>
  );
};
