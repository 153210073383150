import { Card, Divider, Heading, Stack, Text } from "@origin-digital/ods-core";
import React from "react";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import { BasicHeader } from "../BasicHeader/BasicHeader";

import { useWidgetComplete } from "../../analytics";
import { RelinkVehicleButton } from "../RelinkVehicleButton";

export const ConnectedReadOnlyCard = () => {
  const { vehicleId } = useVehicleLiveData();
  useWidgetComplete();

  return (
    <Card data-id={`card-ev-icharge-read-only-${vehicleId}`}>
      <Stack space="large">
        <Stack space="medium">
          <BasicHeader />
          <Divider />
          <Heading variant="h4">Just one more step</Heading>
          <Text>
            Your EV account is linked, but you haven't yet authorised us to
            communicate with this vehicle. Until you do this, you won't receive
            credits for charging your vehicle.
          </Text>
        </Stack>
        <RelinkVehicleButton label="Connect to my EV" />
      </Stack>
    </Card>
  );
};
