import React, { useContext } from "react";
import { useGetVehicleList } from "./useGetVehicleList";
import { IApiError, IChargeCost, IVehicle } from "../contract/bff";

export interface IVehiclesProviderContext {
  loading?: boolean;
  error?: IApiError;
  vehicles?: IVehicle[];
  chargeCost?: IChargeCost;
}

export const VehiclesProviderContext =
  React.createContext<IVehiclesProviderContext>({
    loading: true,
  });

export const useVehiclesDataProviderContext = () =>
  useContext(VehiclesProviderContext);

export const useVehiclesData = () => {
  const { chargeCost, vehicles } = useContext(VehiclesProviderContext);

  if (!vehicles) {
    throw new Error("No vehicle data available");
  }

  return { vehicles, chargeCost };
};

interface IVehiclesProviderProps {
  children: React.ReactNode;
}

export const VehiclesProvider = ({ children }: IVehiclesProviderProps) => {
  const { loading, error, vehicles, chargeCost } = useGetVehicleList();

  return (
    <VehiclesProviderContext.Provider
      value={{ loading, error, vehicles, chargeCost }}
    >
      {children}
    </VehiclesProviderContext.Provider>
  );
};
