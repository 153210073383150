import { contentCardLocations } from "src/consts";
import { UserAccounts_viewer_digital } from "../graphql-types/rx-gateway/UserAccounts";
import { WidgetDefinition } from "./WidgetDefinition";
import { batteryWidgetDefinition } from "./widgets/batteryWidget";
import { brazeCardWidgetDefinition } from "./widgets/brazeCardWidget";
import { broadbandWidgetDefinition } from "./widgets/broadbandWidget";
import { evChargerWidgetDefinition } from "./widgets/evChargerWidget";
import { evIChargePhase2WidgetDefinition } from "./widgets/evIChargePhase2Widget";
import { greetingWidgetDefinition } from "./widgets/greetingWidget";
import { gstDisclaimerWidgetDefinition } from "./widgets/gstDisclaimerWidget";
import {
  lpgClosedWidgetDefinition,
  lpgWidgetDefinition,
} from "./widgets/lpgWidget";
import { portfolioSummaryWidgetDefinition } from "./widgets/portfolioWidget";
import { researchCommunitySignUpWidgetDefinition } from "./widgets/researchCommunitySignupWidget";
import { rewardsOffersWidgetDefinition } from "./widgets/rewardsOffersWidget";
import { spikeWidgetDefinition } from "./widgets/spikeWidget";
import { pendingOrderWidgetDefinition } from "./widgets/pendingOrderWidget/pendingOrderWidgetDefinition";
import { batteryMaximiserWidgetV2Definition } from "./widgets/batteryMaximiserWidgetV2";
import { communityBatteryWidgetDefinition } from "./widgets/commbattWidget";

export type WidgetManifest = WidgetDefinition<any>[];

// In order of appearance
function getBaseWidgetManifest(): WidgetManifest {
  return [
    greetingWidgetDefinition,
    brazeCardWidgetDefinition(contentCardLocations.TOP),
    batteryWidgetDefinition,
    batteryMaximiserWidgetV2Definition,
    evIChargePhase2WidgetDefinition,
    evChargerWidgetDefinition,
    communityBatteryWidgetDefinition,
    portfolioSummaryWidgetDefinition(false),
    lpgWidgetDefinition,
    broadbandWidgetDefinition,
    spikeWidgetDefinition,
    brazeCardWidgetDefinition(contentCardLocations.MIDDLE),
    brazeCardWidgetDefinition(contentCardLocations.BOTTOM),
    rewardsOffersWidgetDefinition,
    portfolioSummaryWidgetDefinition(true),
    lpgClosedWidgetDefinition,
    researchCommunitySignUpWidgetDefinition(false),
    gstDisclaimerWidgetDefinition,
    pendingOrderWidgetDefinition,
  ];
}

// Filter out widgets by widget name set to 'false' in config.toggles
function filterWidgetManifest(
  widgetManifest: WidgetManifest,
  toggles: Record<string, boolean>
): WidgetManifest {
  return widgetManifest.filter(
    (manifest) =>
      toggles[manifest.name] === undefined || toggles[manifest.name] === true
  );
}

function getWidgetsForUserData(
  widgetManifest: WidgetManifest,
  digitalData?: UserAccounts_viewer_digital | null
): WidgetDefinition<any>[] {
  if (!digitalData) {
    return [];
  }
  return widgetManifest.filter((widgetDefinition) =>
    widgetDefinition.shouldLoad
      ? widgetDefinition.shouldLoad(digitalData)
      : digitalData.accounts.length > 0
  );
}

export function getWidgetManifest(
  toggles: Record<string, boolean>,
  digitalData?: UserAccounts_viewer_digital | null
): WidgetManifest {
  const baseWidgetManifest = getBaseWidgetManifest();
  const filteredWidgetManifest = filterWidgetManifest(
    baseWidgetManifest,
    toggles
  );
  return getWidgetsForUserData(filteredWidgetManifest, digitalData);
}
