import { IVendor } from "../contract/generated/bff/src";

export const getVendorBrand = (vendor: IVendor): string => {
  switch (vendor) {
    case IVendor.BMW:
      return "BMW";
    case IVendor.MERCEDES:
      return "Mercedes";
    case IVendor.TESLA:
      return "Tesla";
    default:
      return vendor;
  }
};

export const getVendorAppManagementMethod = (vendor: IVendor): string => {
  if (vendor === IVendor.MERCEDES) {
    return "charge limit";
  }

  return "charge schedule";
};

const complexChargingVendors = [IVendor.BMW, IVendor.MERCEDES];
export const vendorRequiresChargingExplainer = (vendor: IVendor): boolean =>
  complexChargingVendors.includes(vendor);
