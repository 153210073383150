import {
  DigitalServiceStatus,
  DigitalServiceType,
} from "../../../graphql-types/rx-gateway/globalTypes";
import { UserAccounts_viewer_digital } from "../../../graphql-types/rx-gateway/UserAccounts";

export function getEvChargerWidgetProps(data: UserAccounts_viewer_digital): {
  chargerIds: string[];
} {
  const evAccounts = data.services.filter(
    ({ type, status }) =>
      type === DigitalServiceType.EV_CHARGER &&
      status === DigitalServiceStatus.ACTIVE
  );

  if (evAccounts.length > 0) {
    const chargerIds = evAccounts
      .map((service) => service.backendServiceId)
      .sort();
    return { chargerIds };
  }

  throw new Error(`Failed to get ev charger service from user data`);
}
