import React from "react";
import { TimeOfDay } from "@origin-digital/neon-common";
import { IWeatherResponse } from "../../contract/generated/bff/src";
import { Column, Columns, Heading, Stack } from "@origin-digital/ods-core";
import { HouseIcon } from "../Icons/HouseIcon";
import { InfoBar } from "../InfoBar/InfoBar";

interface IHeaderProps {
  timeOfDay: TimeOfDay;
  updatedAt: string;
  weatherData?: IWeatherResponse;
}

export const Header = ({ timeOfDay, updatedAt, weatherData }: IHeaderProps) => (
  <Columns alignY="center" space="xsmall" data-id="header">
    <Column width="content">
      <HouseIcon size="medium" />
    </Column>
    <Column>
      <Stack space="none">
        <Heading variant="h4" data-id="header-text">
          Your home energy
        </Heading>
        <InfoBar
          timeOfDay={timeOfDay}
          updatedAt={updatedAt}
          weatherData={weatherData}
        />
      </Stack>
    </Column>
  </Columns>
);
