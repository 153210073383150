import React, { useEffect } from "react";
import { IBatteryControlStatus } from "../../../contract/generated/bff/src";
import { useSnoozeUpdate } from "../../../hooks/useSnoozeUpdate";
import { ActivateControlDialog } from "./ActivateControlDialog";
import { DeactivateControlDialog } from "./DeactivateControlDialog";

export type IControlDialogProps = {
  open: boolean;
  loading: boolean;
  onClose(): void;
  onConfirm(): void;
};

type IUpdateControlDialogProps = {
  nmi: string;
  open: boolean;
  onClose(): void;
  onUpdateSuccess(): void;
  onUpdateError(): void;
  expectedStatus: IBatteryControlStatus;
};

export const UpdateControlDialog: React.FC<IUpdateControlDialogProps> = (
  props
) => {
  const { nmi, open, expectedStatus, onClose, onUpdateSuccess, onUpdateError } =
    props;
  const { update, data, error, loading } = useSnoozeUpdate();

  const onUpdate = () => {
    if (!loading) {
      if (data && !error) {
        onUpdateSuccess();
      }
      if (!data && error) {
        onUpdateError();
      }
    }
  };
  useEffect(onUpdate, [data, error, loading]);

  const onConfirm = () => update({ nmi, status: expectedStatus });

  return expectedStatus === IBatteryControlStatus.INACTIVE ? (
    <DeactivateControlDialog
      open={open}
      loading={loading}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  ) : (
    <ActivateControlDialog
      open={open}
      loading={loading}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};
