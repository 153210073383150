import React from "react";
import { Alert, Heading, Text } from "@origin-digital/ods-core";
import { ConfirmationDialog } from "@origin-digital/neon-common";
import { TargetEstimatedStateEnum } from "../../../contract/bff";
import { StartInstantChargeButton } from "./StartInstantChargeButton";
import { useVehicleLiveData } from "../../../provider/VehicleLiveDataProvider";

export const onTrackText =
  "You are currently on track to meet your scheduled charge target. Use Instant Charge only if you need your vehicle earlier than your target charge time.";
export const notOnTrackText =
  "Instant Charge is designed to override any scheduled charging and simply charge your EV continuously.";

const getDialogBodyText = (
  targetEstimatedState: TargetEstimatedStateEnum
): string => {
  if (targetEstimatedState === TargetEstimatedStateEnum.CAN_MEET) {
    return onTrackText;
  } else {
    return notOnTrackText;
  }
};

interface IEnableInstantChargeDialogProps {
  open: boolean;
  handleSuccessSnackbarOpen: () => void;
  handleErrorSnackbarOpen: () => void;
  handleClose: () => void;
}

export const EnableInstantChargeDialog = ({
  open,
  handleSuccessSnackbarOpen,
  handleErrorSnackbarOpen,
  handleClose,
}: IEnableInstantChargeDialogProps) => {
  const {
    activeSchedule: { targetEstimatedState },
  } = useVehicleLiveData();
  const bodyText = getDialogBodyText(targetEstimatedState);
  return (
    <ConfirmationDialog
      data-id="enable-instant-charge-dialog"
      id="enable-instant-charge-dialog"
      open={open}
      onCancel={handleClose}
      ctaButton={
        <StartInstantChargeButton
          handleSuccessSnackbarOpen={handleSuccessSnackbarOpen}
          handleErrorSnackbarOpen={handleErrorSnackbarOpen}
          handleClose={handleClose}
        />
      }
    >
      <Heading variant="h3">Instant charge</Heading>
      <Text align="center">{bodyText}</Text>
      <Alert tone="caution">
        The EV Power Up Rate will not apply and you won't earn any credits when
        using Instant Charge.
      </Alert>
    </ConfirmationDialog>
  );
};
