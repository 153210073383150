import React from "react";
import { useSnoozePopupContext } from "../../context/SnoozePopupContext";
import { IBatteryControlStatus } from "../../contract/generated/bff/src";
import { ActiveControlPanel } from "./ControlPanel/ActiveControlPanel";
import { InactiveControlPanel } from "./ControlPanel/InactiveControlPanel";
import { UpdateControlDialog } from "./Dialog/UpdateControlDialog";

export type ISnoozeProps = {
  nmi: string;
  controlledFrom: string | undefined | null;
  refetch(): void;
  status: IBatteryControlStatus;
};

export const Snooze = ({
  controlledFrom,
  refetch,
  nmi,
  status,
}: ISnoozeProps) => {
  const context = useSnoozePopupContext();
  const activateControl = context.activateControl!;
  const deactivateControl = context.deactivateControl!;

  const isControlActive = status === IBatteryControlStatus.ACTIVE;
  const expectedStatus = isControlActive
    ? IBatteryControlStatus.INACTIVE
    : IBatteryControlStatus.ACTIVE;
  const control = isControlActive ? deactivateControl : activateControl;

  return (
    <>
      <UpdateControlDialog
        nmi={nmi}
        open={control.showDialog}
        onClose={control.handleDialogClose}
        onUpdateSuccess={control.onUpdateSuccess(refetch)}
        onUpdateError={control.onUpdateError}
        expectedStatus={expectedStatus}
      />

      {isControlActive ? (
        <ActiveControlPanel handleDialogOpen={control.handleDialogOpen} />
      ) : (
        <InactiveControlPanel
          handleDialogOpen={control.handleDialogOpen}
          snoozeEnd={controlledFrom ?? ""}
        />
      )}
    </>
  );
};
