import React from "react";
import { Column, Columns, Heading, Text } from "@origin-digital/ods-core";
import { IconSpike } from "@origin-digital/ods-icons";

export const Header = () => (
  <Columns alignY="top" space="small">
    <Column width="content">
      <IconSpike size="medium" />
    </Column>
    <Column>
      <Heading variant="h4" data-id="spike-heading">
        Spike
      </Heading>
      <Text tone="neutralLight">Get paid to save energy</Text>
    </Column>
  </Columns>
);
