import React from "react";
import {
  Alert,
  Card,
  Stack,
  Text,
  TextLinkStyles,
} from "@origin-digital/ods-core";
import { Loader } from "../../../components/Loader";
import { content } from "./content";

export interface PortfolioLoaderProps {
  isLoading: boolean;
}

export const PortfolioLoader: React.FC<PortfolioLoaderProps> = ({
  isLoading,
}) => {
  return (
    <Card padding="small" data-id="account-card">
      <Loader isLoading={isLoading}>
        <Alert tone="critical" title={content.heading}>
          <Stack>
            <Text>
              {content.text}
              <TextLinkStyles
                as="button"
                data-id="failure-cta"
                className={`${content.cta}-button`}
                onClick={() => window.location.reload()}
              >
                {content.cta}
              </TextLinkStyles>
              {"."}
            </Text>
          </Stack>
        </Alert>
      </Loader>
    </Card>
  );
};
