import React, { ReactNode } from "react";
import { Button, Dialog, Stack } from "@origin-digital/ods-core";

interface IWontMeetLearnMoreDialogProps {
  open: boolean;
  children: ReactNode;
  handleClose: () => void;
}

export const WontMeetTargetLearnMoreDialog = ({
  open,
  children,
  handleClose,
}: IWontMeetLearnMoreDialogProps) => (
  <Dialog
    id="wont-meet-charging-target-learn-more"
    title=""
    open={open}
    onClose={handleClose}
  >
    <Stack space="large">
      {children}
      <Stack alignX="center">
        <Button variant="text" onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </Stack>
  </Dialog>
);
