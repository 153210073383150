import { useEffect } from "react";
import { titlePrefix } from "@origin-digital/widget-helpers";
import {
  ICardAA,
  IInteractionCardData,
  interactionCard,
} from "@origin-digital/reporting-client";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { useVehicleLiveDataContext } from "../provider/VehicleLiveDataProvider";

export const getAnalyticsData = (vehicleId: string): ICardAA => ({
  id: `card-ev-icharge-${vehicleId}`,
  category: "dashboard-card",
  title: `${titlePrefix}EV Power Up`,
  product: { category: "product", divisionCode: "EV" },
});

export const trackingProviderCaptureClickCard =
  (card: Pick<ICardAA, "id" | "title" | "primaryCta">) =>
  ({ label }: Pick<IInteractionCardData, "label">) => {
    const interactionCardData: IInteractionCardData = {
      card: {
        category: "dashboard-card",
        ...card,
        primaryCta: label,
      },
      label,
    };

    interactionCard("click", interactionCardData);
  };

export const useWidgetComplete = () => {
  const { updateWidgetStatus } = useWidgetProvider();
  const { analyticsData } = useVehicleLiveDataContext();
  useEffect(() => {
    updateWidgetStatus(WidgetStatusCode.COMPLETE, [analyticsData]);
  }, []);
};

export const useWidgetError = (analyticsData: ICardAA, error: any) => {
  const { updateWidgetStatus } = useWidgetProvider();
  useEffect(() => {
    updateWidgetStatus(WidgetStatusCode.FAILURE, [
      {
        ...analyticsData,
        error: {
          errorCode: error.status,
          errorMessage: error.message,
        },
      },
    ]);
  }, []);
};
