import React from "react";

export const MoonIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-id="weather-icon-moon"
  >
    <title>Night time</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-870.000000, -1620.000000)"
        fill="#FFB432"
        fillRule="nonzero"
      >
        <path
          transform="translate(881.500000, 1632.000000) rotate(-315.000000) translate(-881.500000, -1632.000000)"
          d="M878,1622 C876.95,1622 875.95,1622.16 875,1622.46 C879.06,1623.73 882,1627.52 882,1632 C882,1636.48 879.06,1640.27 875,1641.54 C875.95,1641.84 876.95,1642 878,1642 C883.52,1642 888,1637.52 888,1632 C888,1626.48 883.52,1622 878,1622 Z"
        />
      </g>
    </g>
  </svg>
);
