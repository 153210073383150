import React from "react";
import { Button, OriginThemeProvider } from "@origin-digital/ods-core";
import { coreMuiTheme } from "@origin-digital/ods-themes";
// @ts-ignore
import { MuiTheme } from "@origin-digital/ods-types";
import { activateInstantCharge } from "../../../services/instantCharge";
import { useVehicleLiveData } from "../../../provider/VehicleLiveDataProvider";

const theme: MuiTheme = {
  ...coreMuiTheme,
  palette: {
    ...coreMuiTheme.palette,
    primary: coreMuiTheme.palette.success,
  },
};

interface IStartButtonProps {
  handleSuccessSnackbarOpen: () => void;
  handleErrorSnackbarOpen: () => void;
  handleClose: () => void;
}

export const StartInstantChargeButton = ({
  handleSuccessSnackbarOpen,
  handleErrorSnackbarOpen,
  handleClose,
}: IStartButtonProps) => {
  const { vehicleId } = useVehicleLiveData();
  const [submitting, setSubmitting] = React.useState(false);

  const handleStart = async () => {
    setSubmitting(true);
    try {
      await activateInstantCharge(vehicleId);
      handleSuccessSnackbarOpen();
      handleClose();
    } catch (e) {
      handleErrorSnackbarOpen();
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <OriginThemeProvider
      muiTheme={theme}
      generatorClassNameOptions={{ disableGlobal: true, seed: "green" }}
    >
      <Button onClick={handleStart} spinner={submitting}>
        Start
      </Button>
    </OriginThemeProvider>
  );
};
