import React from "react";
import { ICardAA } from "@origin-digital/reporting-client";
import { LinkingCard } from "../../LinkingCard/LinkingCard";
import { VehicleCards } from "../../VehicleCards/VehicleCards";
import { LoadingCard } from "../../LoadingCard/LoadingCard";
import { VehicleErrorCard } from "../../VehicleCards/components/VehicleErrorCard";
import { useVehiclesDataProviderContext } from "../../../provider/VehiclesProvider";

interface IChargeCardProps {
  analyticsData: ICardAA;
}

export const EVIChargeCard = ({ analyticsData }: IChargeCardProps) => {
  const { loading, vehicles, error } = useVehiclesDataProviderContext();

  if (loading) {
    return <LoadingCard />;
  } else if (error) {
    return <VehicleErrorCard analyticsData={analyticsData} error={error} />;
  } else if (vehicles?.length) {
    return <VehicleCards vehicles={vehicles} />;
  } else {
    return <LinkingCard analyticsData={analyticsData} />;
  }
};
