import React from "react";
import { Stack, Text } from "@origin-digital/ods-core";
import { useVehicleLiveData } from "../../../provider/VehicleLiveDataProvider";
import { UnpluggedMessage } from "./UnpluggedMessage";

export const Unplugged = () => {
  const { atChargeLocation } = useVehicleLiveData();
  if (atChargeLocation) {
    return (
      <Stack space="medium">
        <UnpluggedMessage />
        <Text variant="body-small">
          Keep your vehicle plugged in to maximise your potential to earn
          credits.
        </Text>
      </Stack>
    );
  } else {
    return <UnpluggedMessage />;
  }
};
