import {
  Box,
  Card,
  IconButton,
  Inline,
  Stack,
  Text,
  TextProps,
} from "@origin-digital/ods-core";
import { IconInfo, IconSolar } from "@origin-digital/ods-icons";
import React from "react";
import { formatKwh } from "../../util/formatting";
import { HollowBatteryIcon } from "../Icons/HollowBatteryIcon";
import { PowerlineIcon } from "../Icons/PowerlineIcon";
import { SolarExportLineGraph } from "./SolarExportLineGraph";

export interface SolarExportSummaryProps {
  total: number;
  toGrid: number;
  toBattery: number;
  toBatteryLimit: number;
  onInfoPress: () => void;
}

const BodyText = (props: TextProps) => <Text {...props} variant="body-small" />;

export const SolarExportSummary = ({
  total,
  toGrid,
  toBattery,
  toBatteryLimit,
  onInfoPress,
}: SolarExportSummaryProps) => (
  <Card
    outline
    paddingXSpecific="medium"
    paddingYSpecific="medium"
    data-id="community-battery-solar-export-summary"
  >
    <Stack space="small">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Inline space="xsmall" alignY="center">
          <IconSolar color="positive" size="xsmall" />
          <BodyText>Solar export</BodyText>
          <IconButton
            data-id={"community-battery-solar-export-info-button"}
            onClick={onInfoPress}
            noIconPadding
          >
            <IconInfo size="xsmall" color="neutralLight" />
          </IconButton>
        </Inline>
        <Box marginLeft="medium">
          <BodyText weight="medium" tone="neutralDark">
            {formatKwh(total)}
          </BodyText>
        </Box>
      </Box>
      <SolarExportLineGraph
        total={total}
        toBattery={toBattery}
        toBatteryLimit={toBatteryLimit}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Inline space="xxsmall">
          <HollowBatteryIcon color="positive" />
          <Stack space="none">
            <BodyText>To battery</BodyText>
            <BodyText tone="neutralDark">
              <BodyText weight="medium" inline>
                {formatKwh(toBattery, false)}
              </BodyText>{" "}
              / {formatKwh(toBatteryLimit)}
            </BodyText>
          </Stack>
        </Inline>
        <Inline space="xxsmall" alignX="right">
          <Stack space="none" alignX="right">
            <BodyText>To grid</BodyText>
            <BodyText weight="medium" tone="neutralDark">
              {formatKwh(toGrid)}
            </BodyText>
          </Stack>
          <PowerlineIcon />
        </Inline>
      </Box>
    </Stack>
  </Card>
);
