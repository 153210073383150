import { WidgetDefinition } from "../../WidgetDefinition";
import { BrazeCardWidget, BrazeCardWidgetProps } from "./BrazeCard";

export const brazeCardWidgetDefinition = (
  location: string
): WidgetDefinition<BrazeCardWidgetProps> => ({
  widgetComponent: BrazeCardWidget,
  name: "BrazeCardWidget",
  propsBuilder: () => ({
    location,
  }),
});
