import React from "react";
import { useMultiWidgetAnalytics } from "@origin-digital/widget-provider";
import { Stack } from "@origin-digital/ods-core";
import { VehicleLiveDataProvider } from "../../provider/VehicleLiveDataProvider";
import { IVehicle } from "../../contract/bff";
import { VehicleCard } from "./components/VehicleCard";

interface IVehicleCardsProps {
  vehicles: IVehicle[];
}

export const VehicleCards = ({ vehicles }: IVehicleCardsProps) => {
  const vehicleIds = vehicles.map(({ vehicleId }) => vehicleId);
  const onStatusChange = useMultiWidgetAnalytics(
    vehicleIds,
    (vehicleId: string) => `card-ev-icharge-${vehicleId}`
  );
  return (
    <Stack space={["medium", "xlarge"]}>
      {vehicleIds.map((vehicleId) => (
        <VehicleLiveDataProvider
          key={vehicleId}
          vehicleId={vehicleId}
          onStatusChange={onStatusChange}
        >
          <VehicleCard />
        </VehicleLiveDataProvider>
      ))}
    </Stack>
  );
};
