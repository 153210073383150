import { AppMetricsProvider } from "@origin-digital/tal-fe";
import { WidgetStatusCode } from "@origin-digital/widget-provider";
import React, { ReactNode } from "react";

export type AppReadyState = Record<string, WidgetStatusCode> | undefined;

export const isAppReady = (state: AppReadyState) => {
  if (state === undefined) return false;

  return Object.values(state).every(
    (status) => status !== WidgetStatusCode.INIT
  );
};

export const DashboardAppMetricsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <AppMetricsProvider<AppReadyState> isAppReady={isAppReady}>
      {children}
    </AppMetricsProvider>
  );
};
