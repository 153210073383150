import React from "react";
import { Button, Dialog, Heading, Stack, Text } from "@origin-digital/ods-core";
import { IVendor } from "../../contract/generated/bff/src";
import {
  getVendorAppManagementMethod,
  getVendorBrand,
} from "../../utils/vendor";

export const InfoModal = ({
  open,
  onClose,
  vendor,
}: {
  open: boolean;
  onClose: () => void;
  vendor: IVendor;
}) => {
  const brand = getVendorBrand(vendor);
  const appManagementMethod = getVendorAppManagementMethod(vendor);

  return (
    <Dialog
      id="ev-icharge-info-modal"
      open={open}
      title=""
      onClose={onClose}
      noFullScreenOnMobile
    >
      <Stack space="xlarge" alignX="center">
        <Stack space="xlarge">
          <Stack space="small">
            <Heading variant="h3" weight="medium">
              How does EV Power Up manage periodic charging on your {brand}?
            </Heading>
            <Text>
              To allow EV Power Up to switch on charging when rates are low, and
              switch off during peak periods, we need to temporarily adjust your
              EV's {appManagementMethod}. There's no need to take any action in
              your {brand} app, and we'll automatically revert to your original
              settings at the end of each charge session.
            </Text>
          </Stack>
          <Stack space="small">
            <Heading variant="h3" weight="medium">
              Is there any delay in updating my charge status?
            </Heading>
            <Text>
              Yes, a slight delay is a normal part of the EV Power Up experience
              as we communicate with your EV and establish your charging
              session. This could be up to a few minutes, but there's no need to
              take any action and we'll alert you if there are any problems.
            </Text>
          </Stack>
        </Stack>
        <Button onClick={onClose}>OK - got it!</Button>
      </Stack>
    </Dialog>
  );
};
