import { Button, Dialog, Stack, Text } from "@origin-digital/ods-core";
import React from "react";

export const ChargingInfoDialog = ({
  isOpen,
  onClose,
  "data-id": dataId,
}: {
  isOpen: boolean;
  onClose: () => void;
  "data-id"?: string;
}) => (
  <Dialog
    id={`${dataId}-dialog`}
    title={"How EV Power Up charges your vehicle"}
    open={isOpen}
    onClose={onClose}
  >
    <Stack space={"small"} alignX={"center"}>
      <Text>
        EV Power Up works by automatically charging your EV when prices are low
        and avoids charging during peak demand periods.
      </Text>
      <Text>
        This means EV Power Up will periodically stop and start charging your EV
        on any given schedule to ensure you meet your charge target at a super
        low EV rate.
      </Text>
      <Text>
        It’s a lot like an engine stop/start feature that momentarily stops your
        engine when your car is idle and restarts it when you’re ready to move,
        saving you fuel.
      </Text>
      <Button
        data-id={`${dataId}-close-button`}
        onClick={onClose}
        variant="text"
      >
        Close
      </Button>
    </Stack>
  </Dialog>
);
