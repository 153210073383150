// @ts-nocheck
export enum IChargeStatus {
    UNKNOWN = 'unknown',
    UNPLUGGED = 'unplugged',
    PLUGGED_IN_CHARGING = 'plugged_in_charging',
    PLUGGED_IN_INITIALIZING = 'plugged_in_initializing',
    PLUGGED_IN_COMPLETE = 'plugged_in_complete',
    PLUGGED_IN_STOPPED = 'plugged_in_stopped',
    PLUGGED_IN_NO_POWER = 'plugged_in_no_power',
    PLUGGED_IN_FAULT = 'plugged_in_fault'
}

