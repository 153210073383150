import styled from "styled-components";

export enum RING_COLOURS {
  GREEN = "#008906",
  LIGHT_GREY = "#f1f1f1",
  DARK_GREY = "#ABABAB",
  HIGHLIGHT = "#FFF",
}

export const radius = 53;
const strokeWidth = 6;
export const cxcy = radius + strokeWidth / 2;
const circleWidth = cxcy * 2;
const circumference = 2 * Math.PI * radius;
const highlightAnimationDuration = 2.5;
const highlightStrokeLengthPercentage = 25;

const calculateFilledInProgress = (progressPercent: number) =>
  circumference - (progressPercent / 100) * circumference;

export const ChargingProgressStyle = styled.div<{
  soc: number;
  isCharging: boolean;
  targetBatteryLevel: number;
  ringColour: string;
}>`
  position: relative;
  width: ${circleWidth}px;
  height: ${circleWidth}px;

  svg {
    height: 112px;
    width: 112px;
    transform: rotate(-90deg);
  }

  #progress,
  #maximum-charge {
    fill: transparent;
  }

  #progress {
    transition: stroke-dashoffset 1s cubic-bezier(0, 0, 0.05, 1);
    stroke: ${(props) => props.ringColour};
    stroke-width: ${strokeWidth}px;
    stroke-linecap: round;
    stroke-dasharray: ${circumference};
    stroke-dashoffset: ${({ soc }) => calculateFilledInProgress(soc)};
  }

  #maximum-charge {
    stroke: ${RING_COLOURS.LIGHT_GREY};
    stroke-width: ${strokeWidth}px;
    stroke-linecap: round;
    stroke-dasharray: ${circumference};
    stroke-dashoffset: ${({ targetBatteryLevel }) =>
      calculateFilledInProgress(targetBatteryLevel)};
  }

  #highlight {
    fill: transparent;
    animation: ${highlightAnimationDuration}s circle-chart-pulse 1s linear
      infinite reverse;
    stroke: ${({ isCharging }) =>
      isCharging ? "url(#highlight-gradient)" : "none"};
    stroke-width: ${strokeWidth}px;
    stroke-linecap: round;
    stroke-dasharray: ${circumference};
    stroke-dashoffset: ${calculateFilledInProgress(
      highlightStrokeLengthPercentage
    )};
    transform-origin: center;
  }

  @keyframes circle-chart-pulse {
    to {
      transform: rotate(-360deg);
    }
  }

  #charging-percent {
    position: absolute;
    top: 32px;
  }
`;
