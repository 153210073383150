import * as React from "react";
import styled from "styled-components";

import { Box, Heading, Stack, Text } from "@origin-digital/ods-core";

interface ITimelineRow {
  dataId: string;
  currentStatus?: boolean;
  title: string;
  description?: string;
  coloredBullet?: boolean;
  showLine?: boolean;
  coloredVerticalLine?: boolean;
}

interface IBullet {
  coloredBullet?: boolean;
}

interface IVerticalLine {
  coloredLine?: boolean;
}

export const Onboarding = ({
  onboardingProgress,
}: {
  onboardingProgress: string;
}) => {
  if (!onboardingProgress) {
    return null;
  }

  const step2 = onboardingProgress === "building_forecast";

  return (
    <Stack space="medium">
      <Heading data-id="onboarding-card-heading" variant="h4">
        What's next?
      </Heading>
      <Stack space="large">
        <TimelineRow
          dataId="setup"
          coloredBullet
          title="Smart meter upgrade in progress"
          description="Your new smart meter installation has been requested. As a reminder, it can take up to 30 days for your new meter to be installed."
          currentStatus={!step2}
          showLine
          coloredVerticalLine={step2}
        />
        <TimelineRow
          dataId="meter-read"
          title="Your new smart meter is installed and active!"
          description="We're currently building your SpikeHour forecast based on your average energy use. Once complete, you'll be invited to your first SpikeHour event and on your way to energy saving rewards with Spike."
          coloredBullet={step2}
          currentStatus={step2}
          showLine
        />
        <TimelineRow dataId="done" title="You're all done!" />
      </Stack>
    </Stack>
  );
};

const TimelineRow = ({
  dataId,
  title,
  description,
  currentStatus,
  coloredBullet,
  showLine,
  coloredVerticalLine,
}: ITimelineRow) => {
  return (
    <Box display="flex" position="relative" alignItems="flex-start">
      <Box marginRight="small" marginTop="xxsmall">
        <Bullet coloredBullet={coloredBullet} />
        {showLine && <VerticalLine coloredLine={coloredVerticalLine} />}
      </Box>
      <Box>
        <Text
          weight={currentStatus ? "medium" : "regular"}
          tone={currentStatus ? "neutralDark" : "neutralLight"}
          data-id={`timelineRowTitle-${dataId}${
            currentStatus ? "-highlight" : ""
          }`}
        >
          {title}
        </Text>
        {currentStatus && (
          <Box marginTop="xxsmall">
            <Text
              data-id={`timelineRowDesc-${dataId}${
                currentStatus ? "-highlight" : ""
              }`}
              weight="regular"
            >
              {description}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const Bullet = styled.div<IBullet>`
  width: 8px;
  height: 8px;
  background-color: ${(props) =>
    props.coloredBullet
      ? props.theme.colors.positiveLight
      : props.theme.colors.grey200};
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 2px;
`;

const VerticalLine = styled.div<IVerticalLine>`
  height: 100%;
  background-color: ${(props) =>
    props.coloredLine
      ? props.theme.colors.positiveLight
      : props.theme.colors.grey200};
  position: absolute;
  z-index: 1;
  width: 1px;
  display: block;
  margin-left: 3px;
  margin-top: 2px;
`;
