import { ISummaryPointCurrency } from "../contract/bff";

export const formatAmount = (amount: number): string => {
  const isNegative = amount < 0;
  const formattedValue = Math.abs(amount)
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${isNegative ? "-" : ""}${formattedValue}`;
};

export const formatPointCurrency = (
  pointCurrency?: ISummaryPointCurrency | null
): string => {
  switch (pointCurrency?.toLowerCase()) {
    case ISummaryPointCurrency.WATTS.toLowerCase():
      return "Watts";
    case ISummaryPointCurrency.POINTS.toLowerCase():
    default:
      return "Points";
  }
};
