import React from "react";
import { SnoozePopupContextProvider } from "../../context/SnoozePopupContext";
import { useBatteryWidgetData } from "../../hooks/useBatteryWidgetData";
import { useUpdateBatteryWidgetStatus } from "../../hooks/useUpdateBatteryWidgetStatus";
import {
  EBatteryWidgetState,
  getBatteryWidgetState,
} from "../../util/batteryStateHelpers";
import { BatteryMaximiserWidgetError } from "../Error/BatteryMaximiserWidgetError";
import { Loader } from "../Loader/Loader";
import { StandardBatteryWidget } from "../Standard";
import { getTimeOfDay } from "@origin-digital/neon-common";
import { getNow } from "@origin-digital/widget-helpers";

export interface IBatteryMaximiserWidgetProps {
  nmi: string;
}

export const BatteryMaximiser = ({ nmi }: IBatteryMaximiserWidgetProps) => {
  const { updateStatusToError, updateStatusToComplete } =
    useUpdateBatteryWidgetStatus();
  const result = useBatteryWidgetData(nmi);
  const widgetState = getBatteryWidgetState(result);

  const { homeEnergyState } = result;
  const currentTimeOfDay = getTimeOfDay(getNow());

  if (widgetState === EBatteryWidgetState.LOADING) {
    return <Loader />;
  }

  if (widgetState === EBatteryWidgetState.ERROR) {
    updateStatusToError(result.error);
    return <BatteryMaximiserWidgetError />;
  }

  updateStatusToComplete();

  return (
    <StandardBatteryWidget
      timeOfDay={currentTimeOfDay}
      homeEnergyState={homeEnergyState!}
      nmi={nmi}
      refetch={result.refetch}
    />
  );
};

export const BatteryMaximiserWidget = ({
  nmi,
}: IBatteryMaximiserWidgetProps) => {
  return (
    <SnoozePopupContextProvider>
      <BatteryMaximiser nmi={nmi} />
    </SnoozePopupContextProvider>
  );
};
