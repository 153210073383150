import React from "react";
import { TextLinkStyles } from "@origin-digital/ods-core";

interface ILearnMoreLinkProps {
  onClick: () => void;
  label?: string;
}

export const LearnMoreLink = ({
  onClick,
  label = "Learn more",
}: ILearnMoreLinkProps) => (
  <TextLinkStyles as="button" onClick={onClick} className={`${label}-button`}>
    {label}
  </TextLinkStyles>
);
