import React from "react";
import {
  Box,
  Card,
  Column,
  Columns,
  Divider,
  Inline,
  Stack,
} from "@origin-digital/ods-core";
import { Header } from "../Header/Header";
import { ChargingRing } from "../ChargingRing/ChargingRing";
import { ChargeInfoPanel } from "../ChargeInfoPanel/ChargeInfoPanel";
import { ChargeTargetPanel } from "../ChargeTargetPanel/ChargeTargetPanel";
import { ChargeSettingsLink } from "../ChargeSettingsLink/ChargeSettingsLink";
import {
  useVehicleLiveData,
  useVehicleLiveDataContext,
} from "../../provider/VehicleLiveDataProvider";
import {
  isInvalidChargeStatus,
  VehicleInvalidStatus,
} from "../VehicleCards/components/VehicleInvalidStatus";
import { useWidgetComplete } from "../../analytics";
import { ChargeCostPanel } from "../ChargeCostPanel/ChargeCostPanel";
import { SavingsLink } from "../SavingsLink/SavingsLink";

const ConnectedCardContent = () => {
  const { vehicleId } = useVehicleLiveData();
  useWidgetComplete();

  return (
    <Card data-id={`card-ev-icharge-${vehicleId}`}>
      <Stack space="medium">
        <Header />
        <Divider />
        <Stack space="xlarge">
          <Box marginTop="medium">
            <Columns space="medium" alignY="center">
              <Column width="content">
                <ChargingRing />
              </Column>
              <Column>
                <Box data-id="charge-info-panel">
                  <ChargeInfoPanel />
                </Box>
              </Column>
            </Columns>
          </Box>
          <Stack space="medium">
            <Stack space="xsmall">
              <ChargeTargetPanel />
              <ChargeCostPanel />
            </Stack>
            <Inline space="large">
              <ChargeSettingsLink vehicleId={vehicleId} />
              <SavingsLink />
            </Inline>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export const ConnectedCard = () => {
  const data = useVehicleLiveData();
  const { analyticsData } = useVehicleLiveDataContext();

  if (isInvalidChargeStatus(data)) {
    return (
      <VehicleInvalidStatus
        status={data.chargeState.status}
        analyticsData={analyticsData}
      />
    );
  }
  return <ConnectedCardContent />;
};
