import React, { useEffect, useState } from "react";

import { ContentCardWidget } from "@origin-digital/personalisation-widget";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { ICardAA, ICardCategoryAA } from "@origin-digital/reporting-client";
import {
  contentCards,
  IContentCardsResult,
  IContentCard,
} from "@origin-digital/event-dispatcher";
import { TrackingProvider } from "@origin-digital/ods-core";

import { trackingProviderCaptureClickCard } from "src/util/analytics";

export interface BrazeCardWidgetProps {
  location: string;
}

interface DashboardContentCard extends IContentCard {
  appContext: "dashboard";
}

export const BrazeCardWidget: React.FC<BrazeCardWidgetProps> = (props) => {
  const [trackingData, setTrackingData] = useState<ICardAA>();
  const [cardContent, setCardContent] = useState<DashboardContentCard>();

  const { location } = props;
  const { updateWidgetStatus } = useWidgetProvider();

  useEffect(() => {
    contentCards({ location, limit: 1 }).then(
      ({ cards }: IContentCardsResult) => {
        if (cards.length) {
          const [card] = cards as IContentCard[];
          const data = {
            category: "dashboard-card" as ICardCategoryAA,
            id: card.experienceId ?? "experienceId not found",
            title: card.title ?? "",
            primaryCta: card.ctaContained?.label ?? "",
          };
          setCardContent({ ...card, appContext: "dashboard" });
          setTrackingData(data);
          updateWidgetStatus(WidgetStatusCode.COMPLETE, [data]);
        }
      }
    );
  }, [location, updateWidgetStatus]);

  // if there is no card - don't throw an error: just return null
  if (!cardContent || !trackingData) {
    return null;
  }

  return (
    <TrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(trackingData)}
    >
      <ContentCardWidget {...cardContent} />
    </TrackingProvider>
  );
};
