import { Column, Columns, Stack, Text } from "@origin-digital/ods-core";
import * as React from "react";
import { ISummary } from "../../contract/bff";
import { formatAmount, formatPointCurrency } from "../../util/formatter";
import { UpcomingSpikeHour } from "./UpcomingSpikeHour";

interface IPointsProps {
  userSummary: ISummary;
}

export const Points = ({
  userSummary: {
    balance: { points, pointCurrency },
    upcoming: upcomingSpike,
    optInLink,
    participating,
  },
}: IPointsProps) => {
  return (
    <Stack space="large">
      <UpcomingSpikeHour
        upcomingSpike={upcomingSpike}
        participating={participating}
        optInLink={optInLink}
      />
      <Columns alignY="center">
        <Column>
          <Text data-id="point-currency" tone="neutralLight">
            {formatPointCurrency(pointCurrency)}
          </Text>
        </Column>
        <Column width="content">
          <Text data-id="points">{formatAmount(points)}</Text>
        </Column>
      </Columns>
    </Stack>
  );
};
