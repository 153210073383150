import React from "react";
import { Button } from "@origin-digital/ods-core";
import { navTo } from "@origin-digital/event-dispatcher";

interface ILinkAccountButtonProps {
  label?: string;
}

export const LinkAccountButton = ({
  label = "Link account",
}: ILinkAccountButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={navTo.evIChargeSignupRedirect}
    >
      {label}
    </Button>
  );
};
