const BACKEND_ID_DELIMITER = "-";

export const getAccountFromServiceId = (serviceId: string): string => {
  const lastIndexOfDelimiter = serviceId.lastIndexOf(BACKEND_ID_DELIMITER);
  const accountId = serviceId.substring(0, lastIndexOfDelimiter);

  if (!accountId) {
    throw new Error(
      `Failed to get the account id from the serviceId: ${serviceId}`
    );
  }

  return accountId;
};
