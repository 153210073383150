// @ts-nocheck
import { dapiFetchCache, dapiFetch, IDapiRequest } from '@origin-digital/daxi';

import {
  IBatteryControlResponse,
  IBatteryStateResponse,
  IDashboardResponse,
  IUsageDetailsResponse,
} from '../models';

/*
 * This code has been generated from an OpenAPI spec.
 */

export interface IActivateBatteryControlRequest {
  nmi: string;
}

/**
 * @summary Activate control of battery
 */
export const activateBatteryControl = async (baseUrl: string, data: IActivateBatteryControlRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IBatteryControlResponse> => {
    try {
        const method = 'POST';
        const url = baseUrl + activateBatteryControlUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IBatteryControlResponse>(url + queryString, fetchOptions);
          return response.data as IBatteryControlResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IBatteryControlResponse>(url + queryString, fetchOptions);
        return response.data as IBatteryControlResponse;
    } catch (error) {
        error.newRelic = `'POST: ${ activateBatteryControlUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the activateBatteryControl endpoint url that substitutes in given url params.
  */
const activateBatteryControlUrl = (data: IActivateBatteryControlRequest) => {
    return '/api/v1/state/{nmi}/control/activate'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};


export interface IDeactivateBatteryControlRequest {
  nmi: string;
}

/**
 * @summary Deactivate control of battery
 */
export const deactivateBatteryControl = async (baseUrl: string, data: IDeactivateBatteryControlRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IBatteryControlResponse> => {
    try {
        const method = 'DELETE';
        const url = baseUrl + deactivateBatteryControlUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IBatteryControlResponse>(url + queryString, fetchOptions);
          return response.data as IBatteryControlResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IBatteryControlResponse>(url + queryString, fetchOptions);
        return response.data as IBatteryControlResponse;
    } catch (error) {
        error.newRelic = `'DELETE: ${ deactivateBatteryControlUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the deactivateBatteryControl endpoint url that substitutes in given url params.
  */
const deactivateBatteryControlUrl = (data: IDeactivateBatteryControlRequest) => {
    return '/api/v1/state/{nmi}/control/activate'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};


export interface IGetCurrentBatteryStateApiRequest {
  nmi: string;
}

/**
 * @summary Get current battery state
 */
export const getCurrentBatteryStateApi = async (baseUrl: string, data: IGetCurrentBatteryStateApiRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IBatteryStateResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getCurrentBatteryStateApiUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IBatteryStateResponse>(url + queryString, fetchOptions);
          return response.data as IBatteryStateResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IBatteryStateResponse>(url + queryString, fetchOptions);
        return response.data as IBatteryStateResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getCurrentBatteryStateApiUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getCurrentBatteryStateApi endpoint url that substitutes in given url params.
  */
const getCurrentBatteryStateApiUrl = (data: IGetCurrentBatteryStateApiRequest) => {
    return '/api/v1/state/{nmi}'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};


export interface IGetDashboardDataRequest {
  nmi: string;
}

/**
 * @summary Responds with a sitrep containing battery maximiser current status
 */
export const getDashboardData = async (baseUrl: string, data: IGetDashboardDataRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IDashboardResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getDashboardDataUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IDashboardResponse>(url + queryString, fetchOptions);
          return response.data as IDashboardResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IDashboardResponse>(url + queryString, fetchOptions);
        return response.data as IDashboardResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getDashboardDataUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getDashboardData endpoint url that substitutes in given url params.
  */
const getDashboardDataUrl = (data: IGetDashboardDataRequest) => {
    return '/api/v1/dashboard/{nmi}'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};


export interface IGetUsageDetailsBillPeriodApiRequest {
  nmi: string;
}

/**
 * @summary Get battery usage details for the billing period
 */
export const getUsageDetailsBillPeriodApi = async (baseUrl: string, data: IGetUsageDetailsBillPeriodApiRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IUsageDetailsResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getUsageDetailsBillPeriodApiUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IUsageDetailsResponse>(url + queryString, fetchOptions);
          return response.data as IUsageDetailsResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IUsageDetailsResponse>(url + queryString, fetchOptions);
        return response.data as IUsageDetailsResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getUsageDetailsBillPeriodApiUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getUsageDetailsBillPeriodApi endpoint url that substitutes in given url params.
  */
const getUsageDetailsBillPeriodApiUrl = (data: IGetUsageDetailsBillPeriodApiRequest) => {
    return '/api/v1/usage/{nmi}/bill-period'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};


export interface IGetUsageDetailsMonthApiRequest {
  nmi: string;
}

/**
 * @summary Get battery usage details for the month
 */
export const getUsageDetailsMonthApi = async (baseUrl: string, data: IGetUsageDetailsMonthApiRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IUsageDetailsResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getUsageDetailsMonthApiUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IUsageDetailsResponse>(url + queryString, fetchOptions);
          return response.data as IUsageDetailsResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IUsageDetailsResponse>(url + queryString, fetchOptions);
        return response.data as IUsageDetailsResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getUsageDetailsMonthApiUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getUsageDetailsMonthApi endpoint url that substitutes in given url params.
  */
const getUsageDetailsMonthApiUrl = (data: IGetUsageDetailsMonthApiRequest) => {
    return '/api/v1/usage/{nmi}/month'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};


export interface IGetUsageDetailsTodayApiRequest {
  nmi: string;
}

/**
 * @summary Get battery usage details for today
 */
export const getUsageDetailsTodayApi = async (baseUrl: string, data: IGetUsageDetailsTodayApiRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IUsageDetailsResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getUsageDetailsTodayApiUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IUsageDetailsResponse>(url + queryString, fetchOptions);
          return response.data as IUsageDetailsResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IUsageDetailsResponse>(url + queryString, fetchOptions);
        return response.data as IUsageDetailsResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getUsageDetailsTodayApiUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getUsageDetailsTodayApi endpoint url that substitutes in given url params.
  */
const getUsageDetailsTodayApiUrl = (data: IGetUsageDetailsTodayApiRequest) => {
    return '/api/v1/usage/{nmi}/today'.replace('{' + 'nmi' + '}', encodeURIComponent(data.nmi));
};

