import { useEffect, useState } from "react";
import { ISummary } from "../contract/bff";
import { getUserSummaryForSpike } from "../service/summaryDao";

export const useUserSummary = () => {
  const [userSummary, setUserSummary] = useState<ISummary>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    getUserSummaryForSpike()
      .then((summary) => setUserSummary(summary))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, []);

  return { userSummary, loading, error };
};
