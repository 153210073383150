import React from "react";
import { Heading, Text } from "@origin-digital/ods-core";
import { ConfirmationDialog } from "@origin-digital/neon-common";
import { StopInstantChargeButton } from "./StopInstantChargeButton";

interface IDisableInstantChargeDialogProps {
  open: boolean;
  vehicleId: string;
  handleSuccessSnackbarOpen: () => void;
  handleErrorSnackbarOpen: () => void;
  handleClose: () => void;
}

export const DisableInstantChargeDialog = ({
  open,
  vehicleId,
  handleSuccessSnackbarOpen,
  handleErrorSnackbarOpen,
  handleClose,
}: IDisableInstantChargeDialogProps) => {
  return (
    <ConfirmationDialog
      data-id="disable-instant-charge-dialog"
      id="disable-instant-charge-dialog"
      open={open}
      onCancel={handleClose}
      ctaButton={
        <StopInstantChargeButton
          vehicleId={vehicleId}
          handleSuccessSnackbarOpen={handleSuccessSnackbarOpen}
          handleErrorSnackbarOpen={handleErrorSnackbarOpen}
          handleClose={handleClose}
        />
      }
    >
      <Heading variant="h3">Stop Instant charge</Heading>
      <Text align="center">
        Charging will revert to your scheduled settings and take place at the
        most cost-effective time.
      </Text>
    </ConfirmationDialog>
  );
};
