import React from "react";
import { Stack } from "@origin-digital/ods-core";
import {
  IChargeStatus,
  IVehicle,
  TargetEstimatedStateEnum,
} from "../../contract/bff";
import { WontMeetTargetInsufficientTime } from "./components/WontMeetTargetInsufficientTime";
import { InstantChargeOptionPanel } from "./components/InstantChargeOptionPanel";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import { WontMeetTargetNoChargeStatic } from "./components/WontMeetTargetNoChargeStatic";
import { WontMeetTargetUnknown } from "./components/WontMeetTargetUnknown";
import { WontMeetTargetInsufficientTimeNoChargeStatic } from "./components/WontMeetTargetInsufficientTimeNoChargeStatic";

const shouldShowPanel = (data: IVehicle) => {
  const {
    chargeState: { status },
    atChargeLocation,
    chargeOverride,
    optimisationPending,
  } = data;
  return (
    (status === IChargeStatus.PLUGGED_IN_CHARGING ||
      status === IChargeStatus.PLUGGED_IN_STOPPED) &&
    atChargeLocation &&
    !chargeOverride &&
    !optimisationPending
  );
};

export const ChargeTargetPanel = () => {
  const data = useVehicleLiveData();

  if (shouldShowPanel(data)) {
    switch (data.activeSchedule.targetEstimatedState) {
      case TargetEstimatedStateEnum.CAN_MEET:
        return <InstantChargeOptionPanel />;
      case TargetEstimatedStateEnum.NO_CHARGE_STATIC:
        return (
          <Stack space="xsmall">
            <WontMeetTargetNoChargeStatic />
            <InstantChargeOptionPanel />
          </Stack>
        );
      case TargetEstimatedStateEnum.INSUFFICIENT_TIME:
        return <WontMeetTargetInsufficientTime />;
      case TargetEstimatedStateEnum.INSUFFICIENT_TIME_AND_NO_CHARGE_STATIC:
        return (
          <Stack space="xsmall">
            <WontMeetTargetInsufficientTimeNoChargeStatic />
            <InstantChargeOptionPanel />
          </Stack>
        );
      case TargetEstimatedStateEnum.UNKNOWN:
        return (
          <Stack space="xsmall">
            <WontMeetTargetUnknown />
            <InstantChargeOptionPanel />
          </Stack>
        );
      default:
        return null;
    }
  }

  return null;
};
