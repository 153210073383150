import { AccountServiceStatus } from "@origin-digital/kraken-account";
import { isAfter, isBefore } from "date-fns";
import { PortfolioSummaryQuery_viewer_kraken_accounts } from "src/graphql-types/rx-gateway/PortfolioSummaryQuery";
import { parseDate } from "src/util/dateHelper";

export interface AccountWithStatus
  extends PortfolioSummaryQuery_viewer_kraken_accounts {
  serviceStatus: AccountServiceStatus;
}

const getAccountServiceStatus = (
  account: PortfolioSummaryQuery_viewer_kraken_accounts
): AccountServiceStatus => {
  if (account.services.some((service) => service.active)) {
    return AccountServiceStatus.ACTIVE;
  }

  const today = new Date();
  const validFromDate = parseDate(account.services[0]?.validFrom);

  if (validFromDate && isBefore(validFromDate, today)) {
    return AccountServiceStatus.CLOSED;
  }

  if (validFromDate && isAfter(validFromDate, today)) {
    return AccountServiceStatus.ONBOARDING;
  }

  return AccountServiceStatus.UNKNOWN;
};

export const getAccountWithStatus = (
  account: PortfolioSummaryQuery_viewer_kraken_accounts
): AccountWithStatus => {
  return { ...account, serviceStatus: getAccountServiceStatus(account) };
};

export const getStatusFilter =
  (isClosedFilter: boolean) => (account: AccountWithStatus) => {
    // always hide shell accounts
    if (account.services.length === 0) {
      return false;
    }

    return (
      (account.serviceStatus === AccountServiceStatus.CLOSED) === isClosedFilter
    );
  };
