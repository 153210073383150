import { useEffect } from "react";
import { IDashboardSummary } from "../contract/generated/bff/src";
import { useDataFetch } from "../../../../../hooks/useDataFetch";
import { getDashboard } from "../service/dashboardDao";

export interface ICommbattWidgetData {
  data?: IDashboardSummary;
  loading: boolean;
  error?: Error;
}

export const useCommbattWidgetData = (
  accountId: string
): ICommbattWidgetData => {
  const { loading, error, data, dataFetch } = useDataFetch<
    IDashboardSummary,
    string
  >(getDashboard);

  useEffect(() => {
    dataFetch(accountId);
  }, [accountId]);

  return { data, loading, error };
};
