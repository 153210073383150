import React from "react";
import { Button, Stack } from "@origin-digital/ods-core";
import { Header } from "../Header/Header";
import { SavingsSummary } from "../SavingsSummary/SavingsSummary";
import { ISolarExportSummary } from "../../contract/generated/bff/src";
import { SolarExportSummary } from "../SolarExportSummary/SolarExportSummary";
import { navTo } from "@origin-digital/event-dispatcher";
import { InfoModal } from "../InfoModal/InfoModal";

interface CommunityBatteryWidgetActiveProps {
  accountNumber: string;
  usageDateLabel: string;
  lifetimeSavings: number;
  solarExport: ISolarExportSummary;
}

export const CommunityBatteryWidgetActive = ({
  accountNumber,
  usageDateLabel,
  lifetimeSavings,
  solarExport,
}: CommunityBatteryWidgetActiveProps) => {
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  return (
    <Stack
      space="medium"
      dividers
      data-id="community-battery-widget-state-active"
    >
      <Header updatedOn={usageDateLabel} />
      <Stack space="medium">
        <SavingsSummary
          dailyEarnings={solarExport.earnings}
          lifetimeSavings={lifetimeSavings}
        />
        <SolarExportSummary
          total={solarExport.total}
          toBattery={solarExport.toBattery}
          toBatteryLimit={solarExport.toBatteryLimit}
          toGrid={solarExport.toGrid}
          onInfoPress={() => setShowInfoModal(true)}
        />
        <Button
          data-id="community-battery-bills-payments-link"
          variant="text"
          size="small"
          noTextPadding
          onClick={() => navTo.communityBatteryBillsPayments({ accountNumber })}
        >
          Bills & payments
        </Button>
      </Stack>
      <InfoModal open={showInfoModal} onClose={() => setShowInfoModal(false)} />
    </Stack>
  );
};
