import {
  Card,
  Column,
  Columns,
  Heading,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import React from "react";
import { BasicHeader } from "../Header/BasicHeader";
import { SolarErrorIcon } from "../Icons/SolarErrorIcon";

export const BatteryMaximiserWidgetError = () => (
  <Card data-id="battery-maximiser-widget-error-card">
    <Stack>
      <Stack space="medium" dividers>
        <Stack space="medium">
          <BasicHeader />
        </Stack>
        <Columns space="medium">
          <Column>
            <Stack space="small" alignX="center">
              <SolarErrorIcon />
              <Heading variant="h4">Something isn't working</Heading>
              <Text align="center">
                We're currently unable to show you what's happening with your
                system. Check back later, or in the meantime you can still
                access all your past system details below.
              </Text>
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </Stack>
  </Card>
);
