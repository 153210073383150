import { HandWithFloatingCoinImage } from "./HandWithFloatingCoinImage";
import React, { useCallback, useState } from "react";
import {
  Card,
  Stack,
  Button,
  Heading,
  Text,
  Columns,
  Column,
  IconButton,
} from "@origin-digital/ods-core";
import { IconCancel } from "@origin-digital/ods-icons";
import content from "./content.json";

export const WIDGET_DISMISSED_TIME_KEY =
  "ResearchCommunitySignUpWidgetJoinOrDismissedClickTime";

export interface ResearchCommunitySignUpWidgetProps {
  customerNumber?: string;
}

export const ResearchCommunitySignUpWidget = ({
  customerNumber,
}: ResearchCommunitySignUpWidgetProps) => {
  const [closedAt, setClosedAt] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    const nowIso = new Date().toISOString();
    localStorage.setItem(WIDGET_DISMISSED_TIME_KEY, nowIso);
    setClosedAt(nowIso);
  }, []);

  const handleJoin = useCallback(() => {
    localStorage.setItem(WIDGET_DISMISSED_TIME_KEY, new Date().toISOString());
    window.location.href = `https://aus01.prod.platform1.net/ConnectAPIGetway/Landing/api/Welcome/WelcomeOpen?PARAMS=9d21011891564971843be4c0ea7e9467_18&P210024=1&P209665=${customerNumber}`;
  }, [customerNumber]);

  if (closedAt) {
    return null;
  }

  return (
    <Card data-id="research-community-signup-widget">
      <Stack>
        <Columns>
          <Column>
            <HandWithFloatingCoinImage />
          </Column>
          <Column alignX="right">
            <IconButton
              onClick={handleClose}
              data-id="research-community-signup-widget-close-button"
            >
              <IconCancel color="neutral" />
            </IconButton>
          </Column>
        </Columns>
        <Heading variant="h4">{content.title}</Heading>
        <Text>{content.body}</Text>
        <Button
          data-id="research-community-signup-widget-join-button"
          onClick={handleJoin}
          variant="outlined"
        >
          {content.joinButton}
        </Button>
      </Stack>
    </Card>
  );
};
