import React from "react";
import { Inline, Text } from "@origin-digital/ods-core";
import { FlashIcon } from "../../../icons/FlashIcon";
import { ChargingInfoDialog } from "./ChargingInfoDialog";
import { InfoButton } from "./InfoButton";

const dataIdPrefix = "icharge-charging-status-info";

export const ChargingIndicator = ({ isCharging }: { isCharging: boolean }) => {
  const [isInfoOpen, setIsInfoOpen] = React.useState(false);
  const onInfoPressed = () => {
    setIsInfoOpen(true);
  };

  const closeInfoModal = () => {
    setIsInfoOpen(false);
  };

  return (
    <Inline space={"xsmall"}>
      <FlashIcon enabled={isCharging} />
      <Text variant="body-small">
        {isCharging ? "Charging" : "Charging paused"}
      </Text>
      {!isCharging && (
        <InfoButton
          onClick={onInfoPressed}
          data-id={`${dataIdPrefix}-button`}
        />
      )}
      <ChargingInfoDialog
        isOpen={isInfoOpen}
        onClose={closeInfoModal}
        data-id={dataIdPrefix}
      />
    </Inline>
  );
};
