import {
  getDashboardData,
  IDashboardSummary,
} from "../contract/generated/bff/src";
import { apiBasePath } from "../config";

export const getDashboard = async (
  accountId: string
): Promise<IDashboardSummary> => {
  try {
    const { data } = await getDashboardData(
      apiBasePath,
      { accountId },
      "jwt",
      5
    );

    if (!data) {
      throw new Error("Failed to get data from Commbatt BFF");
    }

    return data;
  } catch (error) {
    return Promise.reject(
      `[getDashboardData] Failed to get data from dashboard bff - ${error}`
    );
  }
};
