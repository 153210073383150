import { navTo } from "@origin-digital/event-dispatcher";
import { Button } from "@origin-digital/ods-core";
import React from "react";
import { IVendor } from "../../contract/bff";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";

interface IRelinkVehicleButtonProps {
  label?: string;
}

export const RelinkVehicleButton = ({
  label = "Link account",
}: IRelinkVehicleButtonProps) => {
  const { vendor } = useVehicleLiveData();

  if (!Object.values(IVendor).includes(vendor)) {
    throw new Error("EV vendor is not found or invalid");
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={() => navTo.evIChargeSignupVendorRedirect({ vendor })}
    >
      {label}
    </Button>
  );
};
