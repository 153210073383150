import { useState } from "react";

export interface IDataFetchResult<T> {
  data?: T;
  loading: boolean;
  error?: Error;
}

interface IDataFetch<T, P> extends IDataFetchResult<T> {
  setData: (data: T) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: Error) => void;
  dataFetch: (param: P) => void;
}

export const useDataFetch = <T, P>(
  fetch: (param: P) => Promise<T>
): IDataFetch<T, P> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<T>();

  const dataFetch = (param: P) => {
    setLoading(true);
    fetch(param)
      .then((result: T) => {
        setData(result);
        setError(undefined);
        return Promise.resolve(undefined);
      })
      .catch((dataError: Error) => {
        setData(undefined);
        setError(dataError);
      })
      .finally(() => setLoading(false));
  };

  return { loading, setLoading, error, setError, data, setData, dataFetch };
};
