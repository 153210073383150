import React from "react";
import { LoadingCard } from "../../LoadingCard/LoadingCard";
import { useVehicleLiveDataContext } from "../../../provider/VehicleLiveDataProvider";
import { VehicleCardContent } from "./VehicleCardContent";
import { VehicleErrorCard } from "./VehicleErrorCard";
import { NoActiveScheduleErrorCard } from "./NoActiveScheduleErrorCard";

export const VehicleCard = () => {
  const { loading, analyticsData, data, error } = useVehicleLiveDataContext();
  if (loading) {
    return <LoadingCard />;
  } else if (error || !data) {
    return <VehicleErrorCard error={error} analyticsData={analyticsData} />;
  } else if (data.activeSchedule) {
    return <VehicleCardContent />;
  } else {
    return <NoActiveScheduleErrorCard analyticsData={analyticsData} />;
  }
};
