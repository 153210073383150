import { Card, Divider, Stack, Text } from "@origin-digital/ods-core";
import React from "react";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";
import { BasicHeader } from "../BasicHeader/BasicHeader";

import { useWidgetComplete } from "../../analytics";
import { RelinkVehicleButton } from "../RelinkVehicleButton";

export const ConnectionLostCard = () => {
  const { vehicleId } = useVehicleLiveData();
  useWidgetComplete();

  return (
    <Card data-id={`card-ev-icharge-connection-lost-${vehicleId}`}>
      <Stack space="large">
        <Stack space="medium">
          <BasicHeader />
          <Divider />
          <Text>
            We've lost the connection to your EV account. To continue receiving
            8c per kWh charging, re-link your account now.
          </Text>
        </Stack>
        <RelinkVehicleButton />
      </Stack>
    </Card>
  );
};
