import { LinearProgress } from "@origin-digital/ods-lab";
import React from "react";
import { useTheme } from "styled-components";

export interface SolarExportLineGraphProps {
  total: number;
  toBattery: number;
  toBatteryLimit: number;
}

export const SolarExportLineGraph = ({
  total,
  toBattery,
  toBatteryLimit,
}: SolarExportLineGraphProps) => {
  const theme = useTheme() as any;

  const borderRadius = theme.shape.borderRadii.medium;
  const yellowColor = theme.palette.warning.main;
  const greyColor = theme.palette.grey[100];

  const isExportingToGrid = total > toBatteryLimit;
  const backgroundColor = isExportingToGrid ? yellowColor : greyColor;
  const progressPercentage =
    (toBattery / (isExportingToGrid ? total : toBatteryLimit)) * 100;

  return (
    <LinearProgress
      data-id="community-battery-solar-export-line-graph"
      value={progressPercentage}
      variant="determinate"
      muiProps={{
        color: "success",
        style: {
          height: "6px",
          borderRadius,
          backgroundColor,
        },
      }}
    />
  );
};
