import React from "react";
import { IChargeStatus } from "../../contract/bff";
import { Unplugged } from "./components/Unplugged";
import { ChargeComplete } from "./components/ChargeComplete";
import { ChargeInitializing } from "./components/ChargeInitializing";
import { Charging } from "./components/Charging";
import { useVehicleLiveData } from "../../provider/VehicleLiveDataProvider";

export const ChargeInfoPanel = () => {
  const {
    chargeState: { status },
  } = useVehicleLiveData();

  switch (status) {
    case IChargeStatus.UNPLUGGED:
      return <Unplugged />;
    case IChargeStatus.PLUGGED_IN_COMPLETE:
      return <ChargeComplete />;
    case IChargeStatus.PLUGGED_IN_INITIALIZING:
      return <ChargeInitializing />;
    case IChargeStatus.PLUGGED_IN_CHARGING:
    case IChargeStatus.PLUGGED_IN_STOPPED:
      return <Charging />;
    default:
      return null;
  }
};
